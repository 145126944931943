import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import {
  ButtonGroup,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from 'reactstrap';

export default class ImpersonateUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      familyList: this.props.familyList,
      mentorList: this.props.mentorList,
      competitiveStudentList: this.props.competitiveStudentList,
      mode: 'family',
      selected: '',
      selectedID: '',
      alertOpen: false
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.getFamilyList().then((data) => {
      this.setState({
        familyList: data.families
      });
    });

    this.props.apiaccess.getMentorList().then((data) => {
      this.setState({
        mentorList: data.mentors
      });
    });

    this.props.apiaccess.getCompetitiveStudentData().then((data) => {
      this.setState({
        competitiveStudentList: data.competitive_students
      });
    });
  };

  select = (key, id) => {
    this.setState({
      selected: key,
      selectedID: id
    });
  };

  openAlert = () => {
    this.setState({
      alertOpen: true
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false
    });
  };

  updateTestPortalUser = () => {
    if (this.state.mode === 'family') {
      this.props.apiaccess.setTestUserToFamily(this.state.selectedID).then((data) => {
        this.setState({ alertOpen: true });
      });
    } else if (this.state.mode === 'mentor') {
      this.props.apiaccess.setTestUserToMentor(this.state.selectedID).then((data) => {
        this.setState({ alertOpen: true });
      });
    } else {
      this.props.apiaccess.setTestUserToCompetitiveStudent(this.state.selectedID).then((data) => {
        this.setState({ alertOpen: true });
      });
    }
  };

  render() {
    return (
      <div>
        <h1>Impersonate User</h1>
        <ButtonGroup className="adminPaycheck">
          <Button
            color="default"
            onClick={() => this.setState({ mode: 'family', selected: '', selectedID: '' })}
            active={this.state.mode === 'family'}
          >
            Family
          </Button>
          <Button
            color="default"
            onClick={() => this.setState({ mode: 'mentor', selected: '', selectedID: '' })}
            active={this.state.mode === 'mentor'}
          >
            Mentor
          </Button>
          <Button
            color="default"
            onClick={() =>
              this.setState({ mode: 'competitive_student', selected: '', selectedID: '' })
            }
            active={this.state.mode === 'competitive_student'}
          >
            Competitive Student
          </Button>
        </ButtonGroup>

        <div>
          {this.state.mode === 'family' ? (
            <UncontrolledDropdown>
              <DropdownToggle caret>{this.state.selected || 'Families'}</DropdownToggle>
              <DropdownMenu>
                {this.state.familyList.map((i) => {
                  return (
                    <DropdownItem key={i.id} onClick={() => this.select(i.name, i.id)}>
                      {i.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : this.state.mode === 'mentor' ? (
            <UncontrolledDropdown>
              <DropdownToggle caret>{this.state.selected || 'Mentors'}</DropdownToggle>
              <DropdownMenu>
                {this.state.mentorList.map((i) => {
                  return (
                    <DropdownItem key={i.id} onClick={() => this.select(i.name, i.id)}>
                      {i.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            <UncontrolledDropdown>
              <DropdownToggle caret>{this.state.selected || 'Competitive Students'}</DropdownToggle>
              <DropdownMenu>
                {this.state.competitiveStudentList.map((i) => {
                  return (
                    <DropdownItem key={i.id} onClick={() => this.select(i.name, i.id)}>
                      {i.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>

        <div style={{ marginTop: 15 }}>
          <Button color="success" onClick={this.updateTestPortalUser}>
            Save
          </Button>
          <Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>
            Successfully saved testportaluser!
          </Alert>
        </div>
      </div>
    );
  }
}

ImpersonateUserPage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
