import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class MentorSurvey extends React.Component {
  constructor(props) {
    super(props);

    // Get email address
    const search = new URLSearchParams(window.location.search);
    const email = search.get('email');

    this.state = {
      submitted: false,
      email: email,
      rating: null,
      size:
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >
          900
          ? '4rem'
          : '2.8rem',
      margin:
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >
          900
          ? '-1.5rem'
          : '-0.8rem',
      big:
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >
        900,
      starClicked: false
    };
  }

  handleSubmit = () => {
    this.setState({ submitted: true });
    return true;
  };

  radioClick = () => {
    this.setState(
      {
        rating: document.querySelector('input[name="satisfied"]:checked').value,
        starClicked: true
      },
      () => {
        for (let i = 1; i <= 7; i++) {
          let star = document.getElementById(i);
          i <= this.state.rating ? (star.innerHTML = '★') : (star.innerHTML = '☆');
        }
      }
    );
  };

  radioHoverOn = (num) => {
    this.setState({ submitted: false }, () => {
      for (let i = 1; i <= 7; i++) {
        let star = document.getElementById(i);
        i <= num + 1 ? (star.innerHTML = '★') : (star.innerHTML = '☆');
      }
    });
  };

  radioHoverOff = () => {
    if (!this.state.starClicked) {
      this.setState({ submitted: false }, () => {
        for (let i = 1; i <= 7; i++) {
          let star = document.getElementById(i);
          star.innerHTML = '☆';
        }
      });
    } else {
      this.radioClick();
    }
  };

  render = () => {
    // Adjust stars for mobile
    window.addEventListener('resize', () => {
      let width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!this.state.big && width > 900) {
        this.setState({ size: '4rem', margin: '-1.5rem', big: true });
      } else if (this.state.big && width <= 900) {
        this.setState({ size: '2.8rem', margin: '-0.8rem', big: false });
      }
    });

    const starStyle = {
      fontSize: this.state.size,
      marginTop: this.state.margin,
      marginLeft: '-3px',
      color: '#ed1c24',
      cursor: 'pointer'
    }

    return (
      <div style={{ maxWidth: '600px' }}>
        <h1>Mentor Feedback</h1>
        {this.state.submitted ? (
          <div>
            <p style={{ marginTop: '3rem' }}>
              Thank you for your feedback! <Link to="/nextunconfirmed">Click here to access the Portal.</Link>
            </p>
          </div>
        ) : (
          <div>
            <p style={{ marginTop: '3rem' }}>
              How satisfied are you with your Breakout Mentors experience?
            </p>
            <div>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'A'}
                value={1}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'A'}
                id={1}
                onMouseEnter={() => this.radioHoverOn(0)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'B'}
                value={2}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'B'}
                id={2}
                onMouseEnter={() => this.radioHoverOn(1)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'C'}
                value={3}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'C'}
                id={3}
                onMouseEnter={() => this.radioHoverOn(2)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'D'}
                value={4}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'D'}
                id={4}
                onMouseEnter={() => this.radioHoverOn(3)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'E'}
                value={5}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'E'}
                id={5}
                onMouseEnter={() => this.radioHoverOn(4)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'F'}
                value={6}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'F'}
                id={6}
                onMouseEnter={() => this.radioHoverOn(5)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
              <input
                style={{ display: 'None' }}
                type={'radio'}
                name={'satisfied'}
                id={'G'}
                value={7}
                onChange={this.radioClick}
              ></input>
              <label
                style={starStyle}
                for={'G'}
                id={7}
                onMouseEnter={() => this.radioHoverOn(6)}
                onMouseLeave={() => this.radioHoverOff()}
              >
                ☆
              </label>
            </div>
          </div>
        )}
        <form
          style={{ display: this.state.submitted ? 'none' : 'block' }}
          action="https://docs.google.com/forms/d/e/1FAIpQLSfM8y4bbmAkUfFNgzi9zxU6aw4EQMssXW6aptfQxJ8Ceae1RQ/formResponse?embedded=true"
          method="POST"
          onSubmit={this.handleSubmit}
          target="hidden-form"
        >
          <label style={{ display: 'block' }} className="ss-q-item-label">
            Is there anything we can help with or make easier?
          </label>
          <textarea
            style={{ display: 'block', width: 'calc(100% - 5px)' }}
            name="entry.940737422"
            rows="6"
            cols="0"
            className="longAnswer"
          />

          <label style={{ display: 'block', marginTop: '1.7rem' }} className="ss-q-item-label">
            Do you have any additional comments or feedback?
          </label>
          <textarea
            style={{ display: 'block', width: 'calc(100% - 5px)' }}
            name="entry.1681384709"
            rows="6"
            cols="0"
            className="longAnswer"
          />

          <input type="hidden" name="entry.649960814" value={this.state.email} />
          {this.state.rating == null ? null : (
            <input type="hidden" name="entry.1624203441" value={this.state.rating} />
          )}

          <input
            style={{ marginTop: '1.7rem' }}
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-primary"
          />
        </form>

        <iframe name={'hidden-form'} style={{ display: 'none' }}></iframe>
      </div>
    );
  };
}

export default withRouter(MentorSurvey);
