import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import { Button, Alert } from 'reactstrap';

//BNOTE: Can probably just remove Group adjust option to simplify
class CreditAdjustForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      famOrGroup: 'family',
      giveOrTake: 1,
      familyID: '',
      groupID: '',
      amount: 0,
      description: '',
      alertOpen: false,
      runningRechargeCard: false,
      runningClearCardInfo: false
    };
  }

  saveGroupCredit = () => {
    var amount = this.state.amount * parseInt(this.state.giveOrTake);
    if (this.state.groupID === '') {
      alert('no groupID');
      return;
    }
    if ((this.state.amount === 0) | '0' | '') {
      alert('no amount given');
      return;
    }

    this.props.apiaccess
      .makeGroupCreditAdjustment(
        this.state.groupID,
        amount,
        this.state.description.replace(/\//g, '~~!!~~')
      )
      .then((data) => {
        this.clearForm();
        this.openAlert();
      });
  };

  saveFamilyCredit = () => {
    if (this.state.familyID === '') {
      alert('no familyID');
      return;
    }

    var amount = 'COMMENT';
    if (this.state.amount !== 'COMMENT') {
      amount = this.state.amount * parseInt(this.state.giveOrTake);
      if ((this.state.amount === 0) | '0' | '') {
        alert('no amount given');
        return;
      }
    }

    this.props.apiaccess
      .makeFamilyCreditAdjustment(
        this.state.familyID,
        amount,
        this.state.description.replace(/\//g, '~~!!~~')
      )
      .then((data) => {
        this.clearForm();
        this.openAlert();
      });
  };

  handleChange = (e) => {
    var key = e.target.id;
    var obj = {};
    obj[key] = e.target.value;
    //console.log(e.target.value);
    this.setState(obj);
  };

  handleRadioChange = (e) => {
    var key = e.target.name;
    var obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  };

  clearForm = () => {
    this.setState({
      famOrGroup: 'family',
      giveOrTake: 1,
      familyID: '',
      groupID: '',
      amount: 0,
      description: ''
    });
  };

  openAlert = () => {
    this.setState({
      alertOpen: true
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false
    });
  };

  doClearCardInfo = () => {
    this.setState({ runningClearCardInfo: true });
    this.props.apiaccess.clearCreditCardInfo(this.state.familyID).then((data) => {
      this.setState({ runningClearCardInfo: false });
    });
  };

  doRechargeCard = () => {
    this.setState({ runningRechargeCard: true });
    this.props.apiaccess.stripeRechargeCreditCard(this.state.familyID).then((data) => {
      this.setState({ runningRechargeCard: false });
    });
  };

  render() {
    var styleMR20 = { marginRight: 20 };
    var styleMR5 = { marginRight: 5 };

    return (
      <form>
        <div className="form-group">
          <label style={styleMR20}>Adjust For:</label>
          <input
            type="radio"
            id="family"
            value="family"
            name="famOrGroup"
            style={styleMR5}
            checked={this.state.famOrGroup === 'family' ? true : false}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="family" style={styleMR20}>
            Family
          </label>
          <input
            type="radio"
            style={styleMR5}
            id="group"
            value="group"
            name="famOrGroup"
            checked={this.state.famOrGroup === 'family' ? false : true}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="group">Group</label>
        </div>

        <div className="form-group">
          <label>
            {this.state.famOrGroup === 'family' ? 'Family:' : 'Group:'}
            <select
              className="form-control"
              id={this.state.famOrGroup === 'family' ? 'familyID' : 'groupID'}
              value={this.state.famOrGroup === 'family' ? this.state.familyID : this.state.groupID}
              onChange={this.handleChange}
              style={{ margin: 5 }}
            >
              <option value="0" disabled>
                Pick a {this.state.famOrGroup === 'family' ? 'family' : 'group'}...
              </option>

              {this.state.famOrGroup === 'family'
                ? this.props.familyList.map((family) => {
                    return (
                      <option
                        value={family.id}
                        key={family.id}
                        id={family.id}
                        style={{ fontSize: '12px' }}
                      >
                        {family.name}
                      </option>
                    );
                  })
                : this.props.groupList.map((group) => {
                    return (
                      <option
                        value={group.group_id}
                        key={group.group_id}
                        id={group.group_id}
                        style={{ fontSize: '12px' }}
                      >
                        {group.group_name}
                      </option>
                    );
                  })}
            </select>
          </label>
        </div>

        {this.state.familyID !== '' && (
          <div style={{ paddingBottom: '20px' }}>
            <Button style={{ marginRight: '20px' }} color="primary" onClick={this.doClearCardInfo}>
              {this.state.runningClearCardInfo ? 'Running...' : 'Clear Stripe Card Info'}
            </Button>
            <Button color="primary" onClick={this.doRechargeCard}>
              {this.state.runningRechargeCard ? 'Running...' : 'Stripe Recharge Card'}
            </Button>
          </div>
        )}

        <div className="form-group">
          <label style={styleMR20}>Action:</label>
          <input
            type="radio"
            id="give"
            value={1}
            style={styleMR5}
            name="giveOrTake"
            checked={this.state.giveOrTake === 1 ? true : false}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="give" style={styleMR20}>
            Give Credits
          </label>
          <input
            type="radio"
            style={styleMR5}
            id="-1"
            value="-1"
            name="giveOrTake"
            checked={this.state.giveOrTake === 1 ? false : true}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="-1">Use Credits </label>
        </div>

        <div className="form-group">
          <label htmlFor="amount" style={styleMR20}>
            Amount:
          </label>
          <input
            id="amount"
            step="1"
            min="0"
            value={this.state.amount}
            onChange={this.handleChange}
            placeholder="0"
            required
          />
          <span style={{ paddingLeft: 20 }}>Set to COMMENT if not adjusting credits</span>
        </div>

        <div className="form-group">
          <label htmlFor="description" style={styleMR20}>
            Description:
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            value={this.state.description}
            onChange={this.handleChange}
            required
          />
        </div>

        <Button
          color="success"
          onClick={
            this.state.famOrGroup === 'family' ? this.saveFamilyCredit : this.saveGroupCredit
          }
        >
          Save
        </Button>
        <Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>
          Successfully adjusted credits!
        </Alert>
      </form>
    );
  }
}

CreditAdjustForm.propTypes = {
  familyList: PropTypes.array.isRequired,
  groupList: PropTypes.array.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};

export default class CreditAdjustPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      familyList: this.props.familyList,
      groupList: this.props.groupList
    };
  }

  render() {
    return (
      <div>
        <h1>Credit Adjustment</h1>
        <CreditAdjustForm
          familyList={this.state.familyList}
          groupList={this.state.groupList}
          apiaccess={this.props.apiaccess}
        />
      </div>
    );
  }
}

CreditAdjustPage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
