import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

import { DIVISION_TO_CONCEPT } from './CompetitiveGymConstants';

import { Field, Formik } from 'formik';
import groupBy from 'lodash/groupBy';
import FontAwesome from 'react-fontawesome';

const ProblemCategory = (props) => {
	const [resourcesDisplayed, setResourcesDisplayed] = useState(false);
	// Refresh page using React state to ensure that progress bar sizing is correct
	const [mobile, setMobile] = useState(screen.width <= 785);

	useEffect(() => {
		const handleResize = () => {
			if (mobile && window.innerWidth > 785) {
				setMobile(false);
			} else if (!mobile && window.innerWidth <= 785) {
				setMobile(true);
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [mobile]);

	const name = props.concept.displayTitle;

	let problems = props.relevantProblems[name];

	// remove duplicate problems
	problems = problems ? problems.filter(
		(item, index, array) => array.findIndex((p) => p.problem_id === item.problem_id) === index
	) : [];

	let solvedCount = 0;
	for (let i = 0; i < problems.length; i++) {
		if (props.solvedProblems.includes(problems[i].problem_id)) solvedCount++;
	}

	if (!problems) {
		console.log("EARLY RETURN")
		return null;
	}

	return (
		<React.Fragment>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginRight: '5rem',
					flexWrap: 'wrap',
					width: '100%'
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-end',
						width: window.innerWidth > 785 ? 'auto' : '100%',
						justifyContent: 'space-between'
					}}
				>
					<h3>{props.concept.displayTitle}</h3>
					<div style={{ marginLeft: '2rem', marginBottom: '-0.45rem', whiteSpace: 'nowrap' }}>
						<a
							onClick={() => {
								setResourcesDisplayed(!resourcesDisplayed);
							}}
							href={'javascript:void(0);'}
						>
							<p>
								Resources{' '}
								<i
									className={resourcesDisplayed ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'}
									aria-hidden="true"
									style={{
										marginLeft: '0.5rem',
										marginRight: window.innerWidth > 785 ? 0 : '1rem'
									}}
								></i>
							</p>
						</a>
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', marginRight: '2rem' }}>
					<div
						style={{
							marginTop: window.innerWidth > 785 ? '1rem' : '0.75rem',
							width: window.innerWidth > 785 ? 200 : 120,
							height: window.innerWidth > 785 ? 20 : 12,
							backgroundColor: '#cccccc',
							marginRight: '1rem'
						}}
					>
						<div
							style={{
								width: (solvedCount / problems.length) * (window.innerWidth > 785 ? 200 : 120),
								height: window.innerWidth > 785 ? 20 : 12,
								backgroundColor: '#0add08'
							}}
						></div>
					</div>
					<h3
						style={{ fontSize: window.innerWidth > 785 ? '1.6rem' : 18 }}
					>{`${solvedCount} / ${problems.length}`}</h3>
				</div>
			</div>
			{
				// Adds a new row for the resources
				resourcesDisplayed &&
				props.relevantResources[name] &&
				props.relevantResources[name].length > 0 && (
					<ul>
						{props.relevantResources[name].map((item) => (
							<li>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									{item.display_text}
								</a>
							</li>
						))}
					</ul>
				)
			}
			<Problem
				userRole={props.userRole}
				category={props.concept.displayTitle}
				relevantProblems={problems}
				solvedProblems={props.solvedProblems}
				reloadProblems={props.reloadProblems}
				apiaccess={props.apiaccess}
				setSubmittedProblemInfo={props.setSubmittedProblemInfo}
				setSubmittedProblem={props.setSubmittedProblem}
				setShowTeachingInsightModal={props.setShowTeachingInsightModal}
				setTeachingInsight={props.setTeachingInsight}
				setShowDiagramModal={props.setShowDiagramModal}
				setDiagram={props.setDiagram}
			/>
		</React.Fragment>
	);
};

const Problem = (props) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const isMentor = props.userRole === 'mentor';

	return (
		props.relevantProblems && (
			<>
				<Table style={{ tableLayout: 'fixed' }}>
					<tbody>
						{(isExpanded
							? props.relevantProblems
							: props.relevantProblems.filter((problem) => problem.isPrimary)
						).map((problem, index) => (
							<tr key={index}>
								<td>
									{
										<div style={{ marginTop: '0.01rem' }}>
											<a
												href={problem.link}
												target="_blank"
												rel="noopener noreferrer"
												style={{ marginTop: '0.01rem' }}
											>
												<p>{problem.name}</p>
											</a>
										</div>
									}
								</td>
								<td>
									<div style={{ marginTop: '0.01rem' }}>
										<p>{problem.description ? problem.description : ''}</p>
									</div>
								</td>
								<td>
									{props.solvedProblems.includes(problem.problem_id) ? (
										<Button disabled color="default" size="sm" className="confirmed-button">
											<FontAwesome name="check" className="confirmed-session-icon" />
											Solved
										</Button>
									) : (
										<Button
											disabled={isMentor}
											onClick={(event) => {
												let btn = document.getElementById('problembtn' + props.category);
												props.apiaccess.setSolvedCompetitiveProblem(problem.problem_id).then(
													(response) => {
														props.reloadProblems();

														props.setSubmittedProblemInfo({
															id: problem.problem_id,
															name: problem.name,
															feedbackDisplayText: 'Rate the problem you just completed!'
														});

														props.setSubmittedProblem(true);
													},
													(response) => {
														props.reloadProblems();
													}
												);
											}}
											color={isMentor ? 'default' : 'success'}
											size="sm"
											className={isMentor ? 'confirmed-button' : 'confirm-button'}
										>
											{isMentor ? 'Not Solved' : 'Mark Solved'}
										</Button>
									)}
								</td>
								<td colSpan={isMentor ? 2 : 1}>
									{problem.diagram ? (
										<Button
											color="default"
											size="sm"
											className="non-accent-button"
											style={{
												marginRight: '0.5rem'
											}}
											onClick={(event) => {
												props.setShowDiagramModal(true);
												props.setDiagram(problem.diagram);
											}}
										>
											Diagram
										</Button>
									) : null}
									{problem.insights && isMentor ? (
										<Button
											color="default"
											size="sm"
											className="non-accent-button"
											style={{
												marginRight: '0.5rem'
											}}
											onClick={(event) => {
												props.setShowTeachingInsightModal(true);
												props.setTeachingInsight(problem.insights);
											}}
										>
											Teaching Insights
										</Button>
									) : null}
									<Button
										color="default"
										size="sm"
										className="non-accent-button"
										onClick={(event) => {
											props.reloadProblems();

											props.setSubmittedProblemInfo({
												id: problem.problem_id,
												name: problem.name,
												feedbackDisplayText: `Please add any notes you have on this problem or any resources ${isMentor ? 'your student' : 'you'
													} used!`
											});

											props.setSubmittedProblem(true);
										}}
									>
										Notes/Feedback
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				{props.relevantProblems.length > 4 && (
					<a onClick={() => setIsExpanded(!isExpanded)} href={'javascript:void(0);'}>
						<p style={{ marginLeft: '.75rem', marginTop: '-10px', marginBottom: '3rem' }}>
							{isExpanded ? 'Hide Additional Problems' : 'See Additional Problems'}
							<i
								style={{ marginLeft: '0.5rem' }}
								className={isExpanded ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'}
								aria-hidden="true"
							></i>
						</p>
					</a>
				)}
			</>
		)
	);
};

// https://codesandbox.io/s/formik-conditional-rendering-forked-0pppf3
export default function CompetitiveGym(props) {
	const [relevantProblems, setRelevantProblems] = useState({});
	const [relevantResources, setRelevantResources] = useState({});
	const [solvedProblems, setSolvedProblems] = useState(null);
	const [reloadProblems, setReloadProblems] = useState(false);

	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [recentProblemInfo, setRecentProblemInfo] = useState({});

	const [showDiagramModal, setShowDiagramModal] = useState(false);
	const [diagram, setDiagram] = useState();

	const [showTeachingInsightModal, setShowTeachingInsightModal] = useState(false);
	const [teachingInsight, setTeachingInsight] = useState();

	const [competitiveStudentID, setCompetitiveStudentID] = useState(null);

	const [showPrerequisiteResources, setShowPrereqResources] = useState(false);

	const fetchData = () => {
		let compID = null;
		if (props.userRole === 'mentor') {
			compID = props.competitiveStudent;
		} // Otherwise, it is competitive_student, so it doesn't matter what role (backend uses user token)
		setCompetitiveStudentID(compID);

		console.log(compID);

		props.apiaccess.getCompetitiveProblems(compID).then((responseData) => {
			let solvedProblemList = [];
			for (let problem in responseData.problems) {
				if ('timestamp' in responseData.problems[problem]) {
					solvedProblemList.push(responseData.problems[problem].problem_id);
				}
			}

			setRelevantProblems(groupBy(responseData.problems, (problem) => problem.category));
			setRelevantResources(groupBy(responseData.resources, (resource) => resource.category));
			setSolvedProblems(solvedProblemList);
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchData();
	}, [props.competitiveStudent, props.competitiveDivision, reloadProblems]);

	const reloadProblemsFunction = () => {
		setReloadProblems((prevState) => !prevState);
	};

	return (
		<div>
			<h1 style={{ textTransform: 'capitalize' }}>
				USACO{' '}
				{props.competitiveDivision === 'pre_bronze' ? 'Pre-Bronze' : props.competitiveDivision} Gym
			</h1>
			{['gold', 'platinum'].includes(props.competitiveDivision) ? (
				<h3 style={{ textAlign: 'center' }}>Gym currently not available for Gold and Platinum</h3>
			) : (
				<React.Fragment>
					{['bronze'].includes(props.competitiveDivision) && (
						<a onClick={() => setShowPrereqResources((prev) => !prev)} href={'javascript:void(0);'}>
							<p style={{ fontSize: 'larger' }}>
								Useful Resources Before You Begin{' '}
								<i
									className={
										showPrerequisiteResources ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'
									}
									aria-hidden="true"
									style={{ marginLeft: '0.5rem' }}
								></i>
							</p>
						</a>
					)}
					{showPrerequisiteResources && (
						<ul>
							{props.competitiveDivision === 'bronze' &&
								relevantProblems !== {} &&
								solvedProblems != null &&
								DIVISION_TO_CONCEPT['pre_bronze'].map((questionSection, index) => (
									<React.Fragment key={index}>
										<li>
											{questionSection.header}
											{console.log(questionSection)}
											<ul>
												{questionSection.concepts.map((concept, idx) => (
													<Fragment key={idx}>
														{relevantResources[concept.displayTitle].map((resource, i) => (
															<li key={i}>
																<a href={resource.link} target="_blank" rel="noopener noreferrer">
																	{resource.display_text}
																</a>
															</li>
														))}
													</Fragment>
												))}
											</ul>
										</li>
									</React.Fragment>
								))}
						</ul>
					)}
					{relevantProblems !== {} &&
						solvedProblems != null &&
						DIVISION_TO_CONCEPT[props.competitiveDivision].map((questionSection, index) =>
							questionSection.concepts.map((concept, idx) => (
								<ProblemCategory
									key={index * 100 + idx}
									userRole={props.userRole}
									category={concept.displayTitle}
									relevantProblems={relevantProblems}
									solvedProblems={solvedProblems}
									reloadProblems={reloadProblemsFunction}
									concept={concept}
									apiaccess={props.apiaccess}
									setSubmittedProblemInfo={setRecentProblemInfo}
									setSubmittedProblem={setShowFeedbackModal}
									relevantResources={relevantResources}
									setShowDiagramModal={setShowDiagramModal}
									setDiagram={setDiagram}
									setShowTeachingInsightModal={setShowTeachingInsightModal}
									setTeachingInsight={setTeachingInsight}
								/>
							))
						)}
				</React.Fragment>
			)}
			<Modal centered isOpen={showFeedbackModal && recentProblemInfo !== null}>
				<ModalBody>
					<h2>Feedback</h2>
					{recentProblemInfo !== null && <p>{recentProblemInfo.feedbackDisplayText}</p>}
					<Formik
						initialValues={{
							rating: null,
							comments: ''
						}}
						onSubmit={(values, formikHelpers) => {
							event.preventDefault();

							props.apiaccess
								.submitCompetitiveFeedback(
									recentProblemInfo.id,
									recentProblemInfo.name,
									props.competitiveDivision,
									values.rating,
									values.comments,
									competitiveStudentID
								)
								.then((response) => {
									formikHelpers.resetForm();

									setShowFeedbackModal(false);
									setRecentProblemInfo(null);
								});
						}}
					>
						{(form_props) => (
							<form id={'feedback-form'} onSubmit={form_props.handleSubmit}>
								<div>
									<label style={{ fontWeight: 450 }}>Rating</label>
									<br />
									{[1, 2, 3, 4, 5].map((num) => (
										<label key={num} style={{ marginLeft: '1rem' }}>
											<Field
												style={{ marginRight: '0.25rem' }}
												type={'radio'}
												name={'rating'}
												value={num.toString()}
												required={true}
											/>
											{num.toString()}
										</label>
									))}
								</div>
								<div>
									<label style={{ fontWeight: 450 }}>Comments</label>
									<br />
									<Field name={'comments'} as={'textarea'} style={{ width: '100%' }} rows={5} />
								</div>
							</form>
						)}
					</Formik>
				</ModalBody>
				<ModalFooter>
					<Button
						color="default"
						onClick={() => {
							setShowFeedbackModal(false);
							setRecentProblemInfo(null);
						}}
						style={{ borderColor: 'black' }}
					>
						Close
					</Button>
					<Button color="success" type="submit" form="feedback-form">
						Save
					</Button>
				</ModalFooter>
			</Modal>
			<Modal centered isOpen={showDiagramModal && diagram !== null}>
				<ModalBody>
					<h2>Diagram</h2>
					<a href={diagram} target="_blank">
						<img width="100%" src={diagram} />
					</a>
				</ModalBody>
				<ModalFooter>
					<Button
						color="default"
						onClick={() => {
							setShowDiagramModal(false);
							setDiagram(null);
						}}
						style={{ borderColor: 'black' }}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			<Modal centered isOpen={showTeachingInsightModal && teachingInsight !== null}>
				<ModalBody>
					<h2>Teaching Insights</h2>
					<p className="teaching-insights">{teachingInsight}</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color="default"
						onClick={() => {
							setShowTeachingInsightModal(false);
							setTeachingInsight(null);
						}}
						style={{ borderColor: 'black' }}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
