import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import DatePicker from 'react-datepicker';
import {
  Button,
  Col,
  Row,
  Container,
  ButtonGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import actions from '../util/actions';

export default class RegularBackupTimes extends React.Component {
  constructor(props) {
    super(props);
  }

  /*remove backup time for now
  {this.props.primarySessionTime != null &&
                  <IndividualTime apiaccess={this.props.apiaccess}
                              studentID={this.props.studentID}
                              groupID={this.props.groupID}
                              primarySessionTime={this.props.primarySessionTime}
                              backupSessionTime={this.props.backupSessionTime}
                              getInfoFromDatabase={this.props.getInfoFromDatabase}
                              priority={"backup"} />
              }
              */

  render() {
    if (this.props.communicationGroupID == null) return <span></span>;

    return (
      <div>
        <IndividualTime
          apiaccess={this.props.apiaccess}
          studentID={this.props.studentID}
          groupID={this.props.groupID}
          primarySessionTime={this.props.primarySessionTime}
          backupSessionTime={this.props.backupSessionTime}
          getInfoFromDatabase={this.props.getInfoFromDatabase}
          priority={'primary'}
        />
      </div>
    );
  }
}

class IndividualTime extends React.Component {
  constructor(props) {
    super(props);

    var time = '12:00 PM';
    var day = '';

    if (this.props.priority == 'primary' && this.props.primarySessionTime != null) {
      time =
        this.props.primarySessionTime.split(' ')[1] +
        ' ' +
        this.props.primarySessionTime.split(' ')[2];
      day = this.props.primarySessionTime.split(' ')[0];
    }

    if (this.props.priority == 'backup' && this.props.backupSessionTime != null) {
      time =
        this.props.backupSessionTime.split(' ')[1] +
        ' ' +
        this.props.backupSessionTime.split(' ')[2];
      day = this.props.primarySessionTime.split(' ')[0];
    }

    var timeObj = moment(time, 'h:mm a');

    this.state = {
      tempDay: day,
      tempTime: timeObj.toDate(),
      isUpdated: false,
      modifyMode: false
    };
  }

  componentWillReceiveProps = (nextProps) => {
    var day = '';
    var time = '12:00 PM';

    if (nextProps.priority == 'primary' && nextProps.primarySessionTime != null) {
      time =
        nextProps.primarySessionTime.split(' ')[1] +
        ' ' +
        nextProps.primarySessionTime.split(' ')[2];
      day = nextProps.primarySessionTime.split(' ')[0];
    }

    if (nextProps.priority == 'backup' && nextProps.backupSessionTime != null) {
      time =
        nextProps.backupSessionTime.split(' ')[1] + ' ' + nextProps.backupSessionTime.split(' ')[2];
      day = nextProps.backupSessionTime.split(' ')[0];
    }

    var timeObj = moment(time, 'h:mm a').toDate();

    if (
      this.props.primarySessionTime != nextProps.primarySessionTime ||
      this.props.backupSessionTime != nextProps.backupSessionTime
    ) {
      this.setState({
        isUpdated: false,
        tempDay: day,
        tempTime: timeObj,
        modifyMode: false
      });
    }
  };

  timePickerChanged = (time) => {
    this.setState({ tempTime: time, isUpdated: true });
  };

  selectDay = (key) => {
    this.setState({ tempDay: key, isUpdated: true });
  };

  modify = () => {
    this.setState({
      modifyMode: true
    });
  };

  updateTime = () => {
    this.setState({ isUpdated: false });
    var timeString = moment(this.state.tempTime, 'MM-DD-YYYY HH:mm').format('h:mm a');

    if (this.props.priority == 'primary') {
      if (this.props.studentID) {
        this.props.apiaccess
          .setPrimaryBackupSessionDayTime(
            'student',
            this.props.studentID,
            this.state.tempDay + ' ' + timeString
          )
          .then((response) => {
            this.props.getInfoFromDatabase(this.props.studentID);
          });
      } else {
        this.props.apiaccess
          .setPrimaryBackupSessionDayTime(
            'group',
            this.props.groupID,
            this.state.tempDay + ' ' + timeString
          )
          .then((response) => {
            this.props.getInfoFromDatabase(this.props.groupID);
          });
      }
    } else if (this.props.primarySessionTime != null) {
      if (this.props.studentID) {
        this.props.apiaccess
          .setPrimaryBackupSessionDayTime(
            'student',
            this.props.studentID,
            this.props.primarySessionTime,
            this.state.tempDay + ' ' + timeString
          )
          .then((response) => {
            this.props.getInfoFromDatabase(this.props.studentID);
          });
      } else {
        this.props.apiaccess
          .setPrimaryBackupSessionDayTime(
            'group',
            this.props.groupID,
            this.props.primarySessionTime,
            this.state.tempDay + ' ' + timeString
          )
          .then((response) => {
            this.props.getInfoFromDatabase(this.props.groupID);
          });
      }
    }
  };

  render() {
    var displayButtons = false;

    if (this.props.priority == 'primary' && this.props.primarySessionTime == null) {
      displayButtons = true;
    } else if (this.props.priority == 'backup' && this.props.backupSessionTime == null) {
      displayButtons = true;
    } else if (this.state.modifyMode) {
      displayButtons = true;
    }

    return (
      <div style={{ marginTop: '8px' }}>
        {displayButtons ? (
          <div>
            <h3> {this.props.priority == 'primary' ? 'Regular' : 'Backup'} Time </h3>
            <div>Used for the automated communication tool.</div>
            <UncontrolledDropdown style={{ display: 'inline-block', marginRight: '8px' }}>
              <DropdownToggle caret style={{ width: '125px' }}>
                {this.state.tempDay || 'Day'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.selectDay('Monday')}>{'Monday'}</DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Tuesday')}>{'Tuesday'}</DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Wednesday')}>
                  {'Wednesday'}
                </DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Thursday')}>{'Thursday'}</DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Friday')}>{'Friday'}</DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Saturday')}>{'Saturday'}</DropdownItem>
                <DropdownItem onClick={() => this.selectDay('Sunday')}>{'Sunday'}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <style>
              {`
                    .time-only-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
                        padding-left: unset;
                        padding-right: unset;
                    }
                    .time-only-picker .react-datepicker__input-container, .react-datepicker-wrapper {
                        width:100%;
                    }
                    .time-only-picker .react-datepicker {
                        width: 87px;
                    }
                    .time-only-picker .react-datepicker .react-datepicker__day {
                        line-height: 1.4rem;
                        margin: .1rem 0.166rem;
                    }
                    .time-only-picker .react-datepicker .react-datepicker__day-name {
                        line-height: 1rem;
                    }
                    .time-only-picker .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
                        display: none;
                    }
                    .time-only-picker .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                        padding: 5px 0;
                    }
                    .time-only-picker .react-datepicker__input-container input {
                        width: 100px;
                        padding: 3px 0 1px 8px;
                    }
                  `}
            </style>

            <div
              className="time-only-picker"
              style={{ display: 'inline-block', width: '100px', marginRight: '8px' }}
            >
              <DatePicker
                popperPlacement="bottom-start"
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: window.innerWidth > 400 ? {} : { enabled: true, offset: [0, 1] }
                    }
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      rootBoundary: 'viewport',
                      tether: false,
                      altAxis: true
                    }
                  },
                  {
                    name: 'arrow',
                    options: {
                      padding: 10
                    }
                  }
                ]}
                preventOpenOnFocus={true}
                monthsShown={0}
                date={false}
                selected={this.state.tempTime}
                onChange={this.timePickerChanged}
                timeIntervals={15}
                dateFormat="h:mm a"
                timeFormat="h:mm a"
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
              />
            </div>
            <span className="timeDisplay">Pacific Time </span>

            {this.state.isUpdated && (
              <Button color="success" onClick={this.updateTime}>
                Save
              </Button>
            )}
          </div>
        ) : (
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            Your {this.props.priority == 'primary' ? 'regular' : 'backup'} schedule is{' '}
            <strong>
              {
                (this.props.priority == 'primary'
                  ? this.props.primarySessionTime
                  : this.props.backupSessionTime
                ).split(' ')[0]
              }{' '}
            </strong>
            at{' '}
            <strong>
              {(this.props.priority == 'primary'
                ? this.props.primarySessionTime
                : this.props.backupSessionTime
              ).split(' ')[1] +
                ' ' +
                (this.props.priority == 'primary'
                  ? this.props.primarySessionTime
                  : this.props.backupSessionTime
                ).split(' ')[2]}
            </strong>{' '}
            Pacific Time
            <Button
              onClick={this.modify}
              color="link"
              size="sm"
              className="modify-button"
              style={{ width: '60px' }}
            >
              Modify
            </Button>
          </p>
        )}
      </div>
    );
  }
}
