import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import moment from 'moment';

import {
  ButtonGroup,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Paychecks } from './PastPaychecks';

export default class PaycheckAdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      by: 'month',
      mentors: this.props.mentorList,
      paychecks: [],
      months: [],
      selected: '',
      selectedIDs: []
    };
  }

  update = (by) => {
    let ids = [];
    if (by === 'month') {
      ids = this.state.paychecks.reduce((paycheckids, paycheck) => {
        paycheckids.push(paycheck.id);
        return paycheckids;
      }, []);
    } else if (by === 'mentor') {
      ids = this.state.mentors.reduce((mentorids, mentor) => {
        mentorids.push(mentor.id);
        return mentorids;
      }, []);
    }

    this.setState({
      by: by,
      selected: '',
      selectedIDs: ids
    });
  };

  select = (key, ids) => {
    this.setState({
      selected: key,
      selectedIDs: ids
    });
  };

  componentDidMount = () => {
    this.props.apiaccess.getPastPaychecks().then((data) => {
      const months = data.paychecks.reduce((months, each) => {
        let key = moment.months(each.month - 1) + ' ' + each.year;
        months[key] = months[key] || [];
        months[key].unshift(each.id);
        return months;
      }, {});

      this.setState({
        paychecks: data.paychecks,
        months: months
      });
    });
  };

  render() {
    let paycheckData = this.state.paychecks.filter((paycheck) => {
      if (this.state.by == 'month') return this.state.selectedIDs.includes(paycheck.id);
      return this.state.selectedIDs.includes(paycheck.mentor_id);
    });
    if (this.state.by == 'month') {
      paycheckData.sort(function (a, b) {
        return a.mentor_name < b.mentor_name ? -1 : a.mentor_name > b.mentor_name ? 1 : 0;
      });
    }

    return (
      <div>
        <h1>Paycheck Admin Page</h1>
        <ButtonGroup className="adminPaycheck">
          <Button
            color="default"
            onClick={() => this.update('month')}
            active={this.state.by === 'month'}
          >
            Month
          </Button>
          <Button
            color="default"
            onClick={() => this.update('mentor')}
            active={this.state.by === 'mentor'}
          >
            Mentor
          </Button>
        </ButtonGroup>
        {this.state.by === 'mentor' ? (
          <UncontrolledDropdown>
            <DropdownToggle caret>{this.state.selected || 'Mentors'}</DropdownToggle>
            <DropdownMenu>
              {this.state.mentors.map((i) => {
                return (
                  <DropdownItem key={i.id} onClick={() => this.select(i.name, [i.id])}>
                    {i.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle caret>{this.state.selected || 'Months'}</DropdownToggle>
            <DropdownMenu>
              {Object.keys(this.state.months)
                .reverse()
                .map((i) => {
                  return (
                    <DropdownItem key={i} onClick={() => this.select(i, this.state.months[i])}>
                      {i}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <Paychecks showName={this.state.by == 'month'} paychecks={paycheckData} />
      </div>
    );
  }
}

PaycheckAdminPage.propTypes = { apiaccess: PropTypes.instanceOf(actions) };
