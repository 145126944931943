import axios from 'axios';
import { EventEmitter } from 'events';
let apiLocation = !import.meta.env
  ? process.env.PROD_API_HOST
  : 'https://portalflask2.herokuapp.com/api';

export default class actions extends EventEmitter {
  constructor(token) {
    super();
    this.apicall = axios.create({
      timout: 3000,
      baseURL: apiLocation,
      responseType: 'json'
    });

    this.apicall.interceptors.response.use(
      (response) => {
        //console.log("response");
        //console.log(response);
        return response;
      },
      (err) => {
        //console.log("err");
        //console.log(err.response);
        if (err.response) {
          if (err.response.status == 408 && !err.response.config.__isRetryRequest) {
            err.config.__isRetryRequest = true;
            return this.apicall(err.config);
          } else if (err.response.status == 500) {
            this.emit('api_error');
          } else if (err.response.status == 555) {
            this.emit('alerted_admin');
          } else {
            this.emit('request_error', err);
          }
        } else {
          this.emit('connection_error');
        }
        throw err;
      }
    );

    this.apicall.interceptors.request.use(
      (config) => {
        config.headers = { Authorization: 'Bearer ' + token, ...config.headers };
        return config;
      },
      (err) => {}
    );
  }

  setToken = (token) => {
    this.apicall.interceptors.request.use(
      (config) => {
        config.headers = { Authorization: 'Bearer ' + token, ...config.headers };
        return config;
      },
      (err) => {
        console.log(err);
      }
    );
  };

  getFamilyAndStudents = () => {
    return this.apicall.get('familyandstudents').then((info) => {
      return info.data;
    });
  };

  //takes in a studentsID and returns that student and mentor info
  getStudentAndMentorDetails = (studentID) => {
    return this.apicall.get('studentmentordetails/' + studentID).then((info) => {
      return info.data;
    });
  };

  getPreviousSessions = (studentID, start, end) => {
    return this.apicall
      .get('previoussessions/' + studentID + '/' + start + '/' + end)
      .then((info, start, end) => {
        return info.data;
      });
  };

  setConfirmed = (sessionID, userID) => {
    return this.apicall.put('sessionconfirm/' + sessionID + '/' + userID).then((result) => {
      return result.data;
    });
  };

  setCanceled = (sessionID, userID) => {
    return this.apicall.put('sessioncancel/' + sessionID + '/' + userID).then((result) => {
      return result.data;
    });
  };

  setNewSessionDate = (sessionID, userID, newDate) => {
    return this.apicall
      .put('sessionmove/' + sessionID + '/' + userID + '/' + newDate)
      .then((result) => {
        return result.data;
      });
  };

  getTransactionsByFamily = (familyID) => {
    return this.apicall.get('transactions/' + familyID).then((info) => {
      return info.data;
    });
  };

  getSessionActionsByFamily = (familyID) => {
    return this.apicall.get('sessionactions/' + familyID).then((info) => {
      return info.data;
    });
  };

  paymentMade = (chargeAmount, creditsPurchased, stripeToken, userID, details, autoBill) => {
    return this.apicall
      .put(
        'paymentmade/' +
          chargeAmount +
          '/' +
          creditsPurchased +
          '/' +
          stripeToken +
          '/' +
          userID +
          '/' +
          details +
          '/' +
          autoBill
      )
      .then((info) => {
        return info.data;
      });
  };

  disableAutoBilling = (userID) => {
    return this.apicall.put('disableautobilling/' + userID).then((info) => {
      return info.data;
    });
  };

  getUnpaidSessions = (studentID) => {
    return this.apicall.get('unpaidsessions/' + studentID).then((info) => {
      return info.data;
    });
  };

  getPairingData = () => {
    return this.apicall.get('pairingdata').then((info) => {
      return info.data;
    });
  };

  getCompetitiveStudentData = () => {
    return this.apicall.get('competitivestudentdata').then((info) => {
      return info.data;
    });
  };

  getMentorList = () => {
    return this.apicall.get('mentorlist').then((info) => {
      return info.data;
    });
  };

  getStudentList = () => {
    return this.apicall.get('studentlist').then((info) => {
      return info.data;
    });
  };

  getFamilyList = () => {
    return this.apicall.get('familylist').then((info) => {
      return info.data;
    });
  };

  setGroupPairing = (groupId, mentorId, mentorRate) => {
    return this.apicall
      .put('updatepairing/' + groupId + '/' + mentorId + '/' + mentorRate)
      .then((info) => {
        return info.data;
      });
  };

  addNewUser = (familyCloseIoID, timezone) => {
    return this.apicall.put('adduser/' + familyCloseIoID + '/' + timezone).then((info) => {
      return info.data;
    });
  };

  setTestUserToFamily = (familyID) => {
    return this.apicall.put('testuserfamily/' + familyID).then((info) => {
      return info.data;
    });
  };

  setTestUserToMentor = (mentorID) => {
    return this.apicall.put('testusermentor/' + mentorID).then((info) => {
      return info.data;
    });
  };

  setTestUserToCompetitiveStudent = (competitiveStudentID) => {
    return this.apicall.put('testusercompetitivestudent/' + competitiveStudentID).then((info) => {
      return info.data;
    });
  };

  makeFamilyCreditAdjustment = (familyID, amount, description) => {
    return this.apicall
      .put('makefamilycreditadjustment/' + familyID + '/' + amount + '/' + description)
      .then((info) => {
        return info.data;
      });
  };

  makeGroupCreditAdjustment = (groupID, amount, description) => {
    return this.apicall
      .put('makegroupcreditadjustment/' + groupID + '/' + amount + '/' + description)
      .then((info) => {
        return info.data;
      });
  };

  addNewGroup = (groupName, student1, student2, student3, student4) => {
    return this.apicall
      .put(
        'creategroup/' +
          groupName +
          '/' +
          student1 +
          '/' +
          student2 +
          '/' +
          student3 +
          '/' +
          student4
      )
      .then((info) => {
        return info.data;
      });
  };

  sendPortalInvites = (familyID, emails, timezone) => {
    return this.apicall
      .put('sendappinvites/' + familyID + '/' + emails + '/' + timezone)
      .then((info) => {
        return info.data;
      });
  };

  getNoAccountList = () => {
    return this.apicall.get('familieswithoutusers').then((info) => {
      return info.data;
    });
  };

  getUsersWithOverdueBill = () => {
    return this.apicall.get('userswithoverduebill').then((info) => {
      return info.data;
    });
  };

  getGroupNextSession = (groupID) => {
    return this.apicall.get('groupnextsession/' + groupID).then((info) => {
      return info.data;
    });
  };

  getGroupUnconfirmedSessions = (groupID) => {
    return this.apicall.get('groupunconfirmedsessions/' + groupID).then((info) => {
      return info.data;
    });
  };

  getGroupPreviousSessions = (groupID, start, end) => {
    return this.apicall
      .get('groupprevioussessions/' + groupID + '/' + start + '/' + end)
      .then((info) => {
        return info.data;
      });
  };

  getStudentRateData = () => {
    return this.apicall.get('studentratedata').then((info) => {
      return info.data;
    });
  };

  setStudentRate = (studentID, rate, description) => {
    return this.apicall
      .put('updatestudentrate/' + studentID + '/' + rate + '/' + description)
      .then((info) => {
        return info.data;
      });
  };

  setMonthlyStudentRate = (studentID, monthlyPackage, extraCharge, description) => {
    return this.apicall
      .put(
        'updatemonthlystudentrate/' +
          studentID +
          '/' +
          monthlyPackage +
          '/' +
          extraCharge +
          '/' +
          description
      )
      .then((info) => {
        return info.data;
      });
  };

  getPaycheckToSubmit = (userID) => {
    return this.apicall.get('paychecktosubmit/' + userID).then((info) => {
      return info.data;
    });
  };

  getPastPaychecks = (userID = '') => {
    return this.apicall.get('pastpaychecks/' + userID).then((info) => {
      return info.data;
    });
  };

  addCalendarInvite = (familyID, email) => {
    return this.apicall.put('addcalendarinvite/' + familyID + '/' + email).then((info) => {
      return info.data;
    });
  };

  getCalendarInvites = (familyID) => {
    return this.apicall.get('getcalendarinvites/' + familyID).then((info) => {
      return info.data;
    });
  };

  setPrimaryBackupSessionDayTime = (
    studentOrGroup,
    entityID,
    primaryDayTime,
    backupDayTime = ''
  ) => {
    return this.apicall
      .put(
        'setprimarybackuptimes/' +
          studentOrGroup +
          '/' +
          entityID +
          '/' +
          primaryDayTime +
          '/' +
          backupDayTime
      )
      .then((info) => {
        return info.data;
      });
  };

  submitPaycheck = (paycheckID) => {
    return this.apicall.put('submitpaycheck/' + paycheckID).then((info) => {
      return info.data;
    });
  };

  updateUserSettings = (billingEmail, allEmail, isDeleted) => {
    return this.apicall
      .put('usersettings/' + billingEmail + '/' + allEmail + '/' + isDeleted)
      .then((info) => {
        return info.data;
      });
  };

  updateMentorSettings = (receiveTexts) => {
    return this.apicall.put('mentorsettings/' + receiveTexts).then((info) => {
      return info.data;
    });
  };

  updateCompetitiveStudentSettings = (division, handle) => {
    return this.apicall
      .put('competitivestudentsettings', { division: division, handle: handle })
      .then((info) => {
        return info.data;
      });
  };

  confirmSession = (hash) => {
    return this.apicall.put('sessionlinkconfirm/' + hash).then((result) => {
      return result.data;
    });
  };

  emailUnsubscribe = (hash) => {
    return this.apicall.put('emailunsubscribelink/' + hash).then((result) => {
      return result.data;
    });
  };

  getMonthlyRecaps = (studentID) => {
    return this.apicall.get('monthlyrecaps/' + studentID).then((info) => {
      return info.data;
    });
  };

  getRecapsToSubmit = (studentID) => {
    return this.apicall.get('recapstosubmit/' + studentID).then((info) => {
      return info.data;
    });
  };

  runCloseIOSync = () => {
    return this.apicall.put('synccloseio').then((info) => {
      return info.data;
    });
  };

  runCalendarSync = () => {
    return this.apicall.put('synccalendar').then((info) => {
      return info.data;
    });
  };

  getAdminDashboardData = () => {
    return this.apicall.get('dashboarddata').then((info) => {
      return info.data;
    });
  };

  getAdminPackageDetails = () => {
    return this.apicall.get('adminpackagedetails').then((info) => {
      return info.data;
    });
  };

  submitPaycheckAdjustment = (mentorID, amount, details) => {
    return this.apicall
      .put('adjustpaycheck/' + mentorID + '/' + amount + '/' + details)
      .then((info) => {
        return info.data;
      });
  };

  submitPastPaycheckAdjustment = (paycheckID, amount, details) => {
    return this.apicall
      .put('adjustpastpaycheck/' + paycheckID + '/' + amount + '/' + details)
      .then((info) => {
        return info.data;
      });
  };

  createCommunicationGroup = (studentID) => {
    return this.apicall.put('createcommunicationgroup/' + studentID).then((info) => {
      return info.data;
    });
  };

  getCommunicationGroup = (studentOrGroup, entityID) => {
    return this.apicall
      .get('getcommunicationgroup/' + studentOrGroup + '/' + entityID)
      .then((info) => {
        return info.data;
      });
  };

  joinCommunicationGroup = (studentID, name, phoneOrEmail, familyID) => {
    return this.apicall
      .put('joincommunicationgroup/' + studentID + '/' + name + '/' + phoneOrEmail + '/' + familyID)
      .then((info) => {
        return info.data;
      });
  };

  updateCommunicationGroupSettings = (
    communicationGroupID,
    upcomingMonthMessageEnabled,
    twoDayMessageEnabled,
    dayOfMessageEnabled
  ) => {
    return this.apicall
      .put('updatecommunicationgroupsettings', {
        communicationGroupID: communicationGroupID,
        upcomingMonthMessageEnabled: upcomingMonthMessageEnabled,
        twoDayMessageEnabled: twoDayMessageEnabled,
        dayOfMessageEnabled: dayOfMessageEnabled
      })
      .then((info) => {
        return info.data;
      });
  };

  getThisMonthsSessions = (studentOrGroup, entityID) => {
    return this.apicall.get('getmonthsessions/' + studentOrGroup + '/' + entityID).then((info) => {
      return info.data;
    });
  };

  getAllSessions = (studentID) => {
    return this.apicall.get('getallsessions', { params: { studentID: studentID } }).then((info) => {
      return info.data;
    });
  };

  changeMonthlyPackage = (studentID, oldPackage, newPackage) => {
    return this.apicall
      .put('changemonthlypackage/' + studentID + '/' + oldPackage + '/' + newPackage)
      .then((info) => {
        return info.data;
      });
  };

  sessionPackageAdjustment = (groupID, lastOrGeneral, date) => {
    return this.apicall
      .put('sessionpackageadjustment/' + groupID + '/' + lastOrGeneral + '/' + date)
      .then((info) => {
        return info.data;
      });
  };

  creditCardIntent = () => {
    return this.apicall.get('stripecreditcardintent').then((info) => {
      return info.data;
    });
  };

  creditCardAdded = (familyID, stripePaymentMethod) => {
    return this.apicall
      .put('creditcardadded/' + familyID + '/' + stripePaymentMethod)
      .then((info) => {
        return info.data;
      });
  };

  createNewSession = (groupID, userID, newDate) => {
    return this.apicall
      .put('createnewsession/' + groupID + '/' + userID + '/' + newDate)
      .then((info) => {
        return info.data;
      });
  };

  stripeCheckoutSession = () => {
    return this.apicall.get('stripecheckoutsession').then((info) => {
      return info.data;
    });
  };

  stripeNewCustomerMakeCharge = (amount, stripeCheckoutSession) => {
    return this.apicall
      .put('stripenewcustomermakecharge/' + amount + '/' + stripeCheckoutSession)
      .then((info) => {
        return info.data;
      });
  };

  clearCreditCardInfo = (familyID) => {
    return this.apicall.put('stripeclearcardinfo/' + familyID).then((info) => {
      return info.data;
    });
  };

  stripeRechargeCreditCard = (familyID) => {
    return this.apicall.put('striperechargecard/' + familyID).then((info) => {
      return info.data;
    });
  };

  setMonthlyPackage = (studentID, month, year, newPackage, extraCharge) => {
    return this.apicall
      .put(
        'setmonthlypackage/' +
          studentID +
          '/' +
          month +
          '/' +
          year +
          '/' +
          newPackage +
          '/' +
          extraCharge
      )
      .then((info) => {
        return info.data;
      });
  };

  submitCompetitiveForm = (formResponse, formType) => {
    return this.apicall
      .put('competitiveform', { response: formResponse, type: formType })
      .then((info) => {
        return info.data;
      });
  };

  getSubmittedCompetitiveForms = (formType, compStudentID = null) => {
    return this.apicall
      .get('competitiveform', { params: { type: formType, competitive_student: compStudentID } })
      .then((info) => {
        return info.data;
      });
  };

  getLastSubmittedCompetitiveForms = (studentID) => {
    return this.apicall
      .get('lastcompetitivesubmissions', { params: { studentID: studentID } })
      .then((info) => {
        return info.data;
      });
  };

  getFormsToSubmit = (compStudentID = null) => {
    return this.apicall
      .get('competitiveformstosubmit', { params: { competitive_student: compStudentID } })
      .then((info) => {
        return info.data;
      });
  };

  getCompetitiveProblems = (compStudentID) => {
    return this.apicall
      .get('competitiveproblems', { params: { competitive_student: compStudentID } })
      .then((info) => {
        return info.data;
      });
  };

  getCompetitiveStudentSummary = (compStudentID) => {
    return this.apicall
      .get('competitivestudentsummary', { params: { competitive_student: compStudentID } })
      .then((info) => {
        return info.data;
      });
  };

  setSolvedCompetitiveProblem = (problem_id) => {
    return this.apicall.put('competitiveproblemsolved', { problem_id: problem_id }).then((info) => {
      return info.data;
    });
  };

  submitCompetitiveFeedback = (
    problem_id,
    problem_name,
    problem_division,
    rating,
    comments,
    competitive_student = null
  ) => {
    return this.apicall
      .put('submitcompetitivefeedback', {
        problem_id: problem_id,
        problem_name: problem_name,
        problem_division: problem_division,
        rating: rating,
        comments: comments,
        competitive_student: competitive_student
      })
      .then((info) => {
        return info.data;
      });
  };

  addNewCompetitiveUser = (studentID, timezone) => {
    return this.apicall.put('addcompetitiveuser/' + studentID + '/' + timezone).then((info) => {
      return info.data;
    });
  };

  getTeamData = () => {
    return this.apicall.get('getteamdata').then((info) => {
      return info.data;
    });
  };

  updateTimeZone = (timezone) => {
    return this.apicall
      .put('usertimezonesetting', {
        timezone: timezone
      })
      .then((info) => {
        return info.data;
      });
  };

  updateDeepDivePackage = (
    student_id,
    depositRate,
    totalRate,
    balanceChargeDate,
    numSessions,
    additionalRate,
    depositPaid
  ) => {
    return this.apicall
      .put('updatedeepdivepackage', {
        student_id: student_id,
        depositRate: depositRate,
        totalRate: totalRate,
        balanceChargeDate: balanceChargeDate,
        numSessions: numSessions,
        additionalRate: additionalRate,
        depositPaid: depositPaid
      })
      .then((info) => {
        return info.data;
      });
  };
}
