import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Login from './Login';

const Authenticated = ({
  loggingIn,
  token,
  apiaccess,
  component,
  componentProps,
  userRole,
  roles,
  showLock,
  loginDone,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (token == null) return <Login show={showLock} hide={loginDone} />;
      else {
        if (!roles.includes(userRole)) return <Redirect to="/" />;
        if (loggingIn) return <div></div>;
        return React.createElement(component, {
          ...props,
          ...componentProps,
          loggingIn,
          apiaccess
        });
      }
    }}
  />
);

Authenticated.propTypes = {
  loggingIn: PropTypes.bool,
  token: PropTypes.string,
  component: PropTypes.func
};

export default Authenticated;
