import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loading from './Loading';

export default class EmailUnsubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.emailUnsubscribe(this.props.hash).then((data) => {
      this.setState({
        loading: false
      });
    });
  };

  render() {
    const loading = this.state.loading;

    return <div>{loading ? <Loading /> : <h1>You have been removed from the email list</h1>}</div>;
  }
}

EmailUnsubscribePage.propTypes = {
  hash: PropTypes.string.isRequired
};
