import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { DIVISION_TO_CONCEPT } from './CompetitiveGymConstants';
import { Alert, Button, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const STATUS_TEXT_TO_COLORS = {
  Completed: '#9CFFA0',
  Overdue: '#FF6666',
  Assigned: '#CCCC00'
};

const MONTH_NAME_TO_NUM = {
  December: 11,
  January: 0,
  February: 1,
  March: 2,
  April: 3
};

const MONTH_NUM_TO_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const QuestionComponent = (props) => {
  return (
    <div>
      {props.questions.map((question_section) => (
        <div>
          <h2>{question_section.header}</h2>
          <div>
            {question_section.concepts.map((question) => (
              <div>
                <label style={{ fontWeight: 450 }}>{question.displayTitle}</label>
                <br />
                {[1, 2, 3, 4, 5].map((num) => (
                  <label style={{ marginLeft: '1rem' }}>
                    <Field
                      style={{ marginRight: '0.25rem' }}
                      type={'radio'}
                      name={question.displayTitle}
                      value={num.toString()}
                      required={true}
                    />
                    {num.toString()}
                  </label>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export function CompetitiveEvaluationForm(props) {
  let history = useHistory();
  return (
    <React.Fragment>
      <h1>Self-Evaluation</h1>
      <Formik
        initialValues={{
          background_info: {
            division: ['gold', 'platinum'].includes(props.competitiveDivision)
              ? 'gold_plus'
              : props.competitiveDivision,
            other_comments: ''
          },
          form_type: 'student_evaluation'
        }}
        onSubmit={(data, { resetForm }) => {
          console.log('Submitted', data);

          props.apiaccess.submitCompetitiveForm(data, data.form_type).then((responseData) => {
            if (responseData.message === 'OK') {
              resetForm();
            } else {
              alert('There was an error submitting the form.');
            }

            history.push({
              pathname: '/submitcompetitiveform',
              state: {
                alertOpenProp: true
              }
            });
          });
        }}
      >
        {(form_props) => (
          <Form>
            {form_props.values && (
              <div>
                {form_props.values.form_type === 'student_evaluation' && (
                  <>
                    {form_props.values.background_info.division &&
                      form_props.values.background_info.division !== '' && (
                        <div>
                          <QuestionComponent
                            division={form_props.values.background_info.division}
                            questions={
                              DIVISION_TO_CONCEPT[form_props.values.background_info.division]
                            }
                          />
                          <Button color="success" type={'submit'}>
                            Submit
                          </Button>
                        </div>
                      )}
                  </>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export function ContestForm(props) {
  let history = useHistory();

  const [currentContestMonth, setCurrentContestMonth] = useState('December');
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Sets currentContestMonth to the nearest contest month
  useEffect(() => {
    let today = new Date();

    // Not in contest season (between May - November)
    if (today.getMonth() >= 4 && today.getMonth() !== 11) {
      setCurrentContestMonth('December');
      // Handle April (Set to March)
    } else if (today.getMonth() === 3) {
      setCurrentContestMonth('March');
      // Within contest season
    } else {
      // If it's past day 10, set contest month to current month
      if (today.getDate() > 10) {
        setCurrentContestMonth(MONTH_NAME_TO_NUM[today.getMonth()]);
      } else {
        // Handle the special case of January (negative array index)
        if (today.getMonth() === 0) {
          setCurrentContestMonth('December');
          // December of last year
          setCurrentYear(today.getFullYear() - 1);
          // Set to month before
        } else {
          setCurrentContestMonth(MONTH_NAME_TO_NUM[today.getMonth() - 1]);
        }
      }
    }
  });

  return (
    <React.Fragment>
      <h1>Contest Results</h1>
      <Formik
        initialValues={{
          contest_info: {
            score: 0,
            month: currentContestMonth,
            year: currentYear,
            division: props.competitiveDivision
          },
          form_type: 'contest'
        }}
        onSubmit={(data, { resetForm }) => {
          console.log('Submitted', data);

          props.apiaccess.submitCompetitiveForm(data, data.form_type).then((responseData) => {
            if (responseData.message === 'OK') {
              resetForm();
            } else {
              alert('There was an error submitting the form.');
            }

            history.push({
              pathname: '/submitcompetitiveform',
              state: {
                alertOpenProp: true
              }
            });
          });
        }}
      >
        {(form_props) => (
          <Form>
            {form_props.values && (
              <div>
                <div>
                  <p>
                    Contest Division: <b>{form_props.values.contest_info.division}</b> (you can
                    change this in the Settings tab)
                  </p>
                  <label style={{ marginRight: '0.6rem' }}>Contest Month and Year:</label>
                  <Field component={'select'} name={'contest_info.month'}>
                    <option value={''} disabled>
                      Select&hellip;
                    </option>
                    <option value={'December'}>December</option>
                    <option value={'January'}>January</option>
                    <option value={'February'}>February</option>
                    <option value={'March'}>March (US Open)</option>
                  </Field>
                  <Field
                    component={'select'}
                    name={'contest_info.year'}
                    style={{ marginLeft: '0.1rem' }}
                  >
                    {[currentYear + 1, currentYear, currentYear - 1, currentYear - 2].map(
                      (year) => (
                        <option value={year.toString()} selected={year === currentYear}>
                          {year.toString()}
                        </option>
                      )
                    )}
                  </Field>
                  <br />
                  <div>
                    <label style={{ marginBottom: '0.1rem', marginRight: '0.6rem' }}>
                      Contest Score:{' '}
                    </label>
                    <Field
                      component={'input'}
                      type={'number'}
                      name={'contest_info.score'}
                      min={'0'}
                      max={'1000'}
                      required
                    />
                  </div>
                </div>
                {form_props.values.contest_info.division !== '' &&
                  form_props.values.contest_info &&
                  form_props.values.contest_info.month != null &&
                  form_props.values.contest_info.month !== '' &&
                  form_props.values.contest_info.year != null && (
                    <Button color="success" type={'submit'}>
                      Submit
                    </Button>
                  )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

// https://codesandbox.io/s/formik-conditional-rendering-forked-0pppf3
export function SubmitCompetitiveFormPage(props) {
  let history = useHistory();
  const [alertOpen, setAlertOpen] = useState(false);
  const [lastSubmittedStudentEvaluation, setLastSubmittedStudentEvaluation] = useState(null);
  const [lastSubmittedContestForm, setLastSubmittedContestForm] = useState(null);

  const [formAssignments, setFormAssignments] = useState({});

  const closeAlert = (e) => {
    setAlertOpen(false);
  };

  const loadData = () => {
    console.log(props.form_entries);

    if (props.location && props.location.state && props.location.state.alertOpenProp) {
      setAlertOpen(true);
    }

    props.apiaccess.getFormsToSubmit(props.competitiveStudent).then((responseData) => {
      let form_entries = responseData['form_entries'];
      if ('student_evaluation' in form_entries) {
        setLastSubmittedStudentEvaluation(moment.unix(form_entries['student_evaluation']));
      }

      if ('contest' in form_entries) {
        setLastSubmittedContestForm(moment.unix(form_entries['contest']));
      }

      setFormAssignments(responseData['assignments']);
      console.log(responseData);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [props.form_entries]);

  return (
    <div>
      <Alert color="success" isOpen={alertOpen} toggle={closeAlert}>
        Saved!
      </Alert>
      <h1>{props.header !== null ? 'Surveys' : props.header}</h1>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Completed</th>
            <th>Next Due</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {props.userRole === 'competitive_student' ? (
                <a
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/submitcompetitiveform/submitstudentevaluationform');
                    return false;
                  }}
                >
                  Self Evaluation
                </a>
              ) : (
                <p>Self Evaluation</p>
              )}
            </td>
            <td>
              {lastSubmittedStudentEvaluation != null ? (
                <p>{lastSubmittedStudentEvaluation.format('MM/DD/YYYY')}</p>
              ) : (
                <p>-</p>
              )}
            </td>
            <td>
              {formAssignments['student_evaluation'] ? (
                <p>
                  {moment.unix(formAssignments['student_evaluation'].due_time).format('MM/DD/YYYY')}
                </p>
              ) : (
                <p>-</p>
              )}
            </td>
            <td>
              {formAssignments['student_evaluation'] ? (
                moment
                  .unix(formAssignments['student_evaluation'].due_time)
                  .isSameOrAfter(moment()) ? (
                  <p style={{ color: STATUS_TEXT_TO_COLORS['Assigned'] }}>Assigned</p>
                ) : (
                  <p style={{ color: STATUS_TEXT_TO_COLORS['Overdue'] }}>Overdue</p>
                )
              ) : (
                <p style={{ color: STATUS_TEXT_TO_COLORS['Completed'] }}>Completed</p>
              )}
            </td>
          </tr>
          <tr>
            <td>
              {props.userRole === 'competitive_student' ? (
                <a
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/submitcompetitiveform/submitcontestform');
                    return false;
                  }}
                >
                  Contest Results Form
                </a>
              ) : (
                <p>Contest Results Form</p>
              )}
            </td>
            <td>
              {lastSubmittedContestForm != null ? (
                <p>{lastSubmittedContestForm.format('MM/DD/YYYY')}</p>
              ) : (
                <p>-</p>
              )}
            </td>
            <td>
              {formAssignments['contest'] ? (
                <p>{moment.unix(formAssignments['contest'].due_time).format('MM/DD/YYYY')}</p>
              ) : (
                <p>-</p>
              )}
            </td>
            <td>
              {formAssignments['contest'] ? (
                moment.unix(formAssignments['contest'].due_time).isSameOrAfter(moment()) ? (
                  <p style={{ color: STATUS_TEXT_TO_COLORS['Assigned'] }}>Assigned</p>
                ) : (
                  <p style={{ color: STATUS_TEXT_TO_COLORS['Overdue'] }}>Overdue</p>
                )
              ) : (
                <p style={{ color: STATUS_TEXT_TO_COLORS['Completed'] }}>Completed</p>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
