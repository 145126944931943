import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import {
	FiLogOut,
	FiShare2,
	FiFileText,
	FiBarChart,
	FiSettings,
	FiPackage,
	FiArrowRight,
	FiUser,
	FiShoppingCart,
	FiUsers,
	FiHome,
	FiEdit,
	FiCreditCard,
	FiTool,
	FiSend,
	FiCheck,
	FiDollarSign,
	FiGift,
	FiLayout,
	FiHeart,
	FiBook
} from 'react-icons/fi';

import { ALLOWED_TEAM_LEADS } from './TeamPage';

export default function SidebarNav(props) {
	const makeLi = (i) => {
		return (
			<li key={i.to}>
				<NavLink
					className="sidebar-link"
					activeClassName="active"
					to={i.to}
					onClick={props.clicked}
				>
					<div className="inner">
						{i.icon}
						<p>{i.title}</p>
					</div>
				</NavLink>
			</li>
		);
	};

	const filterSchema = (props) => (item) => {
		return (
			item.authenticated == (props.token != null) &&
			(item.roles.length == 0 || item.roles.includes(props.role))
		);
	};

	const billingLink = [
		{
			title: 'Billing',
			to: '/billing',
			authenticated: true,
			roles: ['user'],
			icon: <FiShoppingCart style={{ marginRight: '15px' }} />
		}
	];

	const compStudentLinks = [
		{
			title: 'Gym',
			to: '/competitivegym',
			authenticated: true,
			roles: ['competitive_student'],
			icon: <FiShare2 style={{ marginRight: '6%', rotate: '90deg' }} />
		},
		{
			title: 'Surveys',
			to: '/submitcompetitiveform',
			authenticated: true,
			roles: ['competitive_student'],
			icon: <FiFileText style={{ marginRight: '5%' }} />
		},
		{
			title: 'View Progress',
			to: '/displaycompetitiveform',
			authenticated: true,
			roles: ['competitive_student'],
			icon: <FiBarChart style={{ marginRight: '3%' }} />
		}
	];

	const mentorUtils = [
		{
			title: 'Home',
			to: '/nextunconfirmed',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiHome style={{ marginRight: '16px' }} />
		},
		{
			title: 'Portal Instructions',
			to: '/instructions',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiFileText style={{ marginRight: '10px' }} />
		},
		{
			title: 'Resources',
			to: '/resources',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiPackage style={{ marginRight: '15px' }} />
		},
		{
			title: 'Session Recaps',
			to: '/recaps',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiEdit style={{ marginRight: '12px' }} />
		},
		{
			title: 'Paychecks',
			to: '/paychecks',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiDollarSign style={{ marginRight: '16px' }} />
		}
	];

	if (props.competitiveStudentList.length !== 0) {
		mentorUtils.splice(1, 0, {
			title: 'USACO Session',
			to: '/usacosession',
			authenticated: true,
			roles: ['mentor'],
			icon: <FiShare2 style={{ marginRight: '6%', rotate: '90deg' }} />
		});
	}

	if (ALLOWED_TEAM_LEADS.has(props.email)) {
		mentorUtils.unshift({ title: 'Team', to: '/team', authenticated: true, roles: ['mentor'] }); // Unshift adds to front of array
	}

	const utilLinks = [
		{
			title: 'Dashboard',
			to: '/dashboard',
			authenticated: true,
			roles: ['admin'],
			icon: <FiLayout style={{ marginRight: '14px' }} />
		},
		{
			title: 'Adjust Credits',
			to: '/creditadjust',
			authenticated: true,
			roles: ['admin'],
			icon: <FiGift style={{ marginRight: '12.5px' }} />
		},
		{
			title: 'Student Rates',
			to: '/studentrate',
			authenticated: true,
			roles: ['admin'],
			icon: <FiDollarSign style={{ marginRight: '13px' }} />
		},
		{
			title: 'Mentor Assignments',
			to: '/mentorassignments',
			authenticated: true,
			roles: ['admin'],
			icon: <FiCheck style={{ marginRight: '10px' }} />
		},
		{
			title: 'Impersonate User',
			to: '/impersonate',
			authenticated: true,
			roles: ['admin'],
			icon: <FiUser style={{ marginRight: '15px' }} />
		},
		{
			title: 'Send Invites',
			to: '/invites',
			authenticated: true,
			roles: ['admin'],
			icon: <FiSend style={{ marginRight: '15px' }} />
		},
		{
			title: 'Paychecks',
			to: '/mentorpaychecks',
			authenticated: true,
			roles: ['admin'],
			icon: <FiCreditCard style={{ marginRight: '17px' }} />
		},
		{
			title: 'Adjust Paychecks',
			to: '/adjustpaychecks',
			authenticated: true,
			roles: ['admin'],
			icon: <FiTool style={{ marginRight: '13px' }} />
		},
		{
			title: 'Create Groups',
			to: '/groupcreate',
			authenticated: true,
			roles: ['admin'],
			icon: <FiUsers style={{ marginRight: '15px' }} />
		},
		{
			title: 'Login',
			to: '/',
			authenticated: false,
			roles: [],
			icon: <FiArrowRight style={{ marginRight: '3.5%' }} />
		},
		{
			title: 'Other Ways To Help',
			to: '/otherwaystohelp',
			authenticated: true,
			roles: ['user', 'mentor'],
			icon: <FiHeart style={{ marginRight: '7px' }} />
		},
		{
			title: 'Package Adjustment',
			to: '/packageadjustment',
			authenticated: true,
			roles: ['admin'],
			icon: <FiPackage style={{ marginRight: '12px' }} />
		},
		{
			title: 'Settings',
			to: '/settings',
			authenticated: true,
			roles: ['user', 'mentor', 'competitive_student'],
			icon: <FiSettings style={{ marginRight: '15px' }} />
		}
	];

	const studentLinks = props.studentList.map((student) => {
		return {
			title: student.name,
			to: '/student/' + student.id,
			authenticated: true,
			roles: ['user'],
			icon: <FiUser style={{ marginRight: '15px' }} />
		};
	});

	const mentorLinks = props.studentList.map((group) => {
		return {
			title: group.name,
			to: '/group/' + group.id,
			authenticated: true,
			roles: ['mentor'],
			icon: <FiUsers style={{ marginRight: '9px' }} />
		};
	});

	const logOutLink = [
		{
			title: 'Logout',
			to: '/logout',
			authenticated: true,
			roles: ['user', 'admin', 'mentor', 'competitive_student'],
			icon: <FiLogOut style={{ marginRight: '15px' }} />
		}
	];

	return (
		<div className="sidebar-menu">
			<NavbarBrand href="/">
				<img src="https://breakoutmentors.com/wp-content/uploads/2023/06/cropped-breakout-mentors-brain-logo-4.jpg" />
			</NavbarBrand>
			<ul className={(props.hidden ? 'sidebar-hide ' : '') + 'sidebar-nav'}>
				{props.loggingIn ? (
					<div>{logOutLink.filter(filterSchema(props)).map(makeLi)}</div>
				) : (
					<div id="flex-container">
						<div>
							{billingLink.filter(filterSchema(props)).map(makeLi)}
							{compStudentLinks.filter(filterSchema(props)).map(makeLi)}
							{mentorUtils.filter(filterSchema(props)).map(makeLi)}
							{studentLinks.filter(filterSchema(props)).map(makeLi)}
							{mentorLinks.filter(filterSchema(props)).map(makeLi)}
							{utilLinks.filter(filterSchema(props)).map(makeLi)}
						</div>
						<div>{logOutLink.filter(filterSchema(props)).map(makeLi)}</div>
					</div>
				)}
			</ul>
		</div>
	);
}

SidebarNav.propTypes = {
	token: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	studentList: PropTypes.arrayOf(PropTypes.object).isRequired
};
