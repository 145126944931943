export const PREBRONZE_CONCEPTS = [
	{
		header: 'Fundamentals',
		concepts: [
			{
				displayTitle: 'Language Syntax'
			}
		]
	},
	{
		header: 'Data Structures and Algorithms',
		concepts: [
			{
				displayTitle: 'Built-In Data Structures I'
			},
			{
				displayTitle: 'Recursion'
			}
		]
	},
	{
		header: 'Efficiency',
		concepts: [
			{
				displayTitle: 'Time and Space Complexity'
			}
		]
	},
	{
		header: 'Competitive Programming Fundamentals',
		concepts: [
			{
				displayTitle: 'Input and Output'
			}
		]
	},
	{
		header: 'Other Topics',
		concepts: [
			{
				displayTitle: 'Other Topics'
			}
		]
	}
];

export const BRONZE_CONCEPTS = [
	{
		header: 'Main',
		concepts: [
			{
				displayTitle: 'Simulation'
			},
			{
				displayTitle: 'Complete Search'
			},
			{
				displayTitle: 'Greedy'
			},
			{
				displayTitle: 'Graphs'
			}
		]
	}
];

export const SILVER_CONCEPTS = [
	{
		header: 'Algorithms',
		concepts: [
			{
				displayTitle: 'Greedy Algorithms'
			},
			{
				displayTitle: 'Two Pointer'
			},
			{
				displayTitle: 'Prefix Sum'
			},
			{
				displayTitle: 'Dynamic Programming'
			}
		]
	},
	{
		header: 'Graphs',
		concepts: [
			{
				displayTitle: 'Special Graphs and Algorithms'
			},
			{
				displayTitle: 'Tree Algorithms'
			}
		]
	},
	{
		header: 'Additional',
		concepts: [
			{
				displayTitle: 'Number Theory'
			},
			{
				displayTitle: 'Binary and Bitwise Operations'
			}
		]
	}
];

export const GOLD_PLUS_CONCEPTS = [
	{
		header: 'Data Structures',
		concepts: [
			{
				displayTitle: 'Heap'
			},
			{
				displayTitle: 'Disjoint Set Union'
			},
			{
				displayTitle: 'Segment Tree'
			}
		]
	},
	{
		header: 'Dynamic Programming Algorithms',
		concepts: [
			{
				displayTitle: 'Intermediate Dynamic Programming'
			},
			{
				displayTitle: 'Memoization'
			},
			{
				displayTitle: 'Bitmask DP'
			},
			{
				displayTitle: 'Range DP'
			},
			{
				displayTitle: 'Tree DP'
			}
		]
	},
	{
		header: 'Graph Algorithms',
		concepts: [
			{
				displayTitle: 'Minimum Spanning Tree (Kruskal)'
			},
			{
				displayTitle: 'Topological Sort (Kahn)'
			},
			{
				displayTitle: 'Lowest Common Ancestor (Tarjan)'
			},
			{
				displayTitle: 'Shortest Path (Dijkstra)'
			}
		]
	},
	{
		header: 'Algorithms',
		concepts: [
			{
				displayTitle: 'Hashing'
			},
			{
				displayTitle: 'Range Sum / Minimum Queries'
			},
			{
				displayTitle: 'Meet in the Middle'
			}
		]
	}
];

export const DIVISION_TO_DISPLAY_TITLE = {
	pre_bronze: 'Pre-Bronze',
	bronze: 'Bronze',
	silver: 'Silver',
	gold_plus: 'Gold+',
	'': 'No Division'
};

export const DIVISION_TO_CONCEPT = {
	pre_bronze: PREBRONZE_CONCEPTS,
	bronze: BRONZE_CONCEPTS,
	silver: SILVER_CONCEPTS,
	gold_plus: GOLD_PLUS_CONCEPTS
};
