import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

let siteLocation = !import.meta.env
  ? process.env.PROD_SITE_ROOT
  : `https://${import.meta.env.VITE_APP_ADDRESS}.repl.co`;

export default class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth0_logout_url:
        'https://breakoutmentors.auth0.com/v2/logout?returnTo=' + encodeURIComponent(siteLocation)
    };
  }

  componentWillMount = () => {
    this.props.logout();
  };

  componentDidMount = () => {
    console.log('did mount redirect');

    //this.props.redirect(this.state.auth0_logout_url);
    window.location.assign(this.state.auth0_logout_url);
  };

  render = () => {
    console.log('redirecting...');
    return <h1>Logging out...</h1>;
  };
}

Logout.PropTypes = {
  logout: PropTypes.func.isRequired
};
