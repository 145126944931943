import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import GroupNextSession from './GroupNextSession';
import { ShowMoreButton, SessionDateButtons } from './PortalButtons';
import actions from '../util/actions';
import RegularBackupTimes from './RegularBackupTimes';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

export default class GroupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: '',
      groupID: this.props.match.params.id,
      previousSessionList: [],
      sessionsDisplayed: 5,
      tempDate: null
    };
  }

  componentDidMount = () => {
    //BNOTE: DOESN'T HANDLE RELOAD OF BUTTONS WHEN ONE IS CONFIRMED
    this.getInfoFromDatabase(this.state.groupID);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      groupName: '',
      groupID: nextProps.match.params.id,
      previousSessionList: [],
      sessionsDisplayed: 5,
      tempDate: null,
      recap_folder: null
    });

    this.getInfoFromDatabase(nextProps.match.params.id);
  };

  getInfoFromDatabase = (groupID) => {
    //only do API call if the id is in the student list
    if (this.props.studentList.every((group) => group.id != groupID)) {
      console.log('no access');
      return;
    }

    this.props.apiaccess
      .getGroupPreviousSessions(groupID, 0, this.state.sessionsDisplayed)
      .then((data) => {
        if (data.sessions) {
          this.setState({
            previousSessionList: this.state.previousSessionList.concat(data.sessions)
          });
        }
        this.setState({
          primarySessionTime: data.primary_day_time,
          backupSessionTime: data.backup_day_time,
          recap_folder: data.folder
        });
      });

    this.props.apiaccess.getCommunicationGroup('group', groupID).then((data) => {
      console.log('look for comm group');
      console.log(data.communicationGroupID);
      this.setState({ communicationGroupID: data.communicationGroupID });
    });

    //array of all groups, so want to find this particular group name without a db query
    var indexVal = this.props.studentList.findIndex((o) => o.id == parseInt(groupID));
    if (this.props.studentList[indexVal]) {
      var name = this.props.studentList[indexVal].name;
      this.setState({ groupName: name });
    }
  };

  handleShowMore = () => {
    //only do API call if the id is in the student list
    if (this.props.studentList.every((group) => group.id != this.state.groupID)) {
      console.log('no access');
      return;
    }

    this.props.apiaccess
      .getGroupPreviousSessions(
        this.state.groupID,
        this.state.sessionsDisplayed,
        this.state.sessionsDisplayed + 5
      )
      .then((data) => {
        if (data.sessions) {
          this.setState({
            previousSessionList: this.state.previousSessionList.concat(data.sessions)
          });
        }
      });

    this.setState({ sessionsDisplayed: this.state.sessionsDisplayed + 5 });
  };

  datePickerChanged = (date) => {
    this.setState({ tempDate: date });
  };

  saveSessionToCal = () => {
    this.props.apiaccess.createNewSession(
      this.state.groupID,
      this.props.userID,
      moment(this.state.tempDate).format('MM-DD-YYYY HH:mm')
    );
    this.setState({ tempDate: '' });
  };

  render() {
    var localUserID = this.props.userID;

    let showMoreDisplay = true;
    if (this.state.previousSessionList.length < this.state.sessionsDisplayed) {
      showMoreDisplay = false;
    }

    return (
      <div>
        <h1 style={{ marginBottom: 0 }}>{this.state.groupName}</h1>

        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <h3 className="light-bottom-border">Monthly Package</h3>
            {this.props.studentList.map((group) => {
              if (group.id == this.state.groupID) return <div key={group.id}>{group.package}</div>;
            })}
          </Col>

          {this.state.recap_folder && (
            <Col xs={12} sm={6} md={6} lg={6}>
              <h3 className="light-bottom-border">Monthly Recaps</h3>
              <a
                href={'https://drive.google.com/drive/u/0/folders/' + this.state.recap_folder}
                target="_blank"
              >
                View All <FontAwesome name="external-link" style={{ fontSize: 16 }} />
              </a>
            </Col>
          )}

          <Col xs={12} sm={12} md={12} lg={12}>
            <RegularBackupTimes
              apiaccess={this.props.apiaccess}
              groupID={this.state.groupID}
              primarySessionTime={this.state.primarySessionTime}
              backupSessionTime={this.state.backupSessionTime}
              getInfoFromDatabase={this.getInfoFromDatabase}
              communicationGroupID={this.state.communicationGroupID}
            />

            <h3>Add Individual Session to Calendar</h3>
            {this.state.tempDate === '' ? (
              <div>Added to your calendar</div>
            ) : (
              <div>
                <div className="addSession" style={{ display: 'inline-block', width: '200px' }}>
                  <span style={{ fontSize: '12px', color: 'gray', display: 'inline-block' }}>
                    {this.props.timezone} Time:
                  </span>
                  <DatePicker
                    popperPlacement="bottom-start"
                    placeholderText="Select date and time"
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: window.innerWidth > 400 ? {} : { enabled: true, offset: [0, 12] }
                        }
                      },
                      {
                        name: 'preventOverflow',
                        options: {
                          rootBoundary: 'viewport',
                          tether: false,
                          altAxis: true
                        }
                      },
                      {
                        name: 'arrow',
                        options: {
                          padding: 10
                        }
                      }
                    ]}
                    selected={this.state.tempDate}
                    onChange={this.datePickerChanged}
                    dateFormat="M/d/yy h:mm a"
                    timeIntervals={15}
                    timeFormat="h:mm a"
                    showTimeSelect
                  />
                </div>
                <Button
                  style={{ display: 'inline-block' }}
                  color="primary"
                  onClick={this.saveSessionToCal}
                >
                  Add Session
                </Button>
              </div>
            )}

            <div>
              {this.props.studentList.every((group) => group.id != this.state.groupID) ? null : (
                <GroupNextSession
                  groupID={parseInt(this.state.groupID)}
                  userID={localUserID}
                  apiaccess={this.props.apiaccess}
                  timezone={this.props.timezone}
                />
              )}
            </div>

            <h3 className="light-bottom-border">All Sessions Held</h3>
            <div>
              <SessionDateButtons
                sessions={this.state.previousSessionList}
                userID={localUserID}
                defaultDescription="No previous sessions"
                hideRecent={false}
                timezone={this.props.timezone}
                apiaccess={this.props.apiaccess}
              />
            </div>

            <ShowMoreButton onClick={this.handleShowMore} showing={showMoreDisplay} />
          </Col>
        </Row>
      </div>
    );
  }
}

GroupPage.propTypes = {
  studentList: PropTypes.array.isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};
