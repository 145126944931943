import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Table } from 'reactstrap';

import { Link, withRouter } from 'react-router-dom';

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runningCloseIoSync: false,
      runningCalendarSync: false,
      monthy_billing_students: '...',
      monthly_billing_total: '...',
      sessionsLastWeek: '...',
      sessionsNextWeek: '...',
      sessionsLastMonth: '...',
      sessionsNextMonth: '...',
      duplicateSessions: [],
      overDueBill: [],
      familiesNoAccount: [],
      monthly_no_cc_family: [],
      recentAdjustments: [],
      noScheduled: [],
      packageDetails: [],
      deepDiveInfo: []
    };
  }

  doCloseIoSync = () => {
    this.setState({ runningCloseIoSync: true });
    this.props.apiaccess.runCloseIOSync().then((data) => {
      this.setState({ runningCloseIoSync: false });
    });
  };

  doCalendarSync = () => {
    this.setState({ runningCalendarSync: true });
    this.props.apiaccess.runCalendarSync().then((data) => {
      this.setState({ runningCalendarSync: false });
    });
  };

  componentDidMount = () => {
    this.props.apiaccess.getAdminDashboardData().then((data) => {
      //console.log(data);
      this.setState({
        monthy_billing_students: data.monthy_billing_students,
        monthly_billing_total: data.monthly_billing_total,
        monthly_no_cc_family: data.monthly_no_cc_family,
        sessionsLastWeek: data.num_last_week,
        sessionsNextWeek: data.num_next_week,
        sessionsLastMonth: data.num_last_month,
        sessionsNextMonth: data.num_next_month,
        duplicateSessions: data.duplicate_sessions,
        recentAdjustments: data.recent_adjustments,
        noScheduled: data.no_scheduled_sessions,
        deepDiveInfo: data.deep_dive_info
      });
    });

    this.props.apiaccess
      .getUsersWithOverdueBill()
      .then((data) => {
        this.setState({ overDueBill: data.users });
      })
      .catch((error) => {
        if (error.response) {
          // if there is response, it means its not a 50x, but 4xx
          console.log(error.response);
        } else {
          // gets activated on 50x errors, since no response from server
          // do whatever you want here :)
          console.log('error in getUsersWithOverdueBill');
        }
      });

    this.props.apiaccess.getNoAccountList().then((data) => {
      this.setState({ familiesNoAccount: data.families });
    });

    this.props.apiaccess.getAdminPackageDetails().then((data) => {
      this.setState({ packageDetails: data.package_details });
    });
  };

  render() {
    return (
      <div>
        <h1>Dashboard</h1>

        <Row>
          {this.state.duplicateSessions.length > 0 && (
            <Col xs={12} sm={12} md={6} lg={4}>
              <h3>Duplicate Session Issue</h3>
              <ul>
                {this.state.duplicateSessions.map((dup, i) => {
                  return (
                    <li key={i}>
                      {dup['count']} {dup['datetime']} {dup['group']}
                    </li>
                  );
                })}
              </ul>
            </Col>
          )}

          <Col xs={12} sm={12} md={6} lg={4}>
            <div>
              <Button color="primary" onClick={this.doCloseIoSync}>
                {this.state.runningCloseIoSync ? 'Running...' : 'Sync Close.IO Data'}
              </Button>
              <p></p>
              <Button color="primary" onClick={this.doCalendarSync}>
                {this.state.runningCalendarSync ? 'Running...' : 'Sync Google Calendar Data'}
              </Button>
            </div>

            <h3>Stats</h3>
            <div>
              <p style={{ marginBottom: 0 }}>
                Monthly billing students: {this.state.monthy_billing_students}
              </p>
              <p style={{ marginBottom: 0 }}>
                Monthly billings: ${this.state.monthly_billing_total}
              </p>
              <p style={{ marginBottom: 0 }}>Sessions last week: {this.state.sessionsLastWeek}</p>
              <p style={{ marginBottom: 0 }}>Sessions next week: {this.state.sessionsNextWeek}</p>
              <p style={{ marginBottom: 0 }}>Sessions last month: {this.state.sessionsLastMonth}</p>
              <p>Sessions next month: {this.state.sessionsNextMonth}</p>
            </div>

            {this.state.familiesNoAccount.length > 0 && (
              <div>
                <h3>Families Without Accounts</h3>
                <ul>
                  {this.state.familiesNoAccount.map((family, i) => {
                    return <li key={i}>{family.name}</li>;
                  })}
                </ul>
              </div>
            )}

            {this.state.monthly_no_cc_family.length > 0 && (
              <div>
                <h3>Monthly Billing No Credit Card</h3>
                <ul>
                  {this.state.monthly_no_cc_family.map((family, i) => {
                    return <li key={i}>{family.name}</li>;
                  })}
                </ul>
              </div>
            )}

            {this.state.overDueBill.length > 0 && (
              <div>
                <h3>Overdue Bills</h3>
                <ul>
                  {this.state.overDueBill.map((user, i) => {
                    return (
                      <li key={i}>
                        {user.email} - {user.sessions.join(', ')}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div>
              <h3>No Scheduled Sessions</h3>
              <ul>
                {this.state.noScheduled.map((grp, i) => {
                  return <li key={i}>{grp.name}</li>;
                })}
              </ul>
            </div>

            <div>
              <h3>Recent Adjustments</h3>
              <ul>
                {this.state.recentAdjustments.map((adj, i) => {
                  return (
                    <li key={i}>
                      {adj.amount} "{adj.details}"
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={12} md={8} lg={8}>
            <Table striped hover className="adminTable">
              <thead>
                <tr>
                  <th>Group</th>
                  <th className="skinny">Package</th>
                  <th className="skinny">On Calendar</th>
                  <th className="skinny">Unconfirmed</th>
                </tr>
              </thead>
              <tbody>
                {this.state.packageDetails.map((packageDetail, i) => {
                  var prepaid = 0;
                  var backgroundStyle = {};
                  if (
                    packageDetail.package === '2sessions' ||
                    packageDetail.package === '2sessions2023' ||
                    packageDetail.package === '2sessions2022'
                  )
                    prepaid = 2;
                  if (
                    packageDetail.package === '3sessions' ||
                    packageDetail.package === '3sessions2023' ||
                    packageDetail.package === '3sessions2022'
                  )
                    prepaid = 3;
                  if (
                    packageDetail.package === '6sessions' ||
                    packageDetail.package === '6sessions2023' ||
                    packageDetail.package === '6sessions2022'
                  )
                    prepaid = 6;
                  if (packageDetail.confirmed + packageDetail.unconfirmed > prepaid) {
                    backgroundStyle = { backgroundColor: '#c2f0c2' };
                  } else if (packageDetail.confirmed + packageDetail.unconfirmed < prepaid) {
                    backgroundStyle = { backgroundColor: '#ffb3b3' };
                  }
                  return (
                    <tr key={i}>
                      <td>{packageDetail.group}</td>
                      <td>{packageDetail.package}</td>
                      <td style={backgroundStyle}>
                        {packageDetail.confirmed + packageDetail.unconfirmed}
                      </td>
                      <td>{packageDetail.unconfirmed}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12}>
            <h3>Deep Dive Sessions</h3>
            <Table striped hover className="adminTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="skinny">Prepaid</th>
                  <th className="skinny">Now</th>
                  <th className="skinny">Now+3</th>
                  <th className="skinny">Now+7</th>
                </tr>
              </thead>
              <tbody>
                {this.state.deepDiveInfo.map((dd, i) => {
                  return (
                    <tr key={i}>
                      <td>{dd.name}</td>
                      <td>{dd.prepaid}</td>
                      <td style={dd.now > dd.prepaid ? { backgroundColor: '#c2f0c2' } : {}}>
                        {dd.now}
                      </td>
                      <td style={dd.day3 > dd.prepaid ? { backgroundColor: '#c2f0c2' } : {}}>
                        {dd.day3}
                      </td>
                      <td style={dd.day7 > dd.prepaid ? { backgroundColor: '#c2f0c2' } : {}}>
                        {dd.day7}
                      </td>{' '}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AdminDashboard);
