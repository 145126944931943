import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Row, Button } from 'reactstrap';

export default function LandingPage(props) {
  return (
    <div>
      <h1>Welcome to the Breakout Mentors Portal</h1>
      <p className="lead">Please log in to your account</p>
      <Button onClick={props.show} color="success" size="lg">
        Login
      </Button>
      <p className="lead" style={{ marginTop: 40 }}>
        Not yet a customer?{' '}
        <a href="https://breakoutmentors.com/keeping-date-student-progress-introducing-customer-portal/">
          Learn more about the Breakout Mentors Portal
        </a>
      </p>
    </div>
  );
}
