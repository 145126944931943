import Auth0Lock from 'auth0-lock';
import { EventEmitter } from 'events';

const options = {
  auth: {
    params: { scope: 'openid name email' },
    responseType: 'token',
    redirectUrl: !import.meta.env
      ? process.env.PROD_SITE_ROOT
      : `https://${import.meta.env.VITE_APP_ADDRESS}.repl.co`
  },
  allowSignUp: false,
  allowLogin: true,
  theme: {
    logo: 'https://breakoutmentors.com/wp-content/uploads/2023/06/breakout-mentors-brain-logo.png'
  },
  languageDictionary: {
    title: 'Please log in'
  },
  configurationBaseUrl: 'https://cdn.auth0.com'
};

export default class AuthService extends EventEmitter {
  constructor(clientID, domain) {
    super();

    this.timezone = new URL(document.location).searchParams.get('tz');
    if (this.timezone == null) {
      this.timezone = 'Pacific';
    }

    this.clientID = clientID;
    this.domain = domain;

    this.lock = new Auth0Lock(clientID, domain, options);
    this.lock.on('authenticated', (authResult) => {
      this.getUserInfo(authResult.accessToken, authResult.idToken, authResult.state, this.timezone);
    });

    this.lock.on('hide', () => {
      this.emit('hide');
    });
  }

  fdsa = () => {};

  getUserInfo = (accessToken, idToken, registerState = null) => {
    if (accessToken == null) this.emit('logged_out');
    else
      this.lock.getUserInfo(accessToken, (error, profile) => {
        if (error) {
          this.emit('logged_out');
          return;
        }

        if (registerState && registerState.includes('STUDENTHASH:'))
          this.emit(
            'competitive_registered',
            accessToken,
            idToken,
            profile,
            registerState.replace('STUDENTHASH:', '').split('~~')[0],
            registerState.replace('STUDENTHASH:', '').split('~~')[1]
          );
        else if (registerState && registerState.includes('CLOSIOID:'))
          this.emit(
            'registered',
            accessToken,
            idToken,
            profile,
            registerState.replace('CLOSIOID:', '').split('~~')[0],
            registerState.replace('STUDENTHASH:', '').split('~~')[1]
          );
        else this.emit('logged_in', accessToken, idToken, profile);
      });
  };

  showLoginLock = () => {
    let options = {
      auth: {
        params: { scope: 'openid name email', state: '', prompt: 'select_account' }
      },
      allowLogin: true,
      allowSignUp: false,
      _enableIdPInitiatedLogin: true,
      autoclose: true
    };
    this.lock.show(options);
  };

  showRegisterLock = (familyID) => {
    return () => {
      let options = {
        auth: {
          params: {
            scope: 'openid name email',
            state: 'CLOSIOID:' + familyID + '~~' + this.timezone
          }
        },
        allowLogin: false,
        allowSignUp: true,
        closable: false,
        configurationBaseUrl: 'https://cdn.auth0.com'
      };
      this.lock.show(options);
    };
  };

  showCompetitiveRegisterLock = (studentHash) => {
    return () => {
      let competitiveOptions = {
        auth: {
          params: { scope: 'openid name email' },
          responseType: 'token',
          redirectUrl: !import.meta.env
            ? process.env.PROD_SITE_ROOT
            : `https://${import.meta.env.VITE_APP_ADDRESS}.repl.co`
        },
        allowSignUp: false,
        allowLogin: true,
        allowedConnections: ['Username-Password-Authentication'],
        theme: {
          logo: 'https://breakoutmentors.com/wp-content/uploads/2023/06/breakout-mentors-brain-logo.png'
        },
        languageDictionary: {
          title: 'Please log in'
        },
        additionalSignUpFields: [
          {
            name: 'handle',
            placeholder: 'Enter your Codeforces handle',
            icon: 'https://breakoutmentors.com/wp-content/uploads/2022/09/codeforcesIcon.png'
          }
        ],
        configurationBaseUrl: 'https://cdn.auth0.com'
      };

      let optionsOverride = {
        auth: {
          params: {
            scope: 'openid name email',
            state: 'STUDENTHASH:' + studentHash + '~~' + this.timezone
          }
        },
        allowLogin: false,
        allowSignUp: true,
        closable: false,
        configurationBaseUrl: 'https://cdn.auth0.com'
      };

      this.lock = new Auth0Lock(this.clientID, this.domain, competitiveOptions);
      this.lock.on('authenticated', (authResult) => {
        this.getUserInfo(authResult.accessToken, authResult.idToken, authResult.state);
      });

      this.lock.show(optionsOverride);
    };
  };
}
