import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import actions from '../util/actions';

import { Link } from 'react-router-dom';

import { FormGroup, Button, Input, Label, Row, Table } from 'reactstrap';
import Loading from './Loading';

import FontAwesome from 'react-fontawesome';

function TransactionRow(props) {
  let addSign = (i) => {
    if (!i) return '';
    return i === '' || i < 0 ? '' + i : '+' + i;
  };
  return (
    <tr>
      <td>{moment(props.transaction.dateTime).format('M/D/YYYY')}</td>
      <td>{props.transaction.action}</td>
      <td>{addSign(props.transaction.amount)}</td>
      <td>{props.transaction.details}</td>
      <td>{props.transaction.balance}</td>
    </tr>
  );
}

TransactionRow.propTypes = {
  transaction: PropTypes.object.isRequired
};

function TransactionTable(props) {
  return props.transactionList.length === 0 ? (
    <div>No transactions yet...</div>
  ) : (
    <div className="tableScrollSmallMobile">
      <Table hover className="transactionTable">
        <thead>
          <tr>
            <th className="skinny">Date</th>
            <th className="skinny">Action</th>
            <th className="skinny">Credits</th>
            <th>Description</th>
            <th className="skinny">Remaining Credits</th>
          </tr>
        </thead>
        <tbody>
          {props.transactionList.map((trans) => (
            <TransactionRow transaction={trans} id={trans.id} key={trans.id} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

TransactionTable.propTypes = {
  transactionList: PropTypes.array
};

export default class PaymentHistory extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.familyID);
    this._initialState = {
      transactionList: [],
      fullTableView: false,
      isLoading: true
    };
    this.state = this._initialState;
  }

  componentDidMount = () => {
    //if going directly to the page, familyID isn't set yet, so have to wait on the API call until we get it
    if (this.props.familyID != 0) {
      this.loadInfo(this.props.familyID);
    }
  };

  componentWillReceiveProps = (newProps) => {
    this.loadInfo(newProps.familyID);
  };

  loadInfo = (familyID) => {
    this.props.apiaccess.getTransactionsByFamily(familyID).then((trans) => {
      this.setState({
        transactionList: trans.transactions,
        isLoading: false
      });
    });
  };

  updateInfo = () => {
    this.props.apiaccess.getSessionActionsByFamily(this.props.familyID).then((trans) => {
      var unsortedList = this.state.transactionList.concat(trans.transactions);
      var sortedList;
      // In case you want to sort by the dateTime...
      if (unsortedList.length > 0) {
        unsortedList.map((item) => {
          item.dateObj = moment(item.dateTime);
        });
        sortedList = unsortedList.sort((a, b) => {
          if (a.dateObj > b.dateObj) {
            return -1;
          } else {
            return 1;
          }
        });
      } else {
        sortedList = [];
      }

      this.setState({
        fullTableView: true,
        transactionList: sortedList,
        isLoading: false
      });
    });
  };

  handleCheckbox = () => {
    if (this.state.fullTableView) {
      this.setState(this._initialState);
      this.loadInfo(this.props.familyID);
    } else {
      this.setState({
        isLoading: true
      });
      this.updateInfo();
    }
  };

  render() {
    return (
      <div>
        <h1 className="billing-history-heading">Transaction History</h1>
        <Button color="link" className="billing-back-button" onClick={this.props.gotoBilling}>
          <FontAwesome name="chevron-left" className="chevron-lft" />
          Billing
        </Button>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div style={{ clear: 'both' }}>
            <TransactionTable transactionList={this.state.transactionList} />
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.fullTableView}
                  onChange={this.handleCheckbox}
                />
                &nbsp;Show package details, confirms, moves, and cancels
              </Label>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

PaymentHistory.propTypes = {
  familyID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
