import { useHistory } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment/moment';

export const ALLOWED_TEAM_LEADS = new Set([
  'colin@breakoutmentors.com',
  'kai@breakoutmentors.com',
  'jessica@breakoutmentors.com',
  'testportaluser@breakoutmentors.com',
  'testportaluser@email.com'
]);

const SessionItem = (props) => {
  return props.sessions.length === 0 ? (
    <Row className="align-items-center">
      <Col xs={7} sm={7} md={6} lg={6} className="long-date">
        Nothing on the calendar yet.
      </Col>
    </Row>
  ) : (
    <div>
      {props.sessions
        .filter((session) => {
          return !session.isCanceled;
        })
        .map((session) => {
          const displayDateStr = moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format(
            'dddd, MMMM Do YYYY'
          );
          let tzString = session.datetimestr.split(' ')[2];
          const displayTimeStr =
            moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format('h:mm a') + ' ' + tzString;
          return (
            <div className="long-date">
              {displayDateStr} <span className="timeDisplay">{displayTimeStr}</span>
            </div>
          );
        })}
    </div>
  );
};

export default function TeamPage(props) {
  const history = useHistory();
  const [teamData, setTeamData] = useState({});

  if (!ALLOWED_TEAM_LEADS.has(props.email)) {
    history.replace('/nextunconfirmed');
  }

  useEffect(() => {
    props.apiaccess.getTeamData().then((data) => {
      setTeamData(data.mentor_data);
    });
  }, []);

  return (
    <Fragment>
      <h1>Team</h1>
      {Object.keys(teamData).map((k, index) => {
        let item = teamData[k];
        console.log('Item', item);
        if (item.email !== props.email) {
          return (
            <Fragment>
              <h1>{item.name}</h1>
              <p style={{ marginBottom: '0' }}>Email: {item.email}</p>
              <p>Phone: {item.phone}</p>
              {item.groups &&
                item.groups.map((group) => {
                  return (
                    <Fragment>
                      <h3>{group.name}</h3>
                      <div style={{ paddingLeft: '20px' }}>
                        <p>Package: {group.monthly_package}</p>
                        {group.session_recaps && (
                          <p>
                            <a
                              href={
                                'https://drive.google.com/drive/u/0/folders/' + group.session_recaps
                              }
                              target="_blank"
                            >
                              Recaps folder
                            </a>
                          </p>
                        )}
                        {group.closeioID && (
                          <p>
                            <a
                              href={'https://app.close.com/lead/' + group.closeioID}
                              target="_blank"
                            >
                              CloseIO link
                            </a>
                          </p>
                        )}
                        <h4 className={'light-bottom-border'}>Sessions this month</h4>
                        <SessionItem sessions={group.sessions_this_month} />
                        <h4 className={'light-bottom-border'} style={{ marginTop: '1rem' }}>
                          Next session
                        </h4>
                        <SessionItem sessions={group.next_session} />
                      </div>
                    </Fragment>
                  );
                })}
            </Fragment>
          );
        }
      })}
    </Fragment>
  );
}
