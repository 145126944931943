import React, { useEffect, useState } from 'react';
import CompetitiveGym from './CompetitiveGym';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Table,
	UncontrolledDropdown
} from 'reactstrap';
import DisplayCompetitiveFormPage from './DisplayCompetitiveFormPage';
import moment from 'moment/moment';
import { SubmitCompetitiveFormPage } from './SubmitCompetitiveFormPage';
import { DIVISION_TO_DISPLAY_TITLE } from './CompetitiveGymConstants';
import { useLocation } from 'react-router-dom';

export default function USACOSession(props) {
	const location = useLocation();

	const [studentID, setStudentID] = useState(null);
	const [selectedStudentIndex, setSelectedStudentIndex] = useState(-1);

	const [formEntries, setFormEntries] = useState({});
	const [lastSubmission, setLastSubmission] = useState({});
	const [lastWeekSubmissions, setLastWeekSubmissions] = useState([]);

	useEffect(() => {
		if (studentID !== null) {
			props.apiaccess.getCompetitiveStudentSummary(studentID).then((result) => {
				console.log(result);

				setFormEntries(result.form_entries);
				setLastSubmission(result.last_submission);
				setLastWeekSubmissions(result.last_week_submission);
			});
		}
	}, [studentID]);

	useEffect(() => {
		if (location && location.state && location.state.studentID != null) {
			setStudentID(location.state.studentID);
			for (let i = 0; i < props.competitiveStudents.length; i++) {
				if (props.competitiveStudents[i].id === Number.parseInt(location.state.studentID)) {
					setSelectedStudentIndex(i);
				}
			}
		}
	}, [location]);

	return (
		<div>
			<h1>USACO Session</h1>
			<div>
				<UncontrolledDropdown>
					<label style={{ marginRight: '10px' }}>Student:</label>
					<DropdownToggle caret style={{ display: 'inline-block' }}>
						{selectedStudentIndex === -1
							? 'Select a student...'
							: props.competitiveStudents[selectedStudentIndex].name}
					</DropdownToggle>
					<DropdownMenu>
						{props.competitiveStudents.map((item) => (
							<DropdownItem
								onClick={() => {
									setStudentID(item.id);
									for (let i = 0; i < props.competitiveStudents.length; i++) {
										if (props.competitiveStudents[i].id === Number.parseInt(item.id)) {
											setSelectedStudentIndex(i);
										}
									}
								}}
							>
								{item.name}
							</DropdownItem>
						))}
					</DropdownMenu>
				</UncontrolledDropdown>
			</div>
			{studentID !== null && selectedStudentIndex !== -1 && formEntries !== null && (
				<React.Fragment>
					<ul>
						<li>
							Division:{' '}
							{DIVISION_TO_DISPLAY_TITLE[props.competitiveStudents[selectedStudentIndex].division]}
						</li>
						{lastWeekSubmissions.length === 0 && Object.keys(lastSubmission).length !== 0 && (
							<li>
								Last Solved Problem:{' '}
								{lastSubmission.name +
									' (' +
									lastSubmission.category +
									'): ' +
									moment.unix(lastSubmission.timestamp).format('MM/DD/YYYY')}
							</li>
						)}
						{lastWeekSubmissions.length !== 0 && (
							<li>
								Problems solved in the last week:
								<ul>
									{lastWeekSubmissions.map((item) => (
										<li>{item.name + ' (' + item.category + ')'}</li>
									))}
								</ul>
							</li>
						)}
					</ul>
					<CompetitiveGym
            competitiveStudent={Number.parseInt(studentID)}
            userRole={props.userRole}
            competitiveDivision={props.competitiveStudents[selectedStudentIndex].division}
            apiaccess={props.apiaccess}
          />
          <SubmitCompetitiveFormPage
            header={'Survey Submission Summary'}
            competitiveStudent={Number.parseInt(studentID)}
            apiaccess={props.apiaccess}
          />
          <DisplayCompetitiveFormPage
            competitiveStudent={Number.parseInt(studentID)}
            userRole={props.userRole}
            competitiveDivision={props.competitiveStudents[selectedStudentIndex].division}
            apiaccess={props.apiaccess}
          />
				</React.Fragment>
			)}
		</div>
	);
}
