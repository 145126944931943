import React from 'react';
import PropTypes from 'prop-types';
import GroupNextSession from './GroupNextSession';
import RequestPaycheck from './RequestPaycheck';
import { Button, Jumbotron } from 'reactstrap';
import { SessionDateButtons } from './PortalButtons';
import actions from '../util/actions';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router-dom';

class UnconfirmedSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: []
    };
  }

  componentDidMount = () => {
    this.loadInfo(this.props.groupID);
  };

  componentWillReceiveProps = (nextProps) => {
    this.loadInfo(nextProps.groupID);
  };

  loadInfo = (groupID) => {
    this.props.apiaccess.getGroupUnconfirmedSessions(groupID).then((data) => {
      this.setState({
        sessions: data.sessions
      });
    });
  };

  updateInfo = () => {
    //if a session was confirmed, moved, or canceled, this will be called by the sessionChanged prop
    //we want to reload the info for all the buttons on the page
    if (this.props.reloadFromAPI) this.props.reloadFromAPI();
  };

  render() {
    return (
      <div>
        {this.state.sessions.length > 0 && (
          <div>
            <h3 className="light-bottom-border">Unconfirmed Past Sessions</h3>
            <SessionDateButtons
              sessions={this.state.sessions}
              userID={this.props.userID}
              defaultDescription="All sessions confirmed"
              hideRecent={false}
              sessionChanged={this.updateInfo}
              apiaccess={this.props.apiaccess}
              timezone={this.props.timezone}
            />
          </div>
        )}
      </div>
    );
  }
}

UnconfirmedSessions.propTypes = {
  groupID: PropTypes.number.isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};

export default class NextUnconfirmedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: props.userID
    };
  }

  reloadFromAPI = () => {
    //should call componentWillReceiveProps for RequestPaycheck, GroupNextSession, UnconfirmedSessions
    this.setState({ userID: this.props.userID });
  };

  render() {
    let compStudentNames = new Map();
    for (let i = 0; i < this.props.competitiveStudents.length; i++) {
      compStudentNames.set(
        this.props.competitiveStudents[i].name,
        this.props.competitiveStudents[i].id
      );
    }

    return (
      <div>
        <RequestPaycheck
          userID={this.state.userID}
          reloadFromAPI={this.reloadFromAPI}
          apiaccess={this.props.apiaccess}
        />

        {this.props.studentList.length > 0 ? (
          this.props.studentList.map((group) => {
            let isCompStudent = compStudentNames.has(group.name);
            return (
              <div key={group.id}>
                <div>
                  <span>
                    <h1 style={{ display: 'inline-block', marginRight: '25px' }}>{group.name}</h1>
                    {isCompStudent && (
                      <a
                        className={'usacoSessionLink'}
                        color={'link'}
                        onClick={() =>
                          this.props.history.push({
                            pathname: '/usacosession',
                            state: { studentID: compStudentNames.get(group.name) }
                          })
                        }
                      >
                        Go to USACO Session
                      </a>
                    )}
                  </span>
                  <Button
                    className="groupPageLink"
                    color="link"
                    onClick={() => this.props.history.push('/group/' + group.id)}
                  >
                    View Page
                    <FontAwesome name="chevron-right" className="chevron-rt" />
                  </Button>
                </div>
                <div className="groupSessions">
                  <GroupNextSession
                    groupID={group.id}
                    userID={this.state.userID}
                    reloadFromAPI={this.reloadFromAPI}
                    apiaccess={this.props.apiaccess}
                    timezone={this.props.timezone}
                  />

                  <UnconfirmedSessions
                    groupID={group.id}
                    userID={this.state.userID}
                    reloadFromAPI={this.reloadFromAPI}
                    apiaccess={this.props.apiaccess}
                    timezone={this.props.timezone}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <Jumbotron style={{ marginTop: 30 }}>
            <p style={{ marginBottom: 0 }}>No students assigned yet.</p>
          </Jumbotron>
        )}
      </div>
    );
  }
}

NextUnconfirmedPage.propTypes = {
  studentList: PropTypes.array.isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  competitiveStudents: PropTypes.array,
  timezone: PropTypes.string.isRequired
};
