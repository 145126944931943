import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import actions from '../util/actions';

import { Button, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import FontAwesome from 'react-fontawesome';

export class SessionButtonItem extends React.Component {
  constructor(props) {
    super(props);

    //BNOTE: excluded dates is just for the current month (on sessions this month) or loaded sessions (on all sessions held)
    var excluded_dates = props.allSessions.map((session) => {
      if (session.datetimestr != this.props.session.datetimestr) {
        //will have an undefined in array, but seems to work fine
        return new Date(session.datetimestr);
      }
    });

    this.state = {
      isCanceled: this.props.session.canceled,
      isConfirmed: this.props.session.confirmed,
      verifyCancelMode: false,
      changeDateMode: false,
      modifyConfirmedSessionMode: false,
      tempDate: moment(this.props.session.datetimestr, 'MM/DD/YYYY HH:mm').toDate(),
      excludedDates: excluded_dates,
      tzString: this.props.session.datetimestr.split(' ')[2]
    };
  }

  handleCancel = () => {
    //call the cancel function
    this.props.apiaccess.setCanceled(this.props.session.id, this.props.userID).then((result) => {
      this.setState({
        isCanceled: true,
        verifyCancelMode: false
      });

      this.props.onCanceled(this.props.session.overWeekOld); //call function in case have to do anything else on page (like update cart total)

      if (this.props.sessionChanged) this.props.sessionChanged();
    });
  };

  handleMove = () => {
    this.setState({
      changeDateMode: false,
      modifyConfirmedSessionMode: false
    });

    //move API call
    this.props.apiaccess
      .setNewSessionDate(
        this.props.session.id,
        this.props.userID,
        moment(this.state.tempDate, 'MM-DD-YYYY HH:mm').format('MM-DD-YYYY HH:mm')
      )
      .then((result) => {
        if (this.props.sessionChanged) this.props.sessionChanged();
      });
  };

  handleNo = () => {
    this.setState({
      changeDateMode: false,
      verifyCancelMode: false,
      modifyConfirmedSessionMode: false,
      tempDate: moment(this.props.session.datetimestr, 'MM/DD/YYYY HH:mm').toDate()
    });
  };

  verifyCancel = () => {
    this.setState({ verifyCancelMode: true });
  };

  handleConfirm = () => {
    //confirm API call
    this.props.apiaccess.setConfirmed(this.props.session.id, this.props.userID).then(() => {
      this.setState({ isConfirmed: true });
      if (this.props.sessionChanged) this.props.sessionChanged();
    });
  };

  handleModify = () => {
    this.setState({ modifyConfirmedSessionMode: true });
  };

  goToChangeDateMode = () => {
    this.setState({ changeDateMode: true });
  };

  datePickerChanged = (date) => {
    this.setState({ tempDate: date });
  };

  render() {
    if (this.state.isCanceled) {
      return null;
    }

    let buttons;
    if (this.state.changeDateMode) {
      buttons = <YesNoButtons onYes={this.handleMove} onNo={this.handleNo} />;
    } else if (this.state.verifyCancelMode) {
      buttons = <YesNoButtons onYes={this.handleCancel} onNo={this.handleNo} />;
    } else if (this.state.modifyConfirmedSessionMode) {
      buttons = (
        <div>
          <Button
            onClick={this.goToChangeDateMode}
            color="secondary"
            size="sm"
            className="move-button"
          >
            Move
          </Button>
          <Button onClick={this.verifyCancel} color="secondary" size="sm" className="cancel-button">
            Delete
          </Button>
          <Button onClick={this.handleNo} color="link" size="sm" className="discard-button">
            Discard
          </Button>
        </div>
      );
    } else if (this.state.isConfirmed) {
      buttons = (
        <div>
          <Button disabled color="default" size="sm" className="confirmed-button">
            <FontAwesome name="check" className="confirmed-session-icon" />
            Confirmed
          </Button>
          <Button onClick={this.handleModify} color="link" size="sm" className="modify-button">
            Modify
          </Button>
        </div>
      );
    } else {
      buttons = (
        <div>
          <Button onClick={this.handleConfirm} color="success" size="sm" className="confirm-button">
            Confirm
          </Button>
          <Button
            onClick={this.goToChangeDateMode}
            color="secondary"
            size="sm"
            className="move-button"
          >
            Move
          </Button>
          <Button onClick={this.verifyCancel} color="secondary" size="sm" className="cancel-button">
            Delete
          </Button>
        </div>
      );
    }

    if (this.state.changeDateMode) {
      console.log('window width:', window.innerWidth);
      console.log(this.state.tempDate);

      //hack fix for https://github.com/Hacker0x01/react-datepicker/issues/1116
      //trying to control where it pops up: https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md
      //consider making v-margin smaller on date and lineheight bigger for larger press area
      this.datePicker = (
        <div>
          <span className="timeDisplay">{this.props.timezone} Time:</span>
          <DatePicker
            startOpen
            popperPlacement="bottom-start"
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: window.innerWidth > 400 ? {} : { enabled: true, offset: [0, 12] }
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  tether: false,
                  altAxis: true
                }
              },
              {
                name: 'arrow',
                options: {
                  padding: 175
                }
              }
            ]}
            excludeDates={this.state.excludedDates}
            selected={this.state.tempDate}
            onChange={this.datePickerChanged}
            dateFormat="M/d/yy h:mm a"
            timeIntervals={15}
            timeFormat="h:mm a"
            showTimeSelect
          />
        </div>
      );
    } else {
      const displayDateStr = moment(this.state.tempDate, 'MM-DD-YYYY HH:mm').format(
        'dddd, MMMM Do YYYY'
      );
      const displayTimeStr =
        moment(this.state.tempDate, 'MM-DD-YYYY HH:mm').format('h:mm a') +
        ' ' +
        this.state.tzString;
      this.datePicker = (
        <div>
          {displayDateStr} <span className="timeDisplay">{displayTimeStr}</span>
        </div>
      );
    }

    return (
      <Row className="align-items-center buttonRow">
        <Col xs={7} sm={7} md={6} lg={6} className="long-date">
          {this.datePicker}
        </Col>
        <Col xs={5} sm={5} md={6} className="button-section">
          {buttons}
        </Col>
      </Row>
    );
  }
}

SessionButtonItem.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};

export class SessionDateButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numSessions: this.props.sessions.length
    };
  }

  handleCanceled = (overWeekOld) => {
    this.setState({ numSessions: this.state.numSessions - 1 });
    //ONLY REQ FOR THE BILLING PAGE
    if (this.props.removeCanceled) this.props.removeCanceled(overWeekOld);
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({ numSessions: nextProps.sessions.length });
  };

  render() {
    return this.state.numSessions === 0 ? (
      <Row className="align-items-center">
        <Col xs={7} sm={7} md={6} lg={6} className="long-date">
          {this.props.defaultDescription}
        </Col>
      </Row>
    ) : (
      <div>
        {this.props.sessions
          .filter((session) => {
            return !session.isCanceled && (!this.props.hideRecent || session.overWeekOld);
          })
          .map((session) => {
            return (
              <SessionButtonItem
                session={session}
                key={session.id}
                onCanceled={this.handleCanceled}
                userID={this.props.userID}
                apiaccess={this.props.apiaccess}
                allSessions={this.props.sessions}
                timezone={this.props.timezone}
                sessionChanged={this.props.sessionChanged}
              />
            );
          })}
      </div>
    );
  }
}

SessionDateButtons.propTypes = {
  sessions: PropTypes.array.isRequired,
  userID: PropTypes.number.isRequired,
  defaultDescription: PropTypes.string.isRequired,
  hideRecent: PropTypes.bool.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
  /* on the billing page only it will have props removeCanceled */
};

function YesNoButtons(props) {
  return (
    <div>
      <span className="areyousure">Are you sure?&nbsp;</span>
      <Button onClick={props.onYes} color="secondary" size="sm" className="yes-no-button">
        Yes
      </Button>
      <Button onClick={props.onNo} color="secondary" size="sm" className="yes-no-button">
        No
      </Button>
    </div>
  );
}

YesNoButtons.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired
};

export function ShowMoreButton(props) {
  return (
    props.showing && (
      <div className="center-show-more">
        <Button size="sm" color="link" className="show-more" onClick={props.onClick}>
          Show More
        </Button>
      </div>
    )
  );
}

ShowMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired
};
