import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Jumbotron,
  FormGroup,
  Label,
  Input,
  Form,
  Alert
} from 'reactstrap';
import { SessionDateButtons } from './PortalButtons';
import moment from 'moment';
var stripe = Stripe(
  !import.meta.env ? 'pk_live_ELbCtO8LNvXZejT2eIFrHkLu' : 'pk_test_jeId9pl1YkbzuwmRG2uI6wAR'
);

class StudentMonthlyBilling extends React.Component {
  constructor(props) {
    super(props);

    var baseSessions = 2;
    if (
      this.props.student.monthly_package === '3sessions' ||
      this.props.student.monthly_package === '3sessions2023' ||
      this.props.student.monthly_package === '3sessions2022'
    )
      baseSessions = 3;
    if (
      this.props.student.monthly_package === '6sessions' ||
      this.props.student.monthly_package === '6sessions2023' ||
      this.props.student.monthly_package === '6sessions2022'
    )
      baseSessions = 6;

    this.state = {
      thisMonthSessions: [],
      heldSessions: [],
      upcomingSessions: [],
      optionsModalOpen: false,
      extraChargePerSession: this.props.student.extra_charge_per_session,
      baseSessions: baseSessions,
      setPackage: this.props.student.monthly_package,
      selectedPackage: this.props.student.monthly_package,
      monthlyRate: this.props.student.monthly_rate,
      addionalSessions: this.props.student.additional_session_rate,
      deepDiveDeposit: this.props.student.deep_dive_deposit,
      deepDiveTotal: this.props.student.deep_dive_total,
      deepDiveDepositPaid: this.props.student.deep_dive_deposit_paid,
      deepDiveBalancePaid: this.props.student.deep_dive_balance_paid,
      deepDiveBalanceChargeDate: this.props.student.deep_dive_balance_charge_date,
      deepDiveNumSessions: this.props.student.deep_dive_num_sessions,
      deepDiveAdditionalRate: this.props.student.deep_dive_additional_rate,
      alertOpen: false
    };
  }

  submitRateChange = () => {
    this.props.apiaccess
      .changeMonthlyPackage(
        this.props.student.id,
        this.state.setPackage,
        this.state.selectedPackage
      )
      .then((data) => {
        //do anything?
      });
    this.closeModal();
    this.setState({
      alertOpen: true
    });
  };

  packageChanged = (changeEvent) => {
    this.setState({
      selectedPackage: changeEvent.target.value
    });
  };

  closeModal = () => {
    this.setState({
      optionsModalOpen: false,
      selectedPackage: this.state.setPackage
    });
  };

  componentDidMount = () => {
    if (this.props.student.monthly_package === 'deepdive') {
      this.props.apiaccess.getAllSessions(this.props.student.id).then((data) => {
        let tempState = {};

        let heldSessions = data.held_sessions;
        if (heldSessions) {
          tempState['heldSessions'] = heldSessions;
        } else {
          tempState['heldSessions'] = [];
        }

        let upcomingSessions = data.upcoming_sessions;
        if (upcomingSessions) {
          tempState['upcomingSessions'] = upcomingSessions;
        } else {
          tempState['upcomingSessions'] = [];
        }

        this.setState(tempState);
      });
    } else {
      this.props.apiaccess.getThisMonthsSessions('student', this.props.student.id).then((data) => {
        let sessions = data.sessions;
        if (sessions) {
          this.setState({ thisMonthSessions: sessions });
        } else {
          this.setState({ thisMonthSessions: [] });
        }
      });
    }
  };

  stripeCheckout = () => {
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.props.stripeCheckoutSession.id
      })
      .then(function(result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result);
      });
  };

  render() {
    if (this.props.autoBillCC) {
      var ccLine = (
        <p>
          Automatically charged on the 3rd of each month to {this.props.autoBillCC}.{' '}
          <Link to="#" onClick={() => this.setState({ optionsModalOpen: true })}>
            Change or cancel next month
          </Link>
        </p>
      );
    } else {
      var ccLine = (
        <p>
          <Link to="#" onClick={this.stripeCheckout}>
            Set payment card
          </Link>
          . Future months will be automatically charged on the 3rd.
        </p>
      );
    }
    var jumbotronText = (
      <div>
        <p>
          ${this.state.monthlyRate} for {this.state.baseSessions} prepaid sessions per month. Any
          additional sessions held are ${this.state.addionalSessions} each.
        </p>
        {ccLine}
      </div>
    );
    if (this.state.setPackage === 'deepdive') {
      var depositLine = (
        <p>
          Deposit of ${this.state.deepDiveDeposit} is due now. It will be automatically charged on
          the 3rd of the month to {this.props.autoBillCC}.
        </p>
      );
      if (!this.props.autoBillCC)
        depositLine = (
          <p>
            A deposit of ${this.state.deepDiveDeposit} is due now.{' '}
            <Link to="#" onClick={this.stripeCheckout}>
              Set payment card
            </Link>
            .
          </p>
        );
      var totalLine = (
        <p>
          The balance
          {this.state.deepDiveDepositPaid
            ? ' of $' + (this.state.deepDiveTotal - this.state.deepDiveDeposit)
            : ''}{' '}
          will be automatically charged on{' '}
          {moment(this.state.deepDiveBalanceChargeDate).format('M/D/YYYY')}
          {this.props.autoBillCC ? ' to ' + this.props.autoBillCC + '.' : '.'}
        </p>
      );

      jumbotronText = (
        <div>
          <p>
            ${this.state.deepDiveTotal} for the initial {this.state.deepDiveNumSessions} session
            package. If you keep going longer, it is ${this.state.deepDiveAdditionalRate} each
            session afterwards.
          </p>
          {!this.state.deepDiveDepositPaid ? depositLine : ''}
          {!this.state.deepDiveBalancePaid ? totalLine : ''}
        </div>
      );
    }
    if (this.state.setPackage === 'pause')
      jumbotronText = (
        <p>
          Paused. The mentor will stay assigned if paused during summer or for 1 month during the
          school year. An individual session is ${160 + this.state.extraChargePerSession}.
        </p>
      );
    if (this.state.setPackage === 'cancel')
      jumbotronText = (
        <p>
          Canceled. The mentor will be assigned a different student, please get back in touch if you
          are interested in the future.
        </p>
      );

    return (
      <div style={{ marginBottom: 60 }}>
        <h2>
          {this.props.student.name} {this.props.student.description}
        </h2>
        <Alert
          color="success"
          isOpen={this.state.alertOpen}
          toggle={() => this.setState({ alertOpen: false })}
        >
          Your change for next month has been submitted and will be processed soon. We will follow
          up by email.
        </Alert>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <Jumbotron>
              <h3 className="jumbotron-header">
                {this.state.setPackage === 'deepdive' ? 'Deep Dive Package' : 'Monthly Package'}
              </h3>
              {jumbotronText}
            </Jumbotron>
          </Col>
        </Row>
        <div>
          {this.state.setPackage === 'deepdive' ? (
            <Fragment>
              <h3 className="light-bottom-border" style={{ color: '#444' }}>
                {this.state.upcomingSessions.length} upcoming sessions
              </h3>
              {this.state.upcomingSessions
                .filter((session) => {
                  return !session.isCanceled;
                })
                .map((session) => {
                  const displayDateStr = moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format(
                    'dddd, MMMM Do YYYY'
                  );
                  let tzString = session.datetimestr.split(' ')[2];
                  const displayTimeStr =
                    moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format('h:mm a') +
                    ' ' +
                    tzString;
                  return (
                    <div className="long-date">
                      {displayDateStr} <span className="timeDisplay">{displayTimeStr}</span>
                    </div>
                  );
                })}
              <h3 className="light-bottom-border" style={{ color: '#444' }}>
                {this.state.heldSessions.length} held sessions
              </h3>
              {this.state.heldSessions
                .filter((session) => {
                  return !session.isCanceled;
                })
                .map((session) => {
                  const displayDateStr = moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format(
                    'dddd, MMMM Do YYYY'
                  );
                  let tzString = session.datetimestr.split(' ')[2];
                  const displayTimeStr =
                    moment(session.datetimestr, 'MM-DD-YYYY HH:mm').format('h:mm a') +
                    ' ' +
                    tzString;
                  return (
                    <div className="long-date">
                      {displayDateStr} <span className="timeDisplay">{displayTimeStr}</span>
                    </div>
                  );
                })}
            </Fragment>
          ) : (
            <Fragment>
              <h3 className="light-bottom-border" style={{ color: '#444' }}>
                Sessions This Month
              </h3>
              <SessionDateButtons
                sessions={this.state.thisMonthSessions}
                hideRecent={false}
                defaultDescription="Nothing on the calendar yet."
                removeCanceled={null}
                userID={this.props.userID}
                apiaccess={this.props.apiaccess}
                timezone={this.props.timezone}
              />
            </Fragment>
          )}
        </div>

        <Modal centered isOpen={this.state.optionsModalOpen}>
          <ModalBody>
            <h2>Package Options</h2>
            <Form>
              <FormGroup
                tag="fieldset"
                style={{ fontSize: '20px', fontWeight: '200', lineHeight: '25px' }}
              >
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="package"
                      value="2sessions"
                      checked={
                        this.state.selectedPackage === '2sessions' ||
                        this.state.selectedPackage === '2sessions2023' ||
                        this.state.selectedPackage === '2sessions2022'
                      }
                      onChange={this.packageChanged}
                    />{' '}
                    2 prepaid sessions per month for $
                    {
                      this.state.selectedPackage.includes('2023') ? 335 + 2 * this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 310 + 2 * this.state.extraChargePerSession :
                          375 + 2 * this.state.extraChargePerSession
                    }
                    . Additional sessions are $
                    {
                      this.state.selectedPackage.includes('2023') ? 140 + this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 130 + this.state.extraChargePerSession :
                          150 + this.state.extraChargePerSession
                    }{' '}
                    each.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="package"
                      value="3sessions"
                      checked={
                        this.state.selectedPackage === '3sessions' ||
                        this.state.selectedPackage === '3sessions2023' ||
                        this.state.selectedPackage === '3sessions2022'
                      }
                      onChange={this.packageChanged}
                    />{' '}
                    3 prepaid sessions per month for $
                    {
                      this.state.selectedPackage.includes('2023') ? 425 + 3 * this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 390 + 3 * this.state.extraChargePerSession :
                          475 + 3 * this.state.extraChargePerSession
                    }
                    . Additional sessions are $
                    {
                      this.state.selectedPackage.includes('2023') ? 110 + this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 100 + this.state.extraChargePerSession :
                          125 + this.state.extraChargePerSession
                    }{' '}
                    each.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="package"
                      value="6sessions"
                      checked={
                        this.state.selectedPackage === '6sessions' ||
                        this.state.selectedPackage === '6sessions2023' ||
                        this.state.selectedPackage === '6sessions2022'
                      }
                      onChange={this.packageChanged}
                    />{' '}
                    6 prepaid sessions per month for $
                    {
                      this.state.selectedPackage.includes('2023') ? 700 + 6 * this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 640 + 6 * this.state.extraChargePerSession :
                          775 + 6 * this.state.extraChargePerSession
                    }{' '}
                    (scheduled twice per week). Additional sessions are $
                    {
                      this.state.selectedPackage.includes('2023') ? 100 + this.state.extraChargePerSession :
                        this.state.selectedPackage.includes('2022') ? 95 + this.state.extraChargePerSession :
                          120 + this.state.extraChargePerSession
                    }{' '}
                    each.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="package"
                      value="pause"
                      checked={this.state.selectedPackage === 'pause'}
                      onChange={this.packageChanged}
                    />{' '}
                    Pause - the mentor will stay assigned if paused during summer or for 1 month
                    during the school year. An individual session is $200.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="package"
                      value="cancel"
                      checked={this.state.selectedPackage === 'cancel'}
                      onChange={this.packageChanged}
                    />{' '}
                    Cancel - contact if interested again in the future.
                  </Label>
                </FormGroup>
                <p style={{ fontSize: 15, marginBottom: 0 }}>
                  If you would like to change your saved card{' '}
                  <Link to="/settings">go to the settings page</Link>.
                </p>
              </FormGroup>
              {this.state.selectedPackage != this.state.setPackage && (
                <Button onClick={this.submitRateChange} style={{ marginRight: '20px' }}>
                  Submit
                </Button>
              )}
              <Button onClick={this.closeModal}>
                {this.state.selectedPackage === this.state.setPackage ? 'Done' : 'Cancel'}
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

class MonthlyBillingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentReceivedDialogOpen: this.props.paymentReceivedDialogOpen,
      autoBillCC: this.props.autoBillCC,
      //paymentDollars: this.props.studentList.reduce((a, b) => a + b.monthly_rate, 0) - this.props.creditsRemaining,
      stripeCheckoutSession: ''
    };

    //if they don't have a credit card setup
    if (!this.state.autoBillCC) {
      this.getCheckoutSession();
    }
  }

  getCheckoutSession = () => {
    this.props.apiaccess.stripeCheckoutSession().then((data) => {
      this.setState({
        stripeCheckoutSession: data.stripe_checkout_session
      });
    });
  };

  render() {
    return (
      <div>
        {this.props.studentList.map((student) => (
          <StudentMonthlyBilling
            student={student}
            key={student.id}
            userID={this.props.userID}
            autoBillCC={this.state.autoBillCC}
            stripeCheckoutSession={this.state.stripeCheckoutSession}
            apiaccess={this.props.apiaccess}
            timezone={this.props.timezone}
          />
        ))}

        <Modal isOpen={this.state.paymentReceivedDialogOpen}>
          <ModalBody>
            <h2>Thank you!</h2>
            <p>Your payment has been received. A receipt will be emailed soon.</p>
            <Button
              color="success"
              onClick={() => {
                this.setState({ paymentReceivedDialogOpen: false });
                this.props.history.push('/billing');
              }}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(MonthlyBillingPage);
