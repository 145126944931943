import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LandingPage from './LandingPage';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      modal: true,
      email: '',
      password: ''
    };
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  login = () => {
    this.setState({
      isLoading: true
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  closeModal = () => {
    this.setState({
      modal: false
    });
  };

  componentDidMount = () => {
    this.props.show();
  };

  render() {
    return <LandingPage />;
  }
}

Login.propTypes = {
  show: PropTypes.func.isRequired
};

export default withRouter(Login);
