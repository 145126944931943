import React from 'react';
import PropTypes from 'prop-types';
import { ShowMoreButton, SessionDateButtons } from './PortalButtons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Button, Col, Row, Container } from 'reactstrap';
import actions from '../util/actions';
import RegularBackupTimes from './RegularBackupTimes';

function HoursCoding(props) {
  return (
    <div>
      <span className="num-sessions">{props.hours}</span> hours coding with Breakout Mentors!
    </div>
  );
}

HoursCoding.propTypes = {
  hours: PropTypes.number.isRequired
};

function MentorDetails(props) {
  return (
    <div className="mentor-details">
      <h3>Mentor</h3>
      <p>{props.name}</p>
      <p>{props.email}</p>
      <p>{props.phone}</p>
    </div>
  );
}

MentorDetails.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
};

class MonthlyRecaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaps: [],
      folder: ''
    };
  }

  componentWillMount = () => {
    this.updateMonthlyRecapLinks(this.props.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.id != this.props.id) {
      this.updateMonthlyRecapLinks(nextProps.id);
    }
  };

  updateMonthlyRecapLinks = (id) => {
    //only do API call if the id is in the student list
    if (this.props.studentList.every((student) => student.id != id)) {
      console.log('no access');
      return;
    }

    this.props.apiaccess.getMonthlyRecaps(id).then((data) => {
      this.setState({
        folder: data.folder,
        recaps: data.recaps
      });
    });
  };

  render = () => {
    var recapList = this.state.recaps.map((recap) => (
      <li key={recap.id}>
        <Link to={{ pathname: recap.url }} target="_blank">
          {' '}
          <FontAwesome name="file-text-o" style={{ fontSize: 15 }} />{' '}
          {moment.months(recap.month - 1)} {recap.year}
        </Link>
      </li>
    ));
    if (recapList.length == 5) {
      //don't show the 5th, show the View All button instead
      recapList.pop();
      recapList.push(
        <li key="all">
          <Link
            to={{ pathname: 'https://drive.google.com/drive/u/0/folders/' + this.state.folder }}
            target="_blank"
          >
            View All <FontAwesome name="external-link" style={{ fontSize: 16 }} />
          </Link>
        </li>
      );
    }

    return (
      <div className="monthlyRecaps">
        <h3>Session Recaps</h3>
        {this.state.recaps.length == 0 ? <p>Coming Soon</p> : recapList}
      </div>
    );
  };
}

export default class StudentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentName: '',
      hoursCoding: 0,
      mentorName: '',
      mentorEmail: '',
      mentorPhone: '',
      previousSessionList: [],
      nextSession: [],
      sessionsDisplayed: 5,
      primarySessionTime: '',
      backupSessionTime: '',
      communicationGroupID: null
    };
  }

  componentWillMount = () => {
    this.getInfoFromDatabase(this.props.match.params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.id != nextProps.match.params.id) {
      this.setState({
        previousSessionList: [],
        nextSession: [],
        sessionsDisplayed: 5
      });
      this.getInfoFromDatabase(nextProps.match.params.id);
    }
  };

  getInfoFromDatabase = (id) => {
    //only do API call if the id is in the student list
    if (this.props.studentList.every((student) => student.id != id)) {
      console.log('no access');
      return;
    }

    this.props.apiaccess.getStudentAndMentorDetails(id).then((info) => {
      this.setState({
        studentName: info.student_name,
        hoursCoding: Math.round(info.student_hours),
        mentorName: info.mentor_name,
        mentorEmail: info.mentor_email,
        mentorPhone: info.mentor_phone,
        primarySessionTime: info.primary_day_time,
        backupSessionTime: info.backup_day_time
      });
    });

    this.props.apiaccess.getCommunicationGroup('student', id).then((data) => {
      console.log('look for comm group');
      console.log(data.communicationGroupID);
      this.setState({ communicationGroupID: data.communicationGroupID });
    });

    this.props.apiaccess.getThisMonthsSessions('student', id).then((data) => {
      this.setState({ nextSession: data.sessions });
    });

    this.props.apiaccess.getPreviousSessions(id, '0', 5).then((data) => {
      this.setState({ previousSessionList: data.sessions });
    });
  };

  updateThisMonthInfo = () => {
    //if a session was confirmed, moved, or canceled, this will be called by the sessionChanged prop
    //we want to reload the info for all the buttons on the page
    this.props.apiaccess
      .getThisMonthsSessions('student', this.props.match.params.id)
      .then((data) => {
        this.setState({ nextSession: data.sessions });
      });
  };

  updatePreviousInfo = () => {
    //if a session was confirmed, moved, or canceled, this will be called by the sessionChanged prop
    //we want to reload the info for all the buttons on the page
    this.props.apiaccess
      .getPreviousSessions(this.props.match.params.id, '0', this.state.sessionsDisplayed)
      .then((data) => {
        this.setState({ previousSessionList: data.sessions });
      });
  };

  handleShowMore = () => {
    this.props.apiaccess
      .getPreviousSessions(
        this.props.match.params.id,
        this.state.sessionsDisplayed,
        this.state.sessionsDisplayed + 5
      )
      .then((data) => {
        if (data.sessions) {
          this.setState({
            previousSessionList: this.state.previousSessionList.concat(data.sessions)
          });
        }
      });

    this.setState({ sessionsDisplayed: this.state.sessionsDisplayed + 5 });
  };

  render = () => {
    let showMoreDisplay = true;
    if (this.state.previousSessionList.length < this.state.sessionsDisplayed) {
      showMoreDisplay = false;
    }

    return (
      <div>
        <h1>{this.state.studentName}</h1>

        <Container fluid className="Student-Main">
          <Row>
            <Col xs={12} sm={7} lg={6} className="hours-coding">
              <HoursCoding hours={this.state.hoursCoding} />

              <RegularBackupTimes
                apiaccess={this.props.apiaccess}
                studentID={this.props.match.params.id}
                primarySessionTime={this.state.primarySessionTime}
                backupSessionTime={this.state.backupSessionTime}
                getInfoFromDatabase={this.getInfoFromDatabase}
                communicationGroupID={this.state.communicationGroupID}
              />

              <MentorDetails
                name={this.state.mentorName}
                email={this.state.mentorEmail}
                phone={this.state.mentorPhone}
              />
            </Col>
            <Col xs={12} sm={5} lg={6}>
              <MonthlyRecaps
                id={this.props.match.params.id}
                apiaccess={this.props.apiaccess}
                studentList={this.props.studentList}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ul className="studentPageSessions">
                <div>
                  <h2 className="light-bottom-border">Sessions This Month</h2>
                  <SessionDateButtons
                    sessions={this.state.nextSession}
                    userID={this.props.userID}
                    defaultDescription="No scheduled sessions"
                    hideRecent={false}
                    sessionChanged={this.updatePreviousInfo}
                    apiaccess={this.props.apiaccess}
                    timezone={this.props.timezone}
                  />
                </div>

                <div>
                  <h2 className="light-bottom-border">All Sessions Held</h2>
                  <SessionDateButtons
                    sessions={this.state.previousSessionList}
                    userID={this.props.userID}
                    defaultDescription="No previous sessions"
                    hideRecent={false}
                    sessionChanged={this.updateThisMonthInfo}
                    apiaccess={this.props.apiaccess}
                    timezone={this.props.timezone}
                  />
                </div>
              </ul>
            </Col>
          </Row>
        </Container>

        <ShowMoreButton onClick={this.handleShowMore} showing={showMoreDisplay} />
      </div>
    );
  };
}

StudentPage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};
