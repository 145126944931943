import React from 'react';

export default function Loading(props) {
  return (
    <div className="loadingCenter">
      <h2>Loading...</h2>
      <img src="https://breakoutmentors.com/wp-content/uploads/2022/09/spinner.gif" alt="loading" />
    </div>
  );
}
