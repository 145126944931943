export default class MemoryStorage {
  /* should use localStorage if it works */

  constructor() {
    this.canUseLocalStorage = this.lsTest();
    this.store = {};
  }

  setItem(key, value) {
    if (this.canUseLocalStorage) {
      localStorage.setItem(key, value);
    } else {
      this.store[key] = value.toString(); // Just like Localstorage
    }
  }

  getItem(key) {
    if (this.canUseLocalStorage) {
      return localStorage.getItem(key);
    } else {
      return this.store[key];
    }
  }

  removeItem(key) {
    if (this.canUseLocalStorage) {
      localStorage.removeItem(key);
    } else {
      delete this.store[key];
    }
  }

  lsTest() {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
