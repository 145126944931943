import React from 'react';
import { PropTypes } from 'prop-types';
import actions from '../util/actions';

import { Link } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Col,
  Row,
  Card,
  CardText,
  CardTitle,
  CardBody,
  Container,
  Jumbotron
} from 'reactstrap';

export default class PayBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      autoPaymentChecked: false
    };

    this.handler = StripeCheckout.configure({
      key:
        !import.meta.env && process.env.APP_SETTINGS === 'config.ProductionConfig'
          ? 'pk_live_ELbCtO8LNvXZejT2eIFrHkLu'
          : 'pk_test_jeId9pl1YkbzuwmRG2uI6wAR',
      name: 'Breakout Mentors',
      email: props.email,
      zipCode: true,
      allowRememberMe: false,
      token: (token) => {
        this.props.onPaymentSubmitted();

        let detailsText =
          this.props.payMode === 'prepay'
            ? 'Paid $' + this.props.activeTotal + ' for ' + this.props.activeTotalValue + ' credits'
            : 'Paid $' + this.props.activeTotal;
        this.props.apiaccess
          .paymentMade(
            this.props.activeTotal,
            Math.round(this.props.activeTotalValue),
            token.id,
            this.props.userID,
            detailsText,
            this.state.autoPaymentChecked
          )
          .then((trans) => {
            if (trans) this.props.onPaymentSuccess();
            else this.props.onPaymentError();
          });
      }
    });

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.checked;

    this.setState({
      autoPaymentChecked: value
    });
  }

  roundToTwo = (num) => {
    if (num % 1 === 0) {
      return num;
    } else {
      return num.toFixed(2);
    }
  };

  handleSwitchToPrepay = (event) => {
    const target = event.target;

    this.setState({
      autoPaymentChecked: false
    });

    this.props.handlePrepayClick();
  };

  /*
                  <Button onClick={this.handleSwitchToPrepay} active={this.props.payMode === 'prepay'}>
                    Prepay for 10 sessions with a 10% discount
                  </Button>
*/

  render = () => {
    return (
      <Container fluid>
        <Row>
          <Col
            sm={12}
            md={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            xl={{ size: 6, offset: 3 }}
          >
            {!this.props.autoBillCC && (
              <Card>
                <ButtonGroup id="billing-btn-group" className="flex-group center-button-group">
                  <Button
                    onClick={this.props.handlePayAsYouGoClick}
                    active={this.props.payMode === 'pay-as-you-go'}
                  >
                    Pay per session
                  </Button>
                </ButtonGroup>
                <CardBody>
                  <CardTitle tag="h2">
                    Amount Due: $
                    <span className="total">{this.roundToTwo(this.props.activeTotal)}</span>
                  </CardTitle>

                  {this.props.payMode == 'pay-as-you-go' &&
                    !this.props.autoBillCC &&
                    this.props.activeTotal > 0 && (
                      <div>
                        <p className="check-box">
                          <input
                            type="checkbox"
                            checked={this.state.autoPaymentChecked}
                            onChange={this.handleInputChange}
                          />
                          &nbsp;Enable automatic payments
                        </p>
                      </div>
                    )}

                  {this.props.activeTotal > 0 && (
                    <Button
                      block
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.handler.open({
                          name: 'Breakout Mentors',
                          amount: this.props.activeTotal * 100,
                          image: 'https://breakoutmentors.com/wp-content/uploads/2022/09/bm_guy.png'
                        });
                      }}
                    >
                      Pay Now
                    </Button>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    );
  };
}

PayBox.propTypes = {
  handlePrepayClick: PropTypes.func.isRequired,
  handlePayAsYouGoClick: PropTypes.func.isRequired,
  payMode: PropTypes.string.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
