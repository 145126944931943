import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import { SessionDateButtons } from './PortalButtons';
import moment from 'moment';

export default class GroupNextSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      nextSessions: [],
      groupID: this.props.groupID
    };
  }

  componentDidMount = () => {
    this.loadInfo(this.props.groupID);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.groupID != nextProps.groupID) {
      this.loadInfo(nextProps.groupID);
    }
  };

  loadInfo = (groupID) => {
    this.props.apiaccess.getThisMonthsSessions('group', groupID).then((data) => {
      this.setState({
        sessions: data.sessions,
        groupID: groupID.groupID
      });
    });

    this.props.apiaccess.getGroupNextSession(groupID).then((data) => {
      this.setState({
        nextSessions: data.sessions
      });
    });
  };

  updateInfo = () => {
    //if a session was confirmed, moved, or canceled, this will be called by the sessionChanged prop
    //we want to reload the info for all the buttons on the page
    if (this.props.reloadFromAPI) this.props.reloadFromAPI();
  };

  //only shows 'Next Session' if it is next month
  render() {
    return (
      <div>
        <h3 className="light-bottom-border">Sessions This Month</h3>
        <SessionDateButtons
          sessions={this.state.sessions}
          userID={this.props.userID}
          defaultDescription="Nothing on the calendar yet."
          hideRecent={false}
          sessionChanged={this.updateInfo}
          timezone={this.props.timezone}
          apiaccess={this.props.apiaccess}
        />

        {this.state.nextSessions.length > 0 &&
          moment(this.state.nextSessions[0].datetimestr, 'MM/DD/YYYY HH:mm').month() !==
            moment().month() && (
            <div>
              <h3 className="light-bottom-border">Next Session</h3>
              <SessionDateButtons
                sessions={this.state.nextSessions}
                userID={this.props.userID}
                defaultDescription="Nothing on the calendar yet."
                hideRecent={false}
                sessionChanged={this.updateInfo}
                timezone={this.props.timezone}
                apiaccess={this.props.apiaccess}
              />
            </div>
          )}
      </div>
    );
  }
}

GroupNextSession.propTypes = {
  groupID: PropTypes.number.isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};
