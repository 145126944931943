import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SessionDateButtons } from './PortalButtons';

import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';

import actions from '../util/actions';

import PayBox from './PayBox';

/*
has 'student' prop and 'unpaidessions' state(becuase
the unpaid session for this student may change)
*/

class StudentCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unpaidsessions: []
    };
  }

  removeCanceledSession = (overWeekOld) => {
    this.props.subtractFromTotal(this.props.student.rate, overWeekOld);
    this.loadStudentsSessions(false);
  };

  loadStudentsSessions = (initialLoad) => {
    this.props.apiaccess.getUnpaidSessions(this.props.student.id).then((data) => {
      let sessions = data.sessions;
      if (sessions) {
        this.setState({ unpaidsessions: sessions });

        var recentSessions = sessions.filter((ses) => {
          return !ses.overWeekOld;
        });
        var oldSesions = sessions.filter((ses) => {
          return ses.overWeekOld;
        });
        if (initialLoad) {
          this.props.addToInitialTotal(
            oldSesions.length,
            recentSessions.length,
            this.props.student.rate
          );
        }
      } else {
        this.setState({ unpaidsessions: [] });
      }
    });
  };

  componentDidMount = () => {
    this.loadStudentsSessions(true);
  };

  render() {
    //if pay for 10 and no unpaid sessions (length 0 or everything is newer than a week ago), don't show anything
    return this.props.hideRecent &&
      (this.state.unpaidsessions.length === 0 ||
        Object.values(this.state.unpaidsessions).every(function (value) {
          return value.overWeekOld === false;
        })) ? null : (
      <div>
        <h3 className="light-bottom-border">
          {this.props.student.name} {this.props.student.description} &ndash; $
          {this.props.student.rate}
        </h3>
        <SessionDateButtons
          sessions={this.state.unpaidsessions}
          hideRecent={this.props.hideRecent}
          defaultDescription="No payment due."
          removeCanceled={this.removeCanceledSession}
          userID={this.props.userID}
          apiaccess={this.props.apiaccess}
          timezone={this.props.timezone}
        />
      </div>
    );
  }
}

StudentCart.propTypes = {
  student: PropTypes.object.isRequired,
  addToInitialTotal: PropTypes.func.isRequired,
  subtractFromTotal: PropTypes.func.isRequired,
  hideRecent: PropTypes.bool.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unpaidOldTotal: 0 /*The old sessions*/,
      unpaidRecentTotal: 0 /*The sessions within the last week, allowed to be part of 10 session package*/,
      prepayTotal: 0 /*the amount for purchasing 10 sessions*/,
      prepayTotalValue: 0 /*the value received when purchasing 10 sessions (like paying $1170 for $1300 of sessions)*/,
      activeTotal: 0 /*include the old (and recent if pay-as-you-go) sessions and prepay if that is selected*/,
      activeTotalValue: 0 /*This will be different from the active total if they got a 10% discount (like paying $1170 for $1300 of sessions)*/,
      creditsApplied: 0 /*Credits remaining that are applied to bill (can't go negative, 10 session limits)*/,
      payMode: 'pay-as-you-go',
      paymentAttempted: false
    };
  }

  handlePayAsYouGoClick = () => {
    this.setState({ payMode: 'pay-as-you-go' });
    this.resetActiveTotals('pay-as-you-go');
  };

  handlePrepayClick = () => {
    this.setState({ payMode: 'prepay' });
    this.resetActiveTotals('prepay');
  };

  addToInitialTotal = (unpaidOldSessions, unpaidRecentSessions, studentrate) => {
    var localUnpaidOldTotal = this.state.unpaidOldTotal + unpaidOldSessions * studentrate;
    var localUnpaidRecentTotal = this.state.unpaidRecentTotal + unpaidRecentSessions * studentrate;
    this.setState({
      unpaidOldTotal: localUnpaidOldTotal,
      unpaidRecentTotal: localUnpaidRecentTotal
    });

    this.resetActiveTotals(this.state.payMode);
  };

  resetActiveTotals = (payMode) => {
    if (payMode === 'pay-as-you-go') {
      //only apply credits if it won't make total negative
      var creditsApplied = 0;
      if (
        this.state.unpaidOldTotal + this.state.unpaidRecentTotal - this.props.creditsRemaining >
        0
      ) {
        creditsApplied = this.props.creditsRemaining;
      }
      this.setState({
        activeTotal: this.state.unpaidOldTotal + this.state.unpaidRecentTotal - creditsApplied,
        activeTotalValue: this.state.unpaidOldTotal + this.state.unpaidRecentTotal - creditsApplied,
        creditsApplied: creditsApplied
      });
    } else {
      var creditsApplied = 0;
      if (this.props.creditsRemaining > 0) {
        creditsApplied = this.props.creditsRemaining;
        //can only apply credits to unpaidOldTotal and unpaidRecentTotal
        if (creditsApplied > this.state.unpaidOldTotal + this.state.unpaidRecentTotal) {
          creditsApplied = this.state.unpaidOldTotal + this.state.unpaidRecentTotal;
        }
      } else {
        //if negative, apply
        creditsApplied = this.props.creditsRemaining;
      }
      this.setState({
        activeTotal: this.state.prepayTotal + this.state.unpaidOldTotal - creditsApplied,
        activeTotalValue: this.state.prepayTotalValue + this.state.unpaidOldTotal - creditsApplied,
        creditsApplied: creditsApplied
      });
    }
  };

  calculatePrepayAmount = (studentList) => {
    var sum = studentList.reduce((total, student) => {
      return total + student.rate;
    }, 0);
    var average = sum / studentList.length;
    this.setState({
      prepayTotal: Math.floor(average * 9) /* fix the bug so there are no decimals  */,
      prepayTotalValue: average * 10
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.calculatePrepayAmount(nextProps.studentList);
  };

  componentDidMount = () => {
    this.calculatePrepayAmount(this.props.studentList);
  };

  subtractFromTotal = (number, overWeekOld) => {
    if (overWeekOld) {
      this.setState({ unpaidOldTotal: this.state.unpaidOldTotal - number });
    } else {
      this.setState({ unpaidRecentTotal: this.state.unpaidRecentTotal - number });
    }
    this.resetActiveTotals(this.state.payMode);
  };

  paymentSubmitted = () => {
    this.setState({
      paymentAttempted: true,
      paymentStatus: 'Processing...',
      paymentDescription: '',
      paymentStatusColor: 'primary',
      paymentButtonText: 'Almost done',
      paymentButtonDis: true
    });
  };

  paymentSuccess = () => {
    this.setState({
      paymentStatus: 'Thank you!',
      paymentDescription: 'Your payment has been received. A receipt will be emailed soon.',
      paymentStatusColor: 'success',
      paymentButtonDis: false,
      paymentAction: () => {
        this.setState({
          unpaidOldTotal: 0 /*The old sessions*/,
          unpaidRecentTotal: 0 /*The sessions within the last week, allowed to be part of 10 session package*/,
          prepayTotal: 0 /*the amount for purchasing 10 sessions*/,
          prepayTotalValue: 0 /*the value received when purchasing 10 sessions (like paying $1170 for $1300 of sessions)*/,
          activeTotal: 0 /*include the old (and recent if pay-as-you-go) sessions and prepay if that is selected*/,
          activeTotalValue: 0 /*This will be different from the active total if they got a 10% discount (like paying $1170 for $1300 of sessions)*/,
          creditsApplied: 0 /*Credits remaining that are applied to bill (can't go negative, 10 session limits)*/,
          payMode: 'pay-as-you-go',
          paymentAttempted: false
        });
        this.props.refresh();
      },
      paymentButtonText: 'Close'
    });
  };

  paymentError = () => {
    this.setState({
      paymentStatus: 'ERROR!',
      paymentDescription: 'Your payment was not succeccfully processed. Please try again later.',
      paymentStatusColor: 'danger',
      paymentButtonDis: false,
      paymentAction: () => this.setState({ paymentAttempted: false }),
      paymentButtonText: 'Close'
    });
  };

  roundToTwo = (num) => {
    if (num % 1 === 0) {
      return num;
    } else {
      return num.toFixed(2);
    }
  };

  render() {
    var prepayLineItem;
    if (this.state.payMode === 'prepay') {
      prepayLineItem = (
        <div>
          <h3 className="light-bottom-border">
            10 sessions with 10% discount &ndash; ${this.roundToTwo(this.state.prepayTotal)}
          </h3>
          <Row>
            <Col xs={8} sm={6} md={5} lg={4} className="long-date">
              Applied to future sessions
            </Col>
          </Row>
        </div>
      );
    }

    var creditsLineItem;
    if (this.state.activeTotal > 0) {
      if (this.state.creditsApplied > 0) {
        creditsLineItem = (
          <h3 className="light-bottom-border creditsLineItem">
            Remaining credits &ndash; +${this.state.creditsApplied}
          </h3>
        );
      } else if (this.state.creditsApplied < 0) {
        creditsLineItem = (
          <div>
            <h3 className="light-bottom-border creditsLineItem">
              Additional unpaid credits &ndash; ${Math.abs(this.state.creditsApplied)}
            </h3>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className="long-date">
                See transaction history for details
              </Col>
            </Row>
          </div>
        );
      }
    }

    return (
      <div>
        <PayBox
          payMode={this.state.payMode}
          handlePrepayClick={this.handlePrepayClick}
          handlePayAsYouGoClick={this.handlePayAsYouGoClick}
          activeTotal={this.state.activeTotal}
          activeTotalValue={this.state.activeTotalValue}
          userID={this.props.userID}
          email={this.props.email}
          onPaymentSubmitted={this.paymentSubmitted}
          onPaymentSuccess={this.paymentSuccess}
          onPaymentError={this.paymentError}
          apiaccess={this.props.apiaccess}
          autoBillCC={this.props.autoBillCC}
        />

        <div className="shopping_cart_div">
          <h2>Shopping Cart</h2>
          <ul className="shoppingCart">
            {prepayLineItem}
            {this.props.studentList.map((student) => (
              <StudentCart
                student={student}
                key={student.id}
                addToInitialTotal={this.addToInitialTotal}
                subtractFromTotal={this.subtractFromTotal}
                hideRecent={this.state.payMode !== 'pay-as-you-go'}
                userID={this.props.userID}
                apiaccess={this.props.apiaccess}
              />
            ))}
            {creditsLineItem}
          </ul>
          <Row>
            <Col xs={12}>
              <h2>Total: ${this.roundToTwo(this.state.activeTotal)}</h2>
            </Col>
          </Row>
        </div>
        <Modal centered isOpen={this.state.paymentAttempted}>
          <ModalBody>
            <h2>{this.state.paymentStatus}</h2>
            <p>{this.state.paymentDescription}</p>
            <Button
              disabled={this.state.paymentButtonDis}
              color={this.state.paymentStatusColor}
              onClick={this.state.paymentAction}
            >
              {this.state.paymentButtonText}
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ShoppingCart);

ShoppingCart.propTypes = {
  studentList: PropTypes.array.isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
