import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DIVISION_TO_CONCEPT, DIVISION_TO_DISPLAY_TITLE } from './CompetitiveGymConstants';
import moment from 'moment';

const MONTH_TO_INDEX = {
  January: 0,
  February: 1,
  March: 2,
  December: 11
};

const DIVISION_TO_COLOR = {
  gold: '#C9B03780',
  silver: '#B4B4B480',
  bronze: '#6A380580',
  pre_bronze: '#6A380580',
  platinum: '#E5E4E2CD'
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function (props) {
  const [studentEvaluations, setStudentEvaluations] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphDataSet, setGraphDataSet] = useState([]);
  const [graphColors, setGraphColors] = useState([]);
  const [formsIndex, setFormsIndex] = useState(0);

  useEffect(() => {
    props.apiaccess
      .getSubmittedCompetitiveForms('student_evaluation', props.competitiveStudent)
      .then((data) => {
        setStudentEvaluations(data.submissions);
      });

    props.apiaccess
      .getSubmittedCompetitiveForms('contest', props.competitiveStudent)
      .then((data) => {
        let submissions = data.submissions;

        let submissionsWithDate = submissions.map((item) => {
          return {
            ...item,
            contest_timestamp: new Date(
              item.response.contest_info.year,
              MONTH_TO_INDEX[item.response.contest_info.month],
              2
            )
          };
        });

        submissionsWithDate.sort((a, b) => (a.contest_timestamp > b.contest_timestamp ? 1 : -1));

        let labelArray = [];
        let dataArray = [];
        let backgroundColorArray = [];

        // console.log(submissionsWithDate);
        for (let i = 0; i < submissionsWithDate.length; i++) {
          let submission = submissionsWithDate[i];
          // console.log(submission);
          labelArray.push(
            submission.response.contest_info.month +
              ' ' +
              submission.response.contest_info.year.valueOf()
          );
          dataArray.push(parseInt(submission.response.contest_info.score));
          // console.log(submission.response.contest_info.division);
          backgroundColorArray.push(DIVISION_TO_COLOR[submission.response.contest_info.division]);
        }

        setGraphLabels(labelArray);
        setGraphDataSet(dataArray);
        setGraphColors(backgroundColorArray);
        // console.log(labelArray);
        // console.log(dataArray);
      });
  }, []);

  return (
    <>
      <h1>Survey History</h1>
      {graphDataSet !== [] && graphLabels !== [] && graphColors !== [] && (
        <div>
          <div>
            <Bar
              datasetIdKey="id"
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  y: {
                    display: true,
                    beginAtZero: true,
                    max: 1000,
                    min: 0
                  }
                }
              }}
              data={{
                labels: graphLabels,
                datasets: [
                  {
                    label: 'Score',
                    data: graphDataSet,
                    backgroundColor: graphColors
                  }
                ]
              }}
            />
          </div>
        </div>
      )}
      {studentEvaluations !== null && (
        <div>
          <div>
            <label style={{ marginRight: '0.6rem' }}>Date Submitted</label>
            <select
              onChange={(e) => {
                setFormsIndex(parseInt(e.target.value));
              }}
            >
              {studentEvaluations.map((item, index) => (
                <option value={index.toString()}>
                  {moment.unix(item.timestamp).format('MM-DD-YYYY h:mm')}
                </option>
              ))}
            </select>
          </div>
          <div>
            {studentEvaluations.length > formsIndex && (
              <div>
                <div>
                  <h2>
                    Division:{' '}
                    {
                      DIVISION_TO_DISPLAY_TITLE[
                        studentEvaluations[formsIndex]['response']['background_info']['division']
                      ]
                    }
                  </h2>
                </div>
                <div>
                  {studentEvaluations[formsIndex]['response']['background_info'][
                    'other_comments'
                  ] && (
                    <div>
                      <h3>Other Comments</h3>
                      <p>
                        {
                          studentEvaluations[formsIndex]['response']['background_info'][
                            'other_comments'
                          ]
                        }
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  {['bronze', 'silver', 'gold_plus'].map((divisionLabel) => {
                    let formData = studentEvaluations[formsIndex]['response'];
                    return (
                      formData[divisionLabel] && (
                        <div>
                          <h3>{DIVISION_TO_DISPLAY_TITLE[divisionLabel]}</h3>
                          {DIVISION_TO_CONCEPT[divisionLabel].map((section) => (
                            <div>
                              <h4>{section.header}</h4>
                              {section.concepts.map((concept) => {
                                let correspondingEntry = formData[concept.displayTitle];
                                if (correspondingEntry) {
                                  return <p>{concept.displayTitle + ': ' + correspondingEntry}</p>;
                                }
                              })}
                            </div>
                          ))}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
