import React from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter, generatePath } from 'react-router-dom';
import { Button, Jumbotron } from 'reactstrap';

import actions from '../util/actions';

import ShoppingCart from './ShoppingCart';
import PaymentHistory from './PaymentHistory';
import MonthlyBillingPage from './MonthlyBillingPage';

import FontAwesome from 'react-fontawesome';

function RemainingCreditsBox(props) {
  return props.creditsRemaining < 1 ? (
    <div></div>
  ) : (
    <Jumbotron>
      <p>
        <span className="num-sessions">{props.creditsRemaining}</span> remaining credits
      </p>
      <p className="creditDetailsLink">
        <Link to="/billing?history=true">See details</Link>
      </p>
    </Jumbotron>
  );
}

function AutoBillEnabledBox(props) {
  return !props.autoBillCC ? (
    <div></div>
  ) : (
    <Jumbotron>
      <h2 className="jumbotron-header">Automatic Payments</h2>
      <p>Sessions will automatically be charged to {props.autoBillCC}.</p>
      <p className="disableAutoBill">
        <Link to="#" onClick={props.disableAutoBill}>
          {' '}
          Click here to deactivate
        </Link>
      </p>
      <p className="creditDetailsLink">
        <Link to="/billing?history=true"> View payment history</Link>
      </p>
    </Jumbotron>
  );
}

RemainingCreditsBox.propTypes = {
  creditsRemaining: PropTypes.number.isRequired
};

class BillingPage extends React.Component {
  constructor(props) {
    super(props);

    var newState = {
      transactionHistory: this.getParams(props.location).history === 'true',
      autoBillCC: this.props.autoBillCC,
      paymentReceivedDialogOpen: false
      //checkout_session_id: this.getParams(props.location).checkout_session_id, //used by stripe for payment
      //stripe_cancel: this.getParams(props.location),
    };

    if (this.getParams(props.location).checkout_session_id != '') {
      newState.autoBillCC = 'CARD';
      newState.paymentReceivedDialogOpen = true;

      //need to make the API call to bill the card
      var paymentDollars =
        this.props.studentList.reduce(
          (a, b) => a + (b.monthly_rate + (!b.deep_dive_deposit_paid ? b.deep_dive_deposit : 0)),
          0
        ) - this.props.creditsRemaining;
      this.props.apiaccess.stripeNewCustomerMakeCharge(
        paymentDollars,
        this.getParams(props.location).checkout_session_id
      );
    }

    this.state = newState;

    //if checkout_session_id param, we don't need to set the state necessarily (same with cancel)
    //need to make the right API call
    //also need to call MonthlyBillingPage paymentSuccess
    //actually just repurpose that modal to single use, then use a showModal prop
  }

  getParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return {
      history: searchParams.get('history') || '',
      checkout_session_id: searchParams.get('checkout_session_id') || '',
      stripe_cancel: searchParams.get('stripe_cancel') || ''
    };
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({ transactionHistory: this.getParams(nextProps.location).history === 'true' });
    //I think will always do the constructor when coming back from stripe checkout page, so no need to handle
  };

  gotoTransactionHistory = () => {
    //this.setState({transactionHistory: true});
    this.props.history.push('/billing?history=true');
  };

  gotoBilling = () => {
    //this.setState({transactionHistory: false});
    this.props.history.push('/billing');
  };

  disableUserAutoBilling = () => {
    this.props.apiaccess.disableAutoBilling(this.props.userID);
    this.props.refresh();
  };

  render() {
    if (this.state.transactionHistory)
      return (
        <PaymentHistory
          familyID={this.props.familyID}
          gotoBilling={this.gotoBilling}
          apiaccess={this.props.apiaccess}
        />
      );

    return (
      <div>
        <div>
          <h1 className="billing-history-heading">Billing</h1>
          <Button
            className="billing-history-button"
            color="link"
            onClick={this.gotoTransactionHistory}
          >
            View Transaction History
            <FontAwesome name="chevron-right" className="chevron-rt" />
          </Button>
        </div>

        {this.props.monthlyBilling ? (
          <div>
            {this.props.creditsRemaining > 0 && (
              <Jumbotron>
                <p style={{ marginBottom: 0 }}>
                  <span className="num-sessions">${this.props.creditsRemaining}</span> in credits.{' '}
                  <Link to="/billing?history=true">See details</Link>
                </p>
              </Jumbotron>
            )}
            {this.props.creditsRemaining < 0 && (
              <Jumbotron>
                <p style={{ marginBottom: 0 }}>
                  <span className="num-sessions">${Math.abs(this.props.creditsRemaining)}</span>{' '}
                  additional owed. <Link to="/billing?history=true">See details</Link>
                </p>
              </Jumbotron>
            )}
            <MonthlyBillingPage
              {...this.props}
              autoBillCC={this.state.autoBillCC}
              paymentReceivedDialogOpen={this.state.paymentReceivedDialogOpen}
            />
          </div>
        ) : (
          <div>
            <RemainingCreditsBox creditsRemaining={this.props.creditsRemaining} />
            <AutoBillEnabledBox
              autoBillCC={this.state.autoBillCC}
              disableAutoBill={this.disableUserAutoBilling}
            />
            <ShoppingCart
              studentList={this.props.studentList}
              timezone={this.props.timezone}
              creditsRemaining={this.props.creditsRemaining}
              userID={this.props.userID}
              email={this.props.email}
              refresh={this.props.refresh}
              apiaccess={this.props.apiaccess}
              autoBillCC={this.state.autoBillCC}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(BillingPage);

BillingPage.propTypes = {
  creditsRemaining: PropTypes.number.isRequired,
  studentList: PropTypes.arrayOf(PropTypes.object).isRequired,
  userID: PropTypes.number.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  timezone: PropTypes.string.isRequired
};
