import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import {
  UncontrolledDropdown,
  Alert,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

//Should be an administrator!

function PackageDropdown(props) {
  const selectPackage = (x) => () => props.selectPackage(x);
  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{props.rateData.monthly_package}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectPackage('2sessions')} style={{ fontSize: '12px' }}>
          2sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions')} style={{ fontSize: '12px' }}>
          3sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions')} style={{ fontSize: '12px' }}>
          6sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('2sessions2023')} style={{ fontSize: '12px' }}>
          2sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions2023')} style={{ fontSize: '12px' }}>
          3sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions2023')} style={{ fontSize: '12px' }}>
          6sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('2sessions2022')} style={{ fontSize: '12px' }}>
          2sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions2022')} style={{ fontSize: '12px' }}>
          3sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions2022')} style={{ fontSize: '12px' }}>
          6sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('starting')} style={{ fontSize: '12px' }}>
          starting
        </DropdownItem>
        <DropdownItem onClick={selectPackage('pause')} style={{ fontSize: '12px' }}>
          pause
        </DropdownItem>
        <DropdownItem onClick={selectPackage('cancel')} style={{ fontSize: '12px' }}>
          cancel
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default class PackageAdjustmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupData: [],
      group: '0',
      student: '0',
      lastOrGeneral: 'last',
      tempDate: null,
      showSave: false,
      savedAlertOpen: false,
      warnAlertOpen: false,
      warningLabel: '',
      packageMonth: moment().month() + 1,
      packageYear: moment().year(),
      packageAdditional: 0,
      rateData: { monthly_package: '' }
    };
  }

  handleChange = (e) => {
    var key = e.target.id;
    var obj = { showSave: true };
    obj[key] = e.target.value;
    this.setState(obj);
  };

  handleRadioChange = (e) => {
    var key = e.target.name;
    var obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  };

  componentDidMount = () => {
    this.props.apiaccess.getStudentRateData().then((data) => {
      //get the student data
      this.setState({
        groupData: data.data
      });
    });
  };

  datePickerChanged = (date) => {
    this.setState({ tempDate: date });
  };

  makeChange = () => {
    this.setState({ showSave: false });

    this.props.apiaccess
      .sessionPackageAdjustment(
        this.state.group,
        this.state.lastOrGeneral,
        moment(this.state.tempDate, 'MM-DD-YYYY HH:mm').format('MM-DD-YYYY')
      )
      .then((data) => {
        if (data.message == 'saved') {
          this.setState({ savedAlertOpen: true });
        } else {
          this.setState({ warnAlertOpen: true, warningLabel: data.message });
        }
      });
  };

  makePackageChange = () => {
    this.setState({ showSaveButton: false });

    this.props.apiaccess
      .setMonthlyPackage(
        this.state.student,
        this.state.packageMonth,
        this.state.packageYear,
        this.state.rateData.monthly_package,
        this.state.packageAdditional
      )
      .then((data) => {
        if (data.message == 'saved') {
          this.setState({ savedAlertOpen: true });
        } else {
          this.setState({ warnAlertOpen: true, warningLabel: data.message });
        }
      });
  };

  handlePackageDropDownChange = (newPackage) => {
    var newRateData = this.state.rateData;
    newRateData.monthly_package = newPackage;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  render() {
    var styleMR20 = { marginRight: 20 };
    var styleMR5 = { marginRight: 5 };

    return (
      <div>
        <h1>Package Adjustment</h1>
        <h3>Mark Session with Different Package</h3>
        <p>
          Set a specific session for a group to not be included on this month's package. Either set
          to the student's package from last month or a generic catch-all package.
        </p>

        <div className="form-group">
          <label>
            Group:
            <select
              className="form-control"
              id="group"
              value={this.state.group}
              onChange={this.handleChange}
            >
              <option value="0" disabled>
                Pick a group
              </option>
              {this.state.groupData.map((group) => (
                <option
                  value={group.group_id}
                  key={group.group_id}
                  id={group.group_id}
                  style={{ fontSize: '12px' }}
                >
                  {group.group_name}
                </option>
              ))}
            </select>
          </label>
        </div>

        <label style={styleMR20}>Session date:</label>
        <DatePicker
          popperPlacement="bottom-start"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: window.innerWidth > 400 ? {} : { enabled: true, offset: [0, 12] }
              }
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true
              }
            },
            {
              name: 'arrow',
              options: {
                padding: 10
              }
            }
          ]}
          maxDate={moment().endOf('month').toDate()}
          selected={this.state.tempDate}
          onChange={this.datePickerChanged}
          dateFormat="M/d/yy"
        />
        <p></p>

        <div className="form-group">
          <label style={styleMR20}>Set to:</label>
          <input
            type="radio"
            id="last"
            value="last"
            name="lastOrGeneral"
            style={styleMR5}
            checked={this.state.lastOrGeneral === 'last' ? true : false}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="last" style={styleMR20}>
            Last month's package
          </label>
          <input
            type="radio"
            style={styleMR5}
            id="general"
            value="general"
            name="lastOrGeneral"
            checked={this.state.lastOrGeneral === 'last' ? false : true}
            onClick={this.handleRadioChange}
          />
          <label htmlFor="general">Generic</label>
        </div>

        {this.state.showSave && (
          <Button color="success" onClick={this.makeChange}>
            Make Session Change
          </Button>
        )}

        <h3>Set or Update a Monthly Package</h3>
        <p>
          If there isn't a package, it will create one, otherwise will overwrite. Don't use when a
          student in a multi-student group.
        </p>
        <div>
          <label>
            Student:
            <select
              className="form-control"
              id="student"
              value={this.state.student}
              onChange={this.handleChange}
            >
              <option value="0" disabled>
                Pick a student
              </option>
              {this.state.groupData.map((student) => (
                <option
                  value={student.student_id}
                  key={student.student_id}
                  id={student.student_id}
                  style={{ fontSize: '12px' }}
                >
                  {student.group_name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label style={styleMR20}>Month:</label>
          <input
            type="number"
            id="packageMonth"
            step="1"
            min="1"
            value={this.state.packageMonth}
            onChange={this.handleChange}
          />
        </div>
        <div>
          <label style={styleMR20}>Year:</label>
          <input
            type="number"
            id="packageYear"
            step="1"
            min="1"
            value={this.state.packageYear}
            onChange={this.handleChange}
          />
        </div>
        <div>
          <label style={styleMR20}>Extra / "Starting" Individual Session:</label>
          <input
            type="number"
            id="packageAdditional"
            step="5"
            value={this.state.packageAdditional}
            onChange={this.handleChange}
          />
        </div>
        <div>
          Package:{' '}
          <PackageDropdown
            rateData={this.state.rateData}
            selectPackage={this.handlePackageDropDownChange}
          />
        </div>
        {this.state.showSaveButton && (
          <Button color="success" onClick={this.makePackageChange}>
            Make Package Change
          </Button>
        )}

        <Alert
          color="success"
          isOpen={this.state.savedAlertOpen}
          toggle={() => this.setState({ savedAlertOpen: false })}
        >
          Successfully saved!
        </Alert>
        <Alert
          color="warning"
          isOpen={this.state.warnAlertOpen}
          toggle={() => this.setState({ warnAlertOpen: false })}
        >
          Didn't work: {this.state.warningLabel}
        </Alert>
      </div>
    );
  }
}
