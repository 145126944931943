import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';

function MentorDropdown(props) {
  const selectNewPairing = (x) => () => props.selectNewPairing(x);
  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle caret>{props.pairingData.mentor_name}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectNewPairing(0)} style={{ fontSize: '12px' }}>
          Not set
        </DropdownItem>
        {props.mentorList.map((mentor, index) => (
          <DropdownItem
            key={mentor.id}
            onClick={selectNewPairing(mentor.id)}
            style={{ fontSize: '12px' }}
          >
            {mentor.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

MentorDropdown.propTypes = {
  selectNewPairing: PropTypes.func.isRequired,
  mentorList: PropTypes.array.isRequired,
  pairingData: PropTypes.shape({
    mentor_rate: PropTypes.number,
    group_id: PropTypes.number
  }).isRequired
};

function PayRateDropdown(props) {
  const changePayRate = (x) => () => props.selectNewPayRate(x);
  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle caret>{props.pairingData.mentor_rate}</DropdownToggle>
      <DropdownMenu id={props.pairingData.group_id}>
        <DropdownItem onClick={changePayRate(0)} style={{ fontSize: '12px' }}>
          0
        </DropdownItem>
        <DropdownItem onClick={changePayRate(45)} style={{ fontSize: '12px' }}>
          45
        </DropdownItem>
        <DropdownItem onClick={changePayRate(50)} style={{ fontSize: '12px' }}>
          50
        </DropdownItem>
        <DropdownItem onClick={changePayRate(55)} style={{ fontSize: '12px' }}>
          55
        </DropdownItem>
        <DropdownItem onClick={changePayRate(60)} style={{ fontSize: '12px' }}>
          60
        </DropdownItem>
        <DropdownItem onClick={changePayRate(65)} style={{ fontSize: '12px' }}>
          65
        </DropdownItem>
        <DropdownItem onClick={changePayRate(70)} style={{ fontSize: '12px' }}>
          70
        </DropdownItem>
        <DropdownItem onClick={changePayRate(80)} style={{ fontSize: '12px' }}>
          80
        </DropdownItem>
        <DropdownItem onClick={changePayRate(90)} style={{ fontSize: '12px' }}>
          90
        </DropdownItem>
        <DropdownItem onClick={changePayRate(100)} style={{ fontSize: '12px' }}>
          100
        </DropdownItem>
        <DropdownItem onClick={changePayRate(110)} style={{ fontSize: '12px' }}>
          110
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

PayRateDropdown.propTypes = {
  selectNewPayRate: PropTypes.func.isRequired,
  pairingData: PropTypes.shape({
    mentor_rate: PropTypes.number,
    group_id: PropTypes.number
  }).isRequired
};

class PairingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pairingData: this.props.pairingData,
      showSaveButton: false
    };
  }

  selectNewPairing = (newMentorId) => {
    let newMentorName = 'Not set';
    //get the new mentor name to show on the dropdown ...
    for (var i = 0; i < this.props.mentorList.length; i++) {
      var mentor = this.props.mentorList[i];

      if (mentor.id === newMentorId) {
        newMentorName = mentor.name;
        break;
      }
    }
    let newPairingData = this.state.pairingData;
    newPairingData.mentor_name = newMentorName;
    newPairingData.mentor_id = newMentorId;

    this.setState({
      pairingData: newPairingData,
      showSaveButton: true
    });
  };

  selectNewPayRate = (newPayRate) => {
    let newPairingData = this.state.pairingData;
    newPairingData.mentor_rate = newPayRate;

    this.setState({
      pairingData: newPairingData,
      showSaveButton: true
    });
  };

  saveNewMentorAssignment = () => {
    //save to the database
    console.log(
      'Mentor id: ' +
        this.state.pairingData.mentor_id +
        ' Group id: ' +
        this.state.pairingData.group_id
    );
    let mentorID = this.state.pairingData.mentor_id;
    if (mentorID === 'Not set') {
      mentorID = 0;
    }
    this.props.apiaccess
      .setGroupPairing(
        this.state.pairingData.group_id,
        mentorID,
        this.state.pairingData.mentor_rate
      )
      .then((data) => {
        //do nothing? reload the page?
      });

    this.setState({
      showSaveButton: false
    });
  };

  render() {
    const saveButton = (
      <Button color="success" onClick={this.saveNewMentorAssignment}>
        Save
      </Button>
    );

    return (
      <tr>
        <td>{this.props.pairingData.group_name}</td>
        <td>
          <MentorDropdown
            mentorList={this.props.mentorList}
            pairingData={this.props.pairingData}
            selectNewPairing={this.selectNewPairing}
          />
        </td>
        <td>
          <PayRateDropdown
            pairingData={this.props.pairingData}
            selectNewPayRate={this.selectNewPayRate}
          />
        </td>
        <td> {this.state.showSaveButton && saveButton} </td>
      </tr>
    );
  }
}

PairingRow.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};

function MentorAssignmentsTable(props) {
  return props.groupPairingList === [] ? (
    <div>Loading data...</div>
  ) : (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Group</th>
          <th>Mentor</th>
          <th>Mentor Pay Rate</th>
          <th className="skinny"></th>
        </tr>
      </thead>
      <tbody>
        {props.groupPairingList.map((pairing, index) => (
          <PairingRow
            pairingData={pairing}
            mentorList={props.mentorList}
            id={pairing.group_id}
            key={pairing.group_id}
            apiaccess={props.apiaccess}
          />
        ))}
      </tbody>
    </table>
  );
}

export default class MentorAssignmentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupPairingList: this.props.groupList,
      mentorList: this.props.mentorList
    };
  }

  render() {
    return (
      <div>
        <h1>Mentor Assignments</h1>
        <MentorAssignmentsTable
          groupPairingList={this.state.groupPairingList}
          mentorList={this.state.mentorList}
          apiaccess={this.props.apiaccess}
        />
      </div>
    );
  }
}
