import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import {
  Button,
  Alert,
  UncontrolledDropdown,
  Label,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from 'reactstrap';
import moment from 'moment';

function MentorDropdown(props) {
  const selectNewMentor = (x, y) => () => props.selectNewMentor(x, y);
  return (
    <UncontrolledDropdown size="md">
      <DropdownToggle caret>{props.mentorName}</DropdownToggle>
      <DropdownMenu>
        {props.mentorList.map((mentor, index) => (
          <DropdownItem
            key={mentor.id}
            onClick={selectNewMentor(mentor.id, mentor.name)}
            style={{ fontSize: '12px' }}
          >
            {mentor.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

MentorDropdown.propTypes = {
  selectNewMentor: PropTypes.func.isRequired,
  mentorList: PropTypes.array.isRequired
};

export default class AdjustPaycheckPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mentorList: this.props.mentorList,
      mentorID: 0,
      mentorName: 'Select Mentor',
      amount: 0,
      total: 0,
      description: '',
      alertOpen: false,
      paycheckData: [],
      mostRecentPaycheck: {},
      details: ''
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.getPastPaychecks().then((data) => {
      this.setState({
        paycheckData: data.paychecks
      });
    });
  };

  selectNewMentor = (newMentorId, newMentorName) => {
    //filter the paycheck data to show the most recent for that mentor

    var mostRecent = this.state.paycheckData.reduce(
      (previous, current) => {
        if (
          current.mentor_id === newMentorId &&
          moment([previous.year, previous.month]).isBefore(moment([current.year, current.month]))
        ) {
          return current;
        }
        return previous;
      },
      { mentor_id: newMentorId, year: 2000, month: 1, amount: 0, details: '' }
    );
    //console.log(previous)

    this.setState({
      mentorID: newMentorId,
      mentorName: newMentorName,
      mostRecentPaycheck: mostRecent,
      total: mostRecent.amount,
      details: mostRecent.details
    });
  };

  handleChange = (e) => {
    var key = e.target.id;
    var obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  };

  submitFutureAdjustment = () => {
    //do the API call

    this.props.apiaccess
      .submitPaycheckAdjustment(
        this.state.mentorID,
        this.state.amount,
        this.state.description.replace(/\//g, '~~!!~~')
      )
      .then((data) => {
        //do anything?
      });

    this.setState({
      mentorID: 0,
      mentorName: 'Select Mentor',
      amount: 0,
      total: 0,
      description: '',
      alertOpen: false,
      mostRecentPaycheck: {},
      details: ''
    });
  };

  submitPastAdjustment = () => {
    console.log(this.state.details);
    console.log(this.state.mostRecentPaycheck.id);

    this.props.apiaccess
      .submitPastPaycheckAdjustment(
        this.state.mostRecentPaycheck.id,
        this.state.total,
        this.state.details.replace(/\//g, '~~!!~~').replace(/\n/g, '%0A')
      )
      .then((data) => {
        //do anything?
      });

    this.setState({
      mentorID: 0,
      mentorName: 'Select Mentor',
      amount: 0,
      total: 0,
      description: '',
      alertOpen: false,
      mostRecentPaycheck: {},
      details: ''
    });
  };

  render() {
    var styleMR20 = { marginRight: 20 };

    return (
      <div>
        <h1>Paycheck Adjustment</h1>

        {this.state.paycheckData.length === 0 ? (
          <p>Loading...</p>
        ) : (
          <MentorDropdown
            mentorList={this.state.mentorList}
            mentorName={this.state.mentorName}
            selectNewMentor={this.selectNewMentor}
          />
        )}

        <h2>Future Paycheck</h2>
        <div className="form-group">
          <label htmlFor="amount" style={styleMR20}>
            Amount:
          </label>
          <input
            type="number"
            id="amount"
            step="1"
            min="0"
            value={this.state.amount}
            onChange={this.handleChange}
            placeholder="0"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description" style={styleMR20}>
            Description:
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            value={this.state.description}
            onChange={this.handleChange}
            required
          />
        </div>

        <Button color="success" onClick={this.submitFutureAdjustment}>
          Save
        </Button>
        <Alert
          color="success"
          isOpen={this.state.alertOpen}
          toggle={() => this.setState({ alertOpen: false })}
        >
          Successfully adjusted paycheck!
        </Alert>

        <h2>Submitted Paycheck</h2>
        {this.state.mostRecentPaycheck != {} && (
          <div>
            <p>Month: {this.state.mostRecentPaycheck.month}</p>
            <p>Year: {this.state.mostRecentPaycheck.year}</p>
            <div className="form-group">
              <label htmlFor="amount" style={styleMR20}>
                Amount:
              </label>
              <input
                type="number"
                id="total"
                step="5"
                min="0"
                value={this.state.total}
                onChange={this.handleChange}
                placeholder="0"
                required
              />
            </div>

            <Label for="details">Details:</Label>
            <Input
              type="textarea"
              name="text"
              id="details"
              value={this.state.details}
              onChange={this.handleChange}
              style={{ height: 150, marginBottom: 20 }}
            />

            <Button color="success" onClick={this.submitPastAdjustment}>
              Save
            </Button>
          </div>
        )}
      </div>
    );
  }
}

AdjustPaycheckPage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
