import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';


export default function MentorInstructions(props) {
	return (
		<div>
			<h1>Instructions</h1>

			<Jumbotron>
				<p style={{ marginBottom: 0 }}>
					The Portal makes everything easier on the mentors so you spend most your time with
					students, not paperwork and meetings. Most of it is automated with emails, but here are
					the finer points of how it works.
				</p>
			</Jumbotron>

			<h3 className="light-bottom-border">Google Calendar</h3>
			<p>
				The calendar should always reflect the mentor's understanding of past and future sessions.
				It is possible to directly update the Google Calendar (particularly when changing a
				recurring appointment). Changes to the Google Calendar take up to 10 minutes to sync to the
				Portal. Changes on the Portal will be synced immediately to the Google Calendar. The time of
				the session is not important for billing purposes, but will show up on your calendar and the
				customer’s Portal.
			</p>
			<p>
				Usually the Google Calendar is set up with a recurring weekly appointment. If that is the
				case, you don’t want to ‘Move’ the session to the following week, you would ‘Delete’ it.{' '}
			</p>

			<h3 className="light-bottom-border">Confirming</h3>
			<p>
				We need to know if the calendar is correct so mentors are asked to ‘Confirm’ sessions (the
				customer has the ability too on their Portal).
			</p>
			<p>
				3 days before the session you will receive an email. This is your reminder to update the
				schedule or contact the family if necessary. If you are reasonably certain the session will
				be held, you can confirm at that time. The day of the session, if you haven’t already
				confirmed, you will receive an email asking you to confirm. These reminders can be text
				messages if preferred (on the Settings page).
			</p>

			<h3 className="light-bottom-border">Requesting Paychecks</h3>
			<p>
				Paychecks must be requested on the 1st or 2nd of the month. You will receive an email to log
				into the Portal, confirm the sessions, and hit the request paycheck button. Payroll is
				entered into Gusto on the 3rd and direct deposited 2 business days later.
			</p>
			<p>
				If you forget to request a paycheck one month, it will be held until the following month. We
				can also run an off-cycle payroll if needed.
			</p>

			<h3 className="light-bottom-border">Session Recaps</h3>
			<p>
				Recaps are done monthly for any student who held at least two sessions that month. They are
				sent directly to the parents and the Directors read them as well.
			</p>
			<p>
				You will receive an email 3 days before the end of the month with the 1st of the month the
				due date. It is ok if you need more time, the latest you can submit is the 4th of the month.
				If you do not receive an email with the PDF attachment, please let Brian know as something
				might have gone wrong.
			</p>

			<h3 className="light-bottom-border">Optional Customer Tools</h3>
			<p>
				<strong>Calendar Invites</strong> - Customers can sign up to receive calendar invites to the
				appointments. If they enable the feature you will be alerted and any sessions on the
				calendar within 90 days will show on their own Google Calendar.
			</p>
			<p>
				If they remove it from their calendar or RSVP no, you will receive an email with 'Declined'
				in the subject line. If this happens please be aware they might not be expecting a session
				then (for example a holiday way in the future), in which case you should ask directly and
				update your calendar accordingly. If unsure, please forward the email to Brian and ask what
				to do.
			</p>
			<p>
				<strong>Automated communication</strong> - Customers can enable automated texts or emails to
				help with scheduling. If they enable the feature you will be alerted and receive a text on
				the first of the month for long-term planning, as well as 2 days before and the morning of
				the session (the customer can enable/disable each one).
			</p>
			<p>
				The system will set up a specific email address or phone number for you. When you or the
				customer replies, the system will immediately route it to everyone else with their preferred
				communication method (text or email). Breakout Mentors operations will see the messages and
				more people can be added as well.
			</p>

			<h3 className="light-bottom-border">USACO Mentors</h3>
			<p>USACO mentors can <Link to={{ pathname: '/usacosession' }}>access the questions and teaching insights here.</Link></p>
			<p>Please encourage your students to create a Portal account to track their progress. Ask Brian for their unique account creation link.</p>
		</div>
	);
}
