import React from 'react';
import PropTypes from 'prop-types';

import actions from '../util/actions';
import moment from 'moment';

import { Table } from 'reactstrap';

export default class PastPaychecks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastPaychecks: { paychecks: [] }
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.getPastPaychecks(this.props.userID).then((response) => {
      this.setState({
        pastPaychecks: response
      });
    });
  };

  render() {
    return <Paychecks paychecks={this.state.pastPaychecks.paychecks} showName={false} />;
  }
}

PastPaychecks.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  userID: PropTypes.number.isRequired
};

export function Paychecks(props) {
  return (
    <Table hover>
      <thead>
        <tr>
          <th className="skinny">Date</th>
          {props.showName ? <th className="skinny">Name</th> : null}
          <th className="skinny">Amount</th>
          <th className="skinny">Details</th>
        </tr>
      </thead>
      <tbody>
        {props.showName
          ? props.paychecks &&
            props.paychecks.map((paycheck) => {
              return <TableData key={paycheck.id} paycheck={paycheck} showName={true} />;
            })
          : props.paychecks &&
            props.paychecks.map((paycheck) => {
              return <TableData key={paycheck.id} paycheck={paycheck} showName={false} />;
            })}
      </tbody>
    </Table>
  );
}

export function TableData(props) {
  return (
    <tr>
      <td>
        {moment.months(props.paycheck.month - 1)} {props.paycheck.year}
      </td>
      {props.showName && <td>{props.paycheck.mentor_name}</td>}
      <td>${props.paycheck.amount}</td>
      <td>
        <div className="display-linebreak">{props.paycheck.details}</div>
      </td>
    </tr>
  );
}
