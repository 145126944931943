import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function OtherWaysToHelp(props) {
  return (
    <div>
      <h1>Other Ways To Help</h1>

      {props.userRole === 'mentor' ? (
        <div>
          <h3 className="light-bottom-border">Refer Your Friends to Mentor</h3>
          <p>
            We find the best mentors through our current mentors, which allows us to help more kids
            learn. There is a{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/0B3bh1gz-IGUJU1lVMUc0MmFJUTQ/view?usp=sharing"
            >
              generous referral bonus
            </a>{' '}
            as well.
          </p>
          <p>
            Simply send a short email introduction between your friend and Brian or your Director.
            We will take it from there.
          </p>

          <h3 className="light-bottom-border">Follow and Share on Social Media</h3>
          <p>Social media is an important way for us to reach new families and mentors.</p>
          <p>
            Follow Breakout Mentors on{' '}
            <a target="_blank" href="https://www.facebook.com/BreakoutMentors/">
              Facebook
            </a>
            ,{' '}
            <a target="_blank" href="https://twitter.com/BreakoutMentors">
              Twitter
            </a>
            ,{' '}
            <a target="_blank" href="https://www.instagram.com/BreakoutMentors/">
              Instagram
            </a>
            , and{' '}
            <a target="_blank" href="https://www.pinterest.com/BreakoutMentors/">
              Pinterest
            </a>
            . Then like and share posts when you can!
          </p>

          <h3 className="light-bottom-border">Add Breakout Mentors to Your LinkedIn Profile</h3>
          <p>
            This helps us reach more potential mentors and families who might view your profile, as
            well as showcase your experience. For the position put either "Kids Coding Tutor" or
            "Kids Coding Mentor". Here is a sample description:
          </p>
          <p className="groupSessions">
            Mentor beginner programming students ranging from middle school to high school through
            the creation of interactive projects coded in Python; teach and explain basic
            programming logic and Python language syntax as well as valuable coding practices,
            including debugging techniques and code organization.
          </p>

          <h3 className="light-bottom-border">Complete a Mentor Profile for the Website</h3>
          <p>
            It's helpful for us to put mentor pictures and descriptions on the website to make it
            more personal. I'd love to put you up there! Here are some{' '}
            <a target="_blank" href="https://breakoutmentors.com/our-mentors/">
              examples
            </a>
            .
          </p>
          <p>
            Send an email to Brian with a high-resolution headshot. Briefly answer 3 of the 5
            questions:
          </p>
          <ul>
            <li>When did you start to code?</li>
            <li>Why is it important for kids to start coding when young?</li>
            <li>What do you find rewarding about teaching kids how to code?</li>
            <li>What is your favorite game or project you have created with a student?</li>
            <li>What advice do you have for kids learning how to code?</li>
          </ul>

          <h3 className="light-bottom-border">Join Conversation on Slack</h3>
          <p>
            Have any questions you would like to hear another mentors option on? Or maybe you are
            curious to find out who the other mentors are at your school.{' '}
            <a
              target="_blank"
              href="https://join.slack.com/t/breakoutmentors/shared_invite/enQtMzAxMzUzMjE1Njg3LTE3NDQxNDNiMzIyYWFmZTQ2YmE2ZTlmZmVlZjg3OTZiZmJlM2U4YzA2MmJkYjRmNzE2MWZmN2E4NDRiMmVmOGU"
            >
              Join the Mentors Slack workspace.
            </a>
          </p>
        </div>
      ) : (
        <div>
          <h3 className="light-bottom-border">Follow and Share on Social Media</h3>
          <p>Social media is an important way for us to reach new families and mentors.</p>
          <p>
            Follow Breakout Mentors on{' '}
            <a target="_blank" href="https://www.facebook.com/BreakoutMentors/">
              Facebook
            </a>
            ,{' '}
            <a target="_blank" href="https://twitter.com/BreakoutMentors">
              Twitter
            </a>
            , and{' '}
            <a target="_blank" href="https://www.instagram.com/BreakoutMentors/">
              Instagram
            </a>
            . Then like and share posts when you can!
          </p>

          <h3 className="light-bottom-border">Leave a Yelp Review</h3>
          <p>Public recommendations from customers are very influential. And appreciated!</p>
          <p>
            We have separate profiles for{' '}
            <a target="_blank" href="https://www.yelp.com/biz/breakout-mentors-palo-alto">
              Palo Alto
            </a>
            ,{' '}
            <a target="_blank" href="https://www.yelp.com/biz/breakout-mentors-berkeley">
              Berkeley
            </a>
            , and{' '}
            <a target="_blank" href="https://www.yelp.com/biz/breakout-mentors-santa-clara-2">
              Santa Clara
            </a>
            .
          </p>
        </div>
      )}
    </div>
  );
}
