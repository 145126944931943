import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';

//Should be an administrator!

function DescriptionDropdown(props) {
  const selectDescChange = (x) => () => props.selectDescChange(x);
  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle caret>{props.rateData.description}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectDescChange('Coding')} style={{ fontSize: '12px' }}>
          Coding
        </DropdownItem>
        <DropdownItem onClick={selectDescChange('Scratch Coding')} style={{ fontSize: '12px' }}>
          Scratch Coding
        </DropdownItem>
        <DropdownItem onClick={selectDescChange('Java Coding')} style={{ fontSize: '12px' }}>
          Java Coding
        </DropdownItem>
        <DropdownItem onClick={selectDescChange('Python Coding')} style={{ fontSize: '12px' }}>
          Python Coding
        </DropdownItem>
        <DropdownItem onClick={selectDescChange('Advanced Coding')} style={{ fontSize: '12px' }}>
          Advanced Coding
        </DropdownItem>
        <DropdownItem onClick={selectDescChange('USACO Coding')} style={{ fontSize: '12px' }}>
          USACO Coding
        </DropdownItem>
        <DropdownItem
          onClick={selectDescChange('Machine Learning Coding')}
          style={{ fontSize: '12px' }}
        >
          Machine Learning Coding
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

DescriptionDropdown.propTypes = {
  selectDescChange: PropTypes.func.isRequired,
  rateData: PropTypes.shape({
    description: PropTypes.string
  })
};

function RateDropdown(props) {
  const selectNewRate = (x) => () => props.selectNewRate(x);
  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle caret>${props.rateData.rate}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectNewRate(75)} style={{ fontSize: '12px' }}>
          $75
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(110)} style={{ fontSize: '12px' }}>
          $110
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(115)} style={{ fontSize: '12px' }}>
          $115
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(120)} style={{ fontSize: '12px' }}>
          $120
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(130)} style={{ fontSize: '12px' }}>
          $130
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(140)} style={{ fontSize: '12px' }}>
          $140
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(150)} style={{ fontSize: '12px' }}>
          $150
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(155)} style={{ fontSize: '12px' }}>
          $155
        </DropdownItem>
        <DropdownItem onClick={selectNewRate(170)} style={{ fontSize: '12px' }}>
          $170
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function BillingTypeDropdown(props) {
  const selectNewBillingType = (x) => () => props.selectNewBillingType(x);
  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle caret>
        {props.rateData.monthly_billing_enabled
          ? props.rateData.monthly_package === 'deepdive'
            ? 'Deep Dive'
            : 'Monthly'
          : 'Individual'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectNewBillingType('Individual')} style={{ fontSize: '12px' }}>
          Individual
        </DropdownItem>
        <DropdownItem onClick={selectNewBillingType('Monthly')} style={{ fontSize: '12px' }}>
          Monthly
        </DropdownItem>
        <DropdownItem onClick={selectNewBillingType('Deep Dive')} style={{ fontSize: '12px' }}>
          Deep Dive
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function PackageDropdown(props) {
  const selectPackage = (x) => () => props.selectPackage(x);
  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{props.rateData.monthly_package}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectPackage('2sessions')} style={{ fontSize: '12px' }}>
          2sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions')} style={{ fontSize: '12px' }}>
          3sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions')} style={{ fontSize: '12px' }}>
          6sessions
        </DropdownItem>
        <DropdownItem onClick={selectPackage('2sessions2023')} style={{ fontSize: '12px' }}>
          2sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions2023')} style={{ fontSize: '12px' }}>
          3sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions2023')} style={{ fontSize: '12px' }}>
          6sessions2023
        </DropdownItem>
        <DropdownItem onClick={selectPackage('2sessions2022')} style={{ fontSize: '12px' }}>
          2sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('3sessions2022')} style={{ fontSize: '12px' }}>
          3sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('6sessions2022')} style={{ fontSize: '12px' }}>
          6sessions2022
        </DropdownItem>
        <DropdownItem onClick={selectPackage('pause')} style={{ fontSize: '12px' }}>
          pause
        </DropdownItem>
        <DropdownItem onClick={selectPackage('cancel')} style={{ fontSize: '12px' }}>
          cancel
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function ExtraDropdown(props) {
  const selectExtra = (x) => () => props.selectExtra(x);
  let displayValue = '$0';
  if (props.rateData.extra_charge_per_session > 0)
    displayValue = '$' + props.rateData.extra_charge_per_session;
  if (props.rateData.extra_charge_per_session < 0)
    displayValue = '-$' + Math.abs(props.rateData.extra_charge_per_session);

  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={selectExtra(-20)} style={{ fontSize: '12px' }}>
          -$20
        </DropdownItem>
        <DropdownItem onClick={selectExtra(-15)} style={{ fontSize: '12px' }}>
          -$15
        </DropdownItem>
        <DropdownItem onClick={selectExtra(-10)} style={{ fontSize: '12px' }}>
          -$10
        </DropdownItem>
        <DropdownItem onClick={selectExtra(-5)} style={{ fontSize: '12px' }}>
          -$5
        </DropdownItem>
        <DropdownItem onClick={selectExtra(0)} style={{ fontSize: '12px' }}>
          0
        </DropdownItem>
        <DropdownItem onClick={selectExtra(5)} style={{ fontSize: '12px' }}>
          $5
        </DropdownItem>
        <DropdownItem onClick={selectExtra(10)} style={{ fontSize: '12px' }}>
          $10
        </DropdownItem>
        <DropdownItem onClick={selectExtra(15)} style={{ fontSize: '12px' }}>
          $15
        </DropdownItem>
        <DropdownItem onClick={selectExtra(20)} style={{ fontSize: '12px' }}>
          $20
        </DropdownItem>
        <DropdownItem onClick={selectExtra(25)} style={{ fontSize: '12px' }}>
          $25
        </DropdownItem>
        <DropdownItem onClick={selectExtra(30)} style={{ fontSize: '12px' }}>
          $30
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function DepositDropdown(props) {
  const selectDeposit = (x) => () => props.selectDeposit(x);
  let displayValue = '$' + props.rateData.deep_dive_deposit;

  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        {[0, 100, 250, 500, 750, 1000].map((num) => {
          return (
            <DropdownItem onClick={selectDeposit(num)} style={{ fontSize: '12px' }}>
              ${num}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function TotalDropdown(props) {
  const selectTotal = (x) => () => props.selectTotal(x);
  let displayValue = '$' + props.rateData.deep_dive_total;

  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        {[750, 800, 1000, 1500, 1750, 2000, 2500, 3000].map((num) => {
          return (
            <DropdownItem onClick={selectTotal(num)} style={{ fontSize: '12px' }}>
              ${num}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function BalanceChargeDatePicker(props) {
  const [tempDate, setTempDate] = useState(props.rateData.deep_dive_charge_date);
  return (
    <Fragment>
      <DatePicker
        popperPlacement="bottom-start"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: window.innerWidth > 400 ? {} : { enabled: true, offset: [0, 12] }
            }
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true
            }
          },
          {
            name: 'arrow',
            options: {
              padding: 10
            }
          }
        ]}
        selected={tempDate}
        onChange={(date) => {
          props.updateBalanceChargeDate(date);
          setTempDate(date);
        }}
        dateFormat="M/d/yy"
      />
    </Fragment>
  );
}

function NumSessionsDropdown(props) {
  const selectNumSessions = (x) => () => props.selectNumSessions(x);
  let displayValue = 10;
  if (props.rateData.deep_dive_num_sessions != null) {
    displayValue = props.rateData.deep_dive_num_sessions;
  }
  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        {[0, 5, 10, 12, 15, 20].map((num) => {
          return (
            <DropdownItem onClick={selectNumSessions(num)} style={{ fontSize: '12px' }}>
              {num}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function DeepDiveAdditionalDropdown(props) {
  const selectAdditional = (x) => () => props.selectAdditional(x);
  let displayValue = '$' + props.rateData.deep_dive_additional;

  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        {[75, 100, 125, 140, 150, 160, 170, 180, 190, 200].map((num) => {
          return (
            <DropdownItem onClick={selectAdditional(num)} style={{ fontSize: '12px' }}>
              ${num}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function DeepDiveDepositPaidDropdown(props) {
  const selectAdditional = (x) => () => props.selectAdditional(x);
  let displayValue = 'No';
  if (props.rateData.deep_dive_deposit_paid) displayValue = 'Yes';

  return (
    <UncontrolledDropdown size="sm" style={{ display: 'inline' }}>
      <DropdownToggle caret>{displayValue}</DropdownToggle>
      <DropdownMenu>
        {['No', 'Yes'].map((yn) => {
          return (
            <DropdownItem onClick={selectAdditional(yn)} style={{ fontSize: '12px' }}>
              {yn}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

RateDropdown.propTypes = {
  selectNewRate: PropTypes.func.isRequired,
  rateData: PropTypes.shape({
    rate: PropTypes.number,
    deep_dive_deposit: PropTypes.number
  })
};

class StudentRateRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rateData: this.props.rateData,
      showSaveButton: false
    };
  }

  handleDescriptionDropDownChange = (newDescription) => {
    var newRateData = this.state.rateData;
    newRateData.description = newDescription;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleRateDropDownChange = (newRate) => {
    var newRateData = this.state.rateData;
    newRateData.rate = newRate;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleBillingTypeDropDownChange = (newBillingType) => {
    var newRateData = this.state.rateData;
    newRateData.monthly_billing_enabled =
      newBillingType === 'Monthly' || newBillingType === 'Deep Dive';

    if (newBillingType === 'Deep Dive') {
      newRateData.monthly_package = 'deepdive';
      newRateData.deep_dive_deposit = 500;
      newRateData.deep_dive_total = 2000;
      newRateData.deep_dive_num_sessions = 10;
      newRateData.deep_dive_additional = 150;
      newRateData.deep_dive_deposit_paid = false;
    }
    if (newBillingType === 'Monthly') {
      newRateData.monthly_package = '3sessions';
    }

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handlePackageDropDownChange = (newPackage) => {
    var newRateData = this.state.rateData;
    newRateData.monthly_package = newPackage;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleExtraDropDownChange = (newExtra) => {
    var newRateData = this.state.rateData;
    newRateData.extra_charge_per_session = newExtra;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleDepositDropdownChange = (newDeposit) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_deposit = newDeposit;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleTotalDropdownChange = (newTotal) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_total = newTotal;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleBalanceChargeDateChange = (newDate) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_charge_date = newDate;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleNumSessionsDropdownChange = (newNumSessions) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_num_sessions = newNumSessions;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleAdditionalDropdownChange = (newAdditional) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_additional = newAdditional;

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  handleDepositPaidDropdownChange = (newPaid) => {
    var newRateData = this.state.rateData;
    newRateData.deep_dive_deposit_paid = newPaid === 'Yes';

    this.setState({
      rateData: newRateData,
      showSaveButton: true
    });
  };

  saveNewRateAssignment = () => {
    if (this.props.rateData.monthly_billing_enabled) {
      //save to the database
      this.props.apiaccess
        .setMonthlyStudentRate(
          this.state.rateData.student_id,
          this.state.rateData.monthly_package,
          this.state.rateData.extra_charge_per_session
            ? this.state.rateData.extra_charge_per_session
            : 0,
          this.state.rateData.description
        )
        .then((data) => {
          //do nothing? reload the page?
          if (this.state.rateData.monthly_package === 'deepdive') {
            this.props.apiaccess
              .updateDeepDivePackage(
                this.state.rateData.student_id,
                this.state.rateData.deep_dive_deposit,
                this.state.rateData.deep_dive_total,
                moment(this.state.rateData.deep_dive_charge_date).format('MM/DD/YYYY'),
                this.state.rateData.deep_dive_num_sessions,
                this.state.rateData.deep_dive_additional,
                this.state.rateData.deep_dive_deposit_paid
              )
              .then(() => { })
              .catch((err) => console.error(err));
          }
        });
    } else {
      //save to the database
      this.props.apiaccess
        .setStudentRate(
          this.state.rateData.student_id,
          this.state.rateData.rate,
          this.state.rateData.description
        )
        .then((data) => {
          //do nothing? reload the page?
        });
    }

    this.setState({
      showSaveButton: false
    });
  };

  render() {
    var saveButton = (
      <Button color="success" onClick={this.saveNewRateAssignment}>
        Save
      </Button>
    );

    return (
      <tr>
        <td>
          {this.props.rateData.student_family_name}
          {this.props.rateData.student_family_name !== this.props.rateData.group_name && (
            <span>
              <br />({this.props.rateData.group_name})
            </span>
          )}
        </td>
        <td>
          <DescriptionDropdown
            rateData={this.props.rateData}
            selectDescChange={this.handleDescriptionDropDownChange}
          />
        </td>
        <td>
          <BillingTypeDropdown
            rateData={this.props.rateData}
            selectNewBillingType={this.handleBillingTypeDropDownChange}
          />
        </td>
        <td>
          {this.props.rateData.monthly_billing_enabled ? (
            <div style={{ width: 180 }}>
              {this.props.rateData.monthly_package === 'deepdive' ? (
                <React.Fragment>
                  Deposit:{' '}
                  <DepositDropdown
                    rateData={this.props.rateData}
                    selectDeposit={this.handleDepositDropdownChange}
                  />
                  <br />
                  Total:{' '}
                  <TotalDropdown
                    rateData={this.props.rateData}
                    selectTotal={this.handleTotalDropdownChange}
                  />
                  <br />
                  Balance Charge Date:{' '}
                  <BalanceChargeDatePicker
                    rateData={this.props.rateData}
                    updateBalanceChargeDate={this.handleBalanceChargeDateChange}
                  />
                  <br />
                  Num Sessions:{' '}
                  <NumSessionsDropdown
                    rateData={this.props.rateData}
                    selectNumSessions={this.handleNumSessionsDropdownChange}
                  />
                  <br />
                  Additional Rate:{' '}
                  <DeepDiveAdditionalDropdown
                    rateData={this.props.rateData}
                    selectAdditional={this.handleAdditionalDropdownChange}
                  />
                  <br />
                  Deposit Paid:{' '}
                  <DeepDiveDepositPaidDropdown
                    rateData={this.props.rateData}
                    selectAdditional={this.handleDepositPaidDropdownChange}
                  />
                  <br />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Package:{' '}
                  <PackageDropdown
                    rateData={this.props.rateData}
                    selectPackage={this.handlePackageDropDownChange}
                  />
                  <br />
                  Extra:{' '}
                  <ExtraDropdown
                    rateData={this.props.rateData}
                    selectExtra={this.handleExtraDropDownChange}
                  />
                </React.Fragment>
              )}
            </div>
          ) : (
            <RateDropdown
              rateData={this.props.rateData}
              selectNewRate={this.handleRateDropDownChange}
            />
          )}
        </td>
        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
          {' '}
          {this.state.showSaveButton && saveButton}{' '}
        </td>
      </tr>
    );
  }
}

function StudentRateTable(props) {
  return props.studentData === [] ? (
    <div>Loading data...</div>
  ) : (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Student (Group)</th>
          <th>Description</th>
          <th>Billing</th>
          <th>Rate</th>
          <th className="skinny"></th>
        </tr>
      </thead>
      <tbody>
        {props.studentData.map((data) => (
          <StudentRateRow rateData={data} key={data.student_id} apiaccess={props.apiaccess} />
        ))}
      </tbody>
    </table>
  );
}

export default class StudentRatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: []
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.getStudentRateData().then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].deep_dive_charge_date !== '') {
          data.data[i].deep_dive_charge_date = moment(
            data.data[i].deep_dive_charge_date,
            'MM/DD/YYYY'
          ).toDate();
        }
      }
      //get the student data
      this.setState({
        studentData: data.data
      });
    });
  };

  render() {
    return (
      <div>
        <h1>Student Rates</h1>
        <StudentRateTable studentData={this.state.studentData} apiaccess={this.props.apiaccess} />
      </div>
    );
  }
}
