import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import { Button, Alert } from 'reactstrap';

import findLodash from 'lodash/find';
import { TIMEZONES } from './SettingsPage';

//Should be an administrator!

const LinkWithCopyButton = (props) => {
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

  // https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/
  const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch((err) => console.error(err));
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  return (
    <Fragment>
      <a target="_blank" rel="noopener noreferrer" href={props.link}>
        {props.link}
      </a>
      <Button
        color="primary"
        style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem' }}
        onClick={() => {
          copyTextToClipboard(props.link)
            .then((r) => {})
            .catch((err) => {
              console.error(err);
            });

          setShowCopyConfirmation(true);
          setTimeout(() => {
            setShowCopyConfirmation(false);
          }, 1000);
        }}
      >
        {showCopyConfirmation ? 'Copied!' : 'Copy Link'}
      </Button>
    </Fragment>
  );
};

class InvitesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      familyID: '0',
      familyHash: '',
      emails: '',
      alertOpen: false,
      student: '0',
      studentsByID: {},
      studentIDList: [],
      timezone: 'Pacific'
    };
  }

  componentDidMount() {
    this.props.apiaccess.getStudentList().then((data) => {
      let mapping = {};

      let studentIDList = [];
      for (let i = 0; i < data.students.length; i++) {
        mapping[data.students[i].id] = {
          hash: data.students[i].hash,
          name: data.students[i].name
        };

        studentIDList.push(data.students[i].id);
      }

      this.setState({
        studentsByID: mapping,
        studentIDList: studentIDList
      });
    });
  }

  sendInvites = () => {
    if (this.state.familyID === '' || this.state.familyID === 0 || this.state.familyID === '0') {
      alert('no familyID');
      return;
    }
    if (this.state.emails === '') {
      alert('no amount given');
      return;
    }

    this.props.apiaccess
      .sendPortalInvites(this.state.familyID, this.state.emails, this.state.timezone)
      .then((data) => {
        this.clearForm();
        this.openAlert();
      });
  };

  handleChange = (e) => {
    var key = e.target.id;
    var obj = {};
    obj[key] = e.target.value;
    this.setState(obj);

    if (key === 'familyID') {
      let hashObj = {
        familyHash: findLodash(this.props.familyList, { id: Number.parseInt(e.target.value) })
          .joinID
      };

      this.setState(hashObj);
    }
  };

  clearForm = () => {
    this.setState({
      familyID: '',
      emails: ''
    });
  };

  openAlert = () => {
    this.setState({
      alertOpen: true
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false
    });
  };

  render() {
    return (
      <div>
        <form>
          <div className="form-group" style={{ marginBottom: '0rem' }}>
            <label>
              Family:
              <select
                className="form-control"
                id="familyID"
                value={this.state.familyID}
                onChange={this.handleChange}
                style={{ margin: 5 }}
              >
                <option value="0" disabled selected={true}>
                  Pick a family...
                </option>
                {this.props.familyList.map((family) => {
                  return (
                    <option
                      value={family.id}
                      key={family.id}
                      id={family.id}
                      style={{ fontSize: '12px' }}
                    >
                      {family.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className={'form-group'} style={{ marginBottom: 0 }}>
            <label>
              Timezone:
              <select
                className="form-control"
                id="timezone"
                value={this.state.timezone}
                onChange={this.handleChange}
                style={{ margin: 5 }}
              >
                <option value="0" disabled selected={true}>
                  Pick a family...
                </option>
                {TIMEZONES.map((tz) => tz.replace('US/', '')).map((timezone) => {
                  return (
                    <option
                      value={timezone}
                      key={timezone}
                      id={timezone}
                      style={{ fontSize: '12px' }}
                    >
                      {timezone}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          {this.state.familyHash !== '' && (
            <LinkWithCopyButton
              link={
                'https://portal.breakoutmentors.com/join=' +
                this.state.familyHash +
                '?tz=' +
                this.state.timezone
              }
            />
          )}
          <div className="form-group">
            <label htmlFor="emails" style={{ marginRight: 20 }}>
              Email addresses (comma separated):
            </label>
            <input
              className="form-control"
              type="email"
              id="emails"
              value={this.state.emails}
              onChange={this.handleChange}
              required
            />
          </div>

          <Button color="success" onClick={this.sendInvites}>
            Send Invites
          </Button>
        </form>
        <h1>USACO Invite Link</h1>
        <div>
          <label>
            Student:
            <select
              className="form-control"
              id="student"
              value={this.state.student}
              onChange={this.handleChange}
            >
              <option value="0" disabled>
                Pick a student
              </option>
              {this.state.studentIDList.map((studentID) => (
                <option
                  value={studentID}
                  key={this.state.studentsByID[studentID]}
                  id={studentID}
                  style={{ fontSize: '12px' }}
                >
                  {this.state.studentsByID[studentID].name}
                </option>
              ))}
            </select>
          </label>
        </div>
        {this.state.student !== '0' && (
          <LinkWithCopyButton
            link={
              'https://portal.breakoutmentors.com/competitiveJoin=' +
              this.state.studentsByID[this.state.student].hash
            }
          />
        )}
        <Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>
          Successfully sent invite(s)!
        </Alert>
      </div>
    );
  }
}

InvitesForm.contextTypes = {
  router: PropTypes.object
};

InvitesForm.propTypes = {
  familyList: PropTypes.array.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};

export default class SendInvitesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      familyList: props.familyList
    };
  }

  render() {
    return (
      <div>
        <h1>Send Invitations</h1>
        <InvitesForm familyList={this.state.familyList} apiaccess={this.props.apiaccess} />
      </div>
    );
  }
}

SendInvitesPage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
