import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Jumbotron } from 'reactstrap';

export default class MentorResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goalText: false,
      impactText: false,
      funText: false
    };
  }

  handleTextToggle = (value) => {
    var newValue = {};
    newValue[value] = !this.state[value];
    this.setState(newValue);
  };

  render() {
    return (
      <div>
        <h1>Resources</h1>
        <Jumbotron>
          <p style={{ marginBottom: 0 }}>
            You can find all the Breakout Mentors resources on <a target="_blank" href="https://drive.google.com/drive/folders/0B3bh1gz-IGUJeGxWVV9kZXpGLUE?resourcekey=0-qIJcKDgitD_KQK6qgBlGNg">Google Drive here</a>. The most used are the <a target="_blank" href="https://docs.google.com/document/d/1kdWax5sYf1ND5K8kKM3RhzfB4I8Ngf0OZOnv9HWeKZ0/edit">Python Plan</a> and <a target="_blank" href="https://docs.google.com/document/d/1T-HJ-lb4gG403-fxnE0IRv5gERXkitOrgXCe-e-SBVY/edit">Scratch Project Ideas</a>.
          </p>
        </Jumbotron>

        <h3>The Goal</h3>
        <div>
          <audio controls>
            <source
              src="https://dl.dropboxusercontent.com/s/mzno3yzipe0bpnc/Goal%2016bit.wav?dl=0"
              type="audio/wav"
            />
            Your browser does not support the audio element.
          </audio>
          <Link
            className="viewHideText"
            to="#"
            onClick={this.handleTextToggle.bind(this, 'goalText')}
          >
            {this.state.goalText ? 'Hide' : 'View'} Transcript
          </Link>
          <div className={this.state.goalText ? '' : 'collapse'}>
            <div className="card card-body">
              <p>
                The goal of Breakout Mentors is to provide a longer-term and more personalized
                option for kids who are learning to code. How can we continue to work at that
                perfect difficulty level for each student? How can we allow them to keep going past
                the basics and master some of those fundamental thinking skills involved in
                programming? How can we keep their interest over months or years by making actual
                projects and letting them be creative?
              </p>
              <p>
                There's truly no limit how far students can go, there's always new things to learn
                and more exciting projects to create.
              </p>
              <p>
                Allow me to give a little bit more background on why this is a problem that needs
                solving - after all there's hundreds or even thousands of organizations, who are
                working on kids coding. There's tutorials and summer camps, after school centers
                that are popping up, but most of those are focused on exposure.
              </p>
              <p>
                How do you get coding into the hands of as many kids as possible? And that's
                wonderful that can provide that spark of interest. What we've observed is that
                there's plenty of kids who have that spark of interest, but it dies out over time.
                Let me give a couple examples.
              </p>
              <p>
                Very typical from talking to hundreds of parents is the the kid who's interested and
                they asked to go to the coding summer camp, and they have fun that first year. It's
                kind of plug-and-play fill in the blanks type of coding so they might not learn a
                lot, but it still gives them that initial exposure.
              </p>
              <p>
                They come out of that camp and they're excited about it. They don't know what to do
                next there's not really a logical next step. So they'll wait around for the next
                thing that comes across their plate. Maybe it's online tutorial or after-school
                center or maybe next year there's another summer camp.{' '}
              </p>
              <p>
                They do that and it's more the same. It's very beginner level plug-and-play probably
                on a new language or platform, so every kid starts from the same spot, and it
                doesn't build on what they were doing before.
              </p>
              <p>
                They repeat this cycle a couple times, and they think "OK I know what this is I'm
                not really that interested in doing the same thing again."
              </p>
              <p>
                Another example is a kid who does Scratch in school and they do it once a week, and
                they enjoy it. They have a lot of fun. They create some some neat things, but they
                never really get into the programming logic. They have exposure to loops and
                conditionals and variables, but they don't really know how to control them to get
                the result they want. So they think they've been doing Scratch for a year or two
                they know it.
              </p>
              <p>
                Or a kid who goes to a Java Minecraft Modding summer camp. They touch Java code -
                they change a few parameters around and copy and paste some code into the right
                places and they think they know Java.{' '}
              </p>
              <p>
                Really, these are missed opportunities. We have the opportunity to deepen their
                interest over months and years and build real skills so they'll be successful
                whenever they do end up pursuing coding in the future.
              </p>
              <p>
                It is important to frequently remind yourself of the goal and refocus on how to get
                there. Listen to these audio files from time to time to be the most effective mentor
                you can be!
              </p>
            </div>
          </div>
        </div>

        <h3>The Impact</h3>
        <div>
          <audio controls>
            <source
              src="https://dl.dropboxusercontent.com/s/9l29w10a46jzh7v/Impact%2016bit.wav?dl=0"
              type="audio/wav"
            />
            Your browser does not support the audio element.
          </audio>
          <Link
            className="viewHideText"
            to="#"
            onClick={this.handleTextToggle.bind(this, 'impactText')}
          >
            {this.state.impactText ? 'Hide' : 'View'} Transcript
          </Link>
          <div className={this.state.impactText ? '' : 'collapse'}>
            <div className="card card-body">
              <p>
                We are aiming to have a substantial impact on a small number of students. One person
                can change your life for the better - think back, I bet outside of your parents,
                there's a few top teachers and influencers who got you to where you are today.
              </p>
              <p>
                Part of it is that personal connection with the student. You'll meet the same
                students every week for an hour and a half. And college-age mentors are far more
                relatable than parents or their teachers or other adults in their lives.{' '}
              </p>
              <p>
                We want you to share your excitement for coding. Try to reveal opportunities like
                what's available in high school and college what companies in the area do.
              </p>
              <p>
                Really, try to build that personal connection with the student and with the parents
                as well. There was one mentor who was actually invited to a student's birthday party
                - that just shows the bond they created throughout their mentoring sessions, and is
                a worthy goal.
              </p>
              <p>
                Even though you might only be working with two or three kids the goal is to have a
                substantial impact on those individual students. The potential impact that that
                person can have later on the world is huge. Just look at all the technical problem
                solving entrepreneurs that are out there.
              </p>
              <p>
                It goes beyond learning to code though. It's a really unique subject that allows you
                to touch on all these different areas.
              </p>
              <p>
                How can you teach critical thinking skills and being able to trace through the logic
                of something? How can you teach planning ahead and being able to break down a large
                goal into a series of steps? How can you combine this logical / technical side of
                the brain with the creative and being able to think of features and what they want
                to create? How can you teach grit and problem-solving, how to recover when things go
                wrong?
              </p>
              <p>
                This is why Breakout Mentors doesn't have an overly structured curriculum. We have
                enough so that the mentors feel like they know what they can be doing and they are
                supported. But at the same time we don't want to just have a plug-and-play something
                that every student does. The students learn a lot more from doing and thinking
                through the problems than just filling in the blanks.
              </p>
              <p>
                This makes it more fun for the mentors too. Every week is different, you think on
                your feet, create exciting projects together, and also get a track the progress of
                this student over many months and years.
              </p>
            </div>
          </div>
        </div>

        <h3>Fun and Creative</h3>
        <div>
          <audio controls>
            <source
              src="https://dl.dropboxusercontent.com/s/sj7ez0cwnf81f6f/Fun-and-Creative%2016bit.wav?dl=0"
              type="audio/wav"
            />
            Your browser does not support the audio element.
          </audio>
          <Link
            className="viewHideText"
            to="#"
            onClick={this.handleTextToggle.bind(this, 'funText')}
          >
            {this.state.funText ? 'Hide' : 'View'} Transcript
          </Link>
          <div className={this.state.funText ? '' : 'collapse'}>
            <div className="card card-body">
              <p>
                Your first objective is to make it fun - learning to code is really an optional
                thing for these kids to do at their age, so if they don't find it fun, or they think
                it's too difficult, they might just stop.
              </p>
              <p>
                It's natural for a mentor to want to spend the time well, feel like you're always
                moving forward. But thinking long-term, there might be some trade-offs between what
                the kid finds fun, and the learning objectives. So I'd say err on the side of
                following the students interests make sure it stays fun, but fit the learning goals
                around that.
              </p>
              <p>
                You don't have to stay on task at all times. You don't have to focus them and do
                these drills and make sure they have mastered every little boring fundamental. You
                can joke around more. You can have fun. If you're smiling the student will have more
                fun. So make sure you find ways to have fun yourself.{' '}
              </p>
              <p>
                The best way to make it fun is to make sure the student is being creative. It's one
                thing to just create a project because the mentor said so or to make an exact clone
                of a game like Pong, but if the student has their own features in it, their own
                graphics, it really makes them feel ownership over it. They're really proud of their
                project and they want to show it off.
              </p>
              <p>
                If a student's creating projects they're proud of, they will want to keep going,
                they will want to make that next project.
              </p>
              <p>
                It's important to note that the creativity doesn't have to be a whole game or a
                large feature - it can really start as simple as improving the graphics and taking
                some time to do that. A lot of students want to make a funny welcome or instruction
                screen or display a goofy message when you lose. Think about Mario how the goal is
                to save the princess, maybe you can add a backstory to your game?
              </p>
              <p>
                Then later on students will be able to add different feature ideas and extensions to
                their project. For example in Pong, they might come up with the idea of after you
                hit the ball five times the paddle should shrink to make it a little bit harder.{' '}
              </p>
              <p>
                Ideally, they'll be able to come up with their own game idea. Maybe it's inspired by
                something they played on their parent's iPhone, or even something entirely new. For
                example one student took picture of his dog and made a game where it had to fetch a
                tennis ball.
              </p>
              <p>
                Your role as a mentor is to encourage this creativity and try to get these ideas out
                of them. One question I like to ask is "how can you make it more fun?"
              </p>
              <p>
                Some kids will have a lot of ideas and you'll have to focus their energy on creating
                one thing at a time. Other kids might have hardly any and you can ask leading
                questions towards an idea that you have, making them feel like it's their own idea,
                and really selling them on how exciting it would be to make.
              </p>
              <p>
                The project is only done when the student feels it's done. It really makes it fun
                for the kids, but also a lot of the learning happens when you go off script and try
                things. You'll get a feel for when the students ready to move on and try something
                new.
              </p>
            </div>
          </div>
        </div>

        <h3>Other Things to Know</h3>
        <div>
          <p>
            Everything is set up to make this as easy and flexible as possible for the mentors.
            You'll be handling the communication directly with the parents, so it is important to
            know what Breakout Mentors tells them. Please reply-all to any Breakout Mentors email so
            everyone is kept in the loop.
          </p>
          <p>
            We only offer 90 minute sessions. Any requested change to this should be discussed with
            Brian and usually won't result in a lower price for the customer.
          </p>
          <p>
            Families sign up for a monthly package with a certain number of prepaid sessions -
            usually 3 (2 or 6 are the other options). If they hold any sessions beyond the prepaid
            number, those have a big discount. So mentors should still attempt to hold weekly
            sessions, but their package determines how often they may be ok canceling instead of
            rescheduling.
          </p>
          <p>
            It is vital to reschedule if there is a conflict at the regular time. Even if the
            customer says they are unavailable and want to skip the session, please still provide
            alternative times to meet. If you have a scheduling conflict coming up later in the
            month, please let the parents know right away and update the records. We tell them 48
            hours of notice are required for any rescheduling or canceling, so that should be the
            minimum for mentors as well. When they contact you about scheduling, a 24 hour response
            is generally expected.
          </p>
          <p>
            If a student is late for a scheduled session, you can still end at the usual time
            (meaning not the full 90 minutes). If you are late, apologize profusely and offer to
            extend the session that day or next time.
          </p>
          <p>
            If they don't show up for a scheduled session, we will charge them and pay the mentor in
            full. Once they are 5 minutes late call or text them. Please wait 30 minutes since you
            will be paid. Close to the end of the 30 minutes, email Brian about the no-show and also
            forward any texts or emails with the customer scheduling that day's session. Leave it on
            the calendar and Portal as confirmed.
          </p>
          <p>
            If anything is difficult let us know. Brian is the main contact for systems and
            logistics, and your Director for teaching plans and adjustments.
          </p>
        </div>
      </div>
    );
  }
}
