import React from 'react';
import PropTypes from 'prop-types';

import actions from '../util/actions';

import { Jumbotron, Button, Container, Row, Col } from 'reactstrap';

import { SessionDateButtons } from './PortalButtons';

export default class RequestPaycheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paycheck: {},
      paycheckGroups: {},
      paycheckAdjustments: []
    };
  }

  componentDidMount = () => {
    this.loadInfo();
  };

  loadInfo = () => {
    this.props.apiaccess.getPaycheckToSubmit(this.props.userID).then((response) => {
      response.sessions = response.sessions || [];
      const order = response.sessions.reduce((order, each) => {
        if (!order.includes(each.group)) {
          order.push(each.group);
        }
        return order;
      }, []);
      const grouped = response.sessions.reduce((groups, each) => {
        groups[each.group] = groups[each.group] || [];
        groups[each.group].push(each);
        return groups;
      }, {});

      this.setState({
        paycheck: response,
        paycheckGroups: grouped,
        paycheckGroupsOrder: order,
        paycheckAdjustments: response.adjustments
      });
    });
  };

  updateInfo = () => {
    this.loadInfo(); //reload the data for this component

    //if a session was confirmed, moved, or canceled, this will be called by the sessionChanged prop
    //we want to reload the info for all the buttons on the page
    if (this.props.reloadFromAPI) this.props.reloadFromAPI();
  };

  submitPaycheck = () => {
    this.props.apiaccess.submitPaycheck(this.state.paycheck.paycheck_id).then((response) => {
      this.updateInfo();
    });
  };

  render() {
    return (
      <div>
        {this.state.paycheck.paycheck_id && (
          <Jumbotron style={{ marginTop: 15 }}>
            <Container fluid>
              <h1>Request Your Paycheck</h1>
              {this.state.paycheck.sessions.every((session) => {
                return session.confirmed;
              }) ? null : (
                <p>Please confirm or correct all sessions to request your paycheck.</p>
              )}

              {this.state.paycheckGroupsOrder.map((group, i) => {
                return (
                  <div key={i}>
                    <h3 className="light-bottom-border">{group}</h3>
                    <SessionDateButtons
                      sessions={this.state.paycheckGroups[group]}
                      userID={this.props.userID}
                      defaultDescription="No sessions this month"
                      hideRecent={false}
                      apiaccess={this.props.apiaccess}
                      timezone={this.props.timezone}
                      sessionChanged={this.updateInfo}
                    />
                  </div>
                );
              })}

              {this.state.paycheckAdjustments.length > 0 && <h3>Adjustments</h3>}
              {this.state.paycheckAdjustments.map((adjustment, i) => {
                return (
                  <div key={i} className="long-date">
                    {adjustment.details}
                  </div>
                );
              })}

              <Row>
                <Col sm={{ size: 4, offset: 8 }}>
                  {this.state.paycheck.sessions.every((session) => {
                    return session.confirmed;
                  }) ? (
                    <Button block color="success" onClick={this.submitPaycheck}>
                      Request Paycheck
                    </Button>
                  ) : (
                    <p>Please confirm or correct all sessions to request your paycheck.</p>
                  )}
                </Col>
              </Row>
            </Container>
          </Jumbotron>
        )}
      </div>
    );
  }
}

RequestPaycheck.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  userID: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired
};
