import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Button, Jumbotron } from 'reactstrap';

class RecapForm extends React.Component {
  constructor(props) {
    super(props);
    let tempDivision = '';
    let tempConcepts = '';
    if (
      this.props.competitiveRecapInfo !== null &&
      this.props.competitiveRecapInfo[this.props.group_name] != null
    ) {
      tempDivision = this.props.competitiveRecapInfo[this.props.group_name].division;
      tempDivision =
        'USACO ' + tempDivision[0].toUpperCase() + tempDivision.substring(1).toLowerCase();
      tempConcepts = this.props.competitiveRecapInfo[this.props.group_name].concepts.join(', ');
    }
    this.state = {
      submitted: false,
      mentor_name: this.props.mentor_name,
      group_name: this.props.group_name,
      session_dates: this.props.session_dates,
      month: this.props.month,
      year: this.props.year,
      month_year_str: moment.months(this.props.month - 1) + ' ' + this.props.year,
      group_num: this.props.group_num,
      concepts: tempConcepts,
      project: tempDivision
    };
  }

  handleSubmit = (event) => {
    this.setState({ submitted: true });
    return true;
  };

  /*How it works
	Uses the actual Google Form - on submit its target is an iframe that doesn't show (would have the Google thank you)
	Instead shows our own 'Thank you' div and hides the form from view
	*/

  render = () => {
    return (
      <div className="recapForm">
        <h3 className="light-bottom-border">{this.state.group_name}</h3>

        {this.state.submitted ? (
          <div>
            Thank you! If you don't receive an email with the recap PDF attached within 5 minutes,
            there was likely an error so please let Brian know.
          </div>
        ) : null}

        <form
          style={{ display: this.state.submitted ? 'none' : 'block' }}
          action="https://docs.google.com/forms/d/e/1FAIpQLSeQOxVo9ZMto0MqVcT3V_YcOCnoO3s4hqcAsG4KE9DlrkWYwg/formResponse?embedded=true"
          method="POST"
          target={this.state.group_name}
          onSubmit={this.handleSubmit}
        >
          <label>Dates: {this.state.session_dates} (if incorrect, update on student page)</label>

          <label className="ss-q-item-label">Projects (comma separated list)</label>
          <textarea name="entry.1637630303" rows="8" cols="0" className="shortAnswer" required>
            {this.state.project}
          </textarea>

          <label className="ss-q-item-label">Concepts (comma separated list)</label>
          <textarea name="entry.1522671324" rows="8" cols="0" className="shortAnswer" required>
            {this.state.concepts}
          </textarea>

          <label className="ss-q-item-label">
            Project Details (3-4 sentences describing the project and features)
          </label>
          <textarea
            name="entry.197961774"
            rows="8"
            cols="0"
            className="longAnswer"
            required
          ></textarea>

          <label className="ss-q-item-label">
            Learning Progress (3-4 sentences about how the student is doing and future plans - keep
            it positive)
          </label>
          <textarea
            name="entry.855138582"
            rows="8"
            cols="0"
            className="longAnswer"
            required
          ></textarea>

          <input type="hidden" name="entry.1395140722" value={this.state.mentor_name} />
          <input type="hidden" name="entry.1895237113" value={this.state.group_name} />
          <input type="hidden" name="entry.236523235" value={this.state.session_dates} />
          <input type="hidden" name="entry.416059872" value={this.state.month} />
          <input type="hidden" name="entry.1690132032" value={this.state.year} />
          <input type="hidden" name="entry.1098842498" value={this.state.month_year_str} />
          <input type="hidden" name="entry.865849423" value={this.state.group_num} />

          <input type="submit" name="submit" value="Submit" className="btn btn-primary" />
        </form>

        <iframe
          id={this.state.group_name}
          name={this.state.group_name}
          style={{ display: 'none' }}
        ></iframe>
      </div>
    );
  };
}

class RecapsToSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaps: [],
      competitiveRecapInfo: null
    };
  }

  componentWillMount = () => {
    this.props.apiaccess.getRecapsToSubmit(this.props.userID).then((response) => {
      this.setState({
        recaps: response.recaps,
        competitiveRecapInfo: response.competitiveStudents
      });
    });
  };

  render = () => {
    return (
      <div>
        <h1>Recaps to Submit</h1>
        {this.state.recaps.length > 0 && this.state.competitiveRecapInfo !== null ? (
          this.state.recaps.map((recap) => {
            return (
              <RecapForm
                key={recap.group_num}
                competitiveRecapInfo={this.state.competitiveRecapInfo}
                {...recap}
                {...this.props}
              />
            );
          })
        ) : (
          <Jumbotron>
            <p style={{ marginBottom: 0 }}>Nothing right now.</p>
          </Jumbotron>
        )}
      </div>
    );
  };
}

export default withRouter(RecapsToSubmit);
