import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Alert, Modal, ModalBody, Jumbotron } from 'reactstrap';
import { findIndex as findIndexLodash } from 'lodash';
import { find as findLodash } from 'lodash';

export const TIMEZONES = [
  'US/Pacific',
  'US/Eastern',
  'US/Central',
  'US/Mountain',
  'US/Arizona',
  'US/Alaska',
  'US/Hawaii'
];

class CommunicationGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameFieldValue: null,
      phoneFieldValue: null,
      emailFieldValue: null,
      upcomingMonthMessageEnabled: false,
      twoDayMessageEnabled: false,
      dayOfMessageEnabled: false,
      communicationGroupID: null,
      checkboxesModified: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      upcomingMonthMessageEnabled:
        nextProps.communicationGroup != null &&
        nextProps.communicationGroup.upcomingMonthMessageEnabled,
      twoDayMessageEnabled:
        nextProps.communicationGroup != null && nextProps.communicationGroup.twoDayMessageEnabled,
      dayOfMessageEnabled:
        nextProps.communicationGroup != null && nextProps.communicationGroup.dayOfMessageEnabled,
      communicationGroupID:
        nextProps.communicationGroup != null
          ? nextProps.communicationGroup.communicationGroupID
          : null,
      checkboxesModified: false
    });
  }

  handleNameFieldChange = (event) => {
    const target = event.target;
    const value = target.value == '' ? null : target.value;

    this.setState({
      nameFieldValue: value
    });
  };

  handlePhoneFieldChange = (event) => {
    const target = event.target;
    const value = target.value == '' ? null : target.value;

    this.setState({
      phoneFieldValue: value
    });
  };

  handleEmailFieldChange = (event) => {
    const target = event.target;
    const value = target.value == '' ? null : target.value;

    this.setState({
      emailFieldValue: value
    });
  };

  standardizePhone(phone) {
    if (phone.charAt(0) != 1) {
      return '+1' + phone.replace(/ /g, '').replace(/\(/g, '').replace(/-/g, '').replace(/\)/g, '');
    } else {
      return '+' + phone.replace(/ /g, '').replace(/\(/g, '').replace(/-/g, '').replace(/\)/g, '');
    }
  }

  validateCommunicationGroup = () => {
    var phoneOrEmail = this.state.emailFieldValue;

    if (this.state.emailFieldValue == null) {
      phoneOrEmail = this.standardizePhone(this.state.phoneFieldValue);
      if (phoneOrEmail.length != 12) return;
    }

    this.setState({
      nameFieldValue: '',
      phoneFieldValue: '',
      emailFieldValue: ''
    });

    this.props.createJoinCommunicationGroup(
      this.props.studentID,
      this.state.nameFieldValue,
      phoneOrEmail,
      this.props.communicationGroupFound
    );
  };

  submitCommunicationGroupSettings = () => {
    this.setState({
      checkboxesModified: false
    });

    this.props.apiaccess.updateCommunicationGroupSettings(
      this.state.communicationGroupID,
      this.state.upcomingMonthMessageEnabled,
      this.state.twoDayMessageEnabled,
      this.state.dayOfMessageEnabled
    );
  };

  render() {
    if (this.props.communicationGroup && this.props.communicationGroup.members) {
      var members = this.props.communicationGroup.members.map((member) => {
        return (
          <span key={member.name}>
            {member.name} ({member.phone ? 'phone' : 'email'})
          </span>
        );
      });

      var memberComponent = members.reduce((prev, curr) => [prev, ', ', curr]);
    }

    return (
      <div>
        {this.props.communicationGroupFound && <p>Members: {memberComponent}</p>}

        {
          // Communication group exists, show checkboxes
          this.props.communicationGroupFound && (
            <div>
              <p style={{ marginBottom: '0' }}>
                <input
                  type="checkbox"
                  checked={this.state.upcomingMonthMessageEnabled}
                  onChange={(event) => {
                    this.setState({
                      upcomingMonthMessageEnabled: event.target.checked,
                      checkboxesModified: true
                    });
                  }}
                />
                &nbsp;Start of Month Notification
              </p>
              <p style={{ marginBottom: '0' }}>
                <input
                  type="checkbox"
                  checked={this.state.twoDayMessageEnabled}
                  onChange={(event) => {
                    this.setState({
                      twoDayMessageEnabled: event.target.checked,
                      checkboxesModified: true
                    });
                  }}
                />
                &nbsp;2 Days Before Session Notification
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={this.state.dayOfMessageEnabled}
                  onChange={(event) => {
                    this.setState({
                      dayOfMessageEnabled: event.target.checked,
                      checkboxesModified: true
                    });
                  }}
                />
                &nbsp;Day-of Session Notification
              </p>
              {this.state.checkboxesModified && (
                <Button
                  style={{ marginBottom: '1rem' }}
                  color="success"
                  onClick={this.submitCommunicationGroupSettings}
                >
                  Save Communication Group Settings
                </Button>
              )}
            </div>
          )
        }

        <p>
          <label style={{ marginRight: 10 }}>Name:</label>
          <input
            type="text"
            value={this.state.nameFieldValue}
            onChange={this.handleNameFieldChange}
          />
        </p>

        <p style={{ marginBottom: '0' }}>
          <label style={{ marginRight: 10 }}>Phone:</label>
          <input
            style={{ marginBottom: '1rem' }}
            type="text"
            value={this.state.phoneFieldValue}
            onChange={this.handlePhoneFieldChange}
          />

          <label style={{ marginLeft: 10 }}>
            {' '}
            <strong> OR </strong> Email:
          </label>
          <label style={{ marginRight: 10 }}></label>
          <input
            style={{ marginBottom: '1rem' }}
            type="text"
            value={this.state.emailFieldValue}
            onChange={this.handleEmailFieldChange}
          />
        </p>

        <Button
          color={this.props.communicationGroupFound ? 'secondary' : 'success'}
          disabled={
            !(
              this.state.nameFieldValue != null &&
              (this.state.emailFieldValue != null || this.state.phoneFieldValue != null)
            )
          }
          onClick={this.validateCommunicationGroup}
        >
          {this.props.communicationGroupFound
            ? 'Join Mentor Communication'
            : 'Enable Mentor Communication'}
        </Button>
      </div>
    );
  }
}

class CreditCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (this.props.userRole === 'user' && !import.meta.env) {
      this.getCreditCardIntent();
    }
  }

  showStripeForm = () => {
    //have to load https://js.stripe.com/v3/ first - right now in the head for every page

    this.setState({ displayNewCCForm: !this.state.displayNewCCForm });

    var stripe = Stripe(
      !import.meta.env && process.env.APP_SETTINGS === 'config.ProductionConfig'
        ? 'pk_live_ELbCtO8LNvXZejT2eIFrHkLu'
        : 'pk_test_jeId9pl1YkbzuwmRG2uI6wAR',
      {
        betas: ['link_beta_3'],
        apiVersion: '2019-12-03;link_beta=v1'
      }
    );
    const loader = 'auto';
    const appearance = {
      /* ... */
    };
    var elements = stripe.elements({
      clientSecret: this.state.stripe_client_secret,
      loader,
      appearance
    });
    var linkAuthenticationElement = elements.create('linkAuthentication', {
      defaultValues: { email: this.props.email }
    });
    linkAuthenticationElement.mount('#link-authentication-element');
    var paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
    paymentElement.focus();

    this.elements = elements;
    this.stripe = stripe;
  };

  getCreditCardIntent() {
    this.props.apiaccess.creditCardIntent().then((data) => {
      this.setState({ stripe_client_secret: data.stripe_client_secret });
    });
  }

  saveNewCard = (event) => {
    event.preventDefault();

    this.setState({
      paymentAttempted: true,
      paymentStatus: 'Processing...',
      paymentDescription: '',
      paymentStatusColor: 'primary',
      paymentButtonText: 'Almost done',
      paymentButtonDis: true
    });

    /*old way
    this.stripe.confirmCardSetup(
      this.state.stripe_client_secret,
      {
        payment_method: {
          card: this.cardElement,
        },
      }
    )
    */
    var returnURL = !import.meta.env
      ? process.env.PROD_SITE_ROOT + 'settings'
      : `https://${import.meta.env.VITE_APP_ADDRESS}.repl.co/settings`;
    var elements = this.elements;
    this.stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: returnURL
        },
        redirect: 'if_required'
      })
      .then((result) => {
        if (result.error) {
          // Display error.message in your UI.
          console.log('CC ERROR');

          this.setState({
            paymentStatus: 'ERROR!',
            paymentDescription: 'Your card was not successfully saved. Please try again later.',
            paymentStatusColor: 'danger',
            paymentButtonDis: false,
            paymentAction: () => this.setState({ paymentAttempted: false }),
            paymentButtonText: 'Close'
          });
        } else {
          if (result.setupIntent.status === 'succeeded') {
            // The setup has succeeded. Display a success message and send
            // setupIntent.payment_method to your server to save the
            // card to a Customer

            this.props.apiaccess
              .creditCardAdded(this.props.familyID, result.setupIntent.payment_method)
              .then((data) => {
                this.setState({
                  paymentStatus: 'Thank you!',
                  paymentDescription: 'Your card has been saved.',
                  paymentStatusColor: 'success',
                  paymentButtonDis: false,
                  paymentAction: () => window.location.reload(),
                  paymentButtonText: 'Close',
                  autoBillCC: 'Credit Card'
                });
              });
          }
        }
      });
  };

  render() {
    return (
      <div>
        <div>
          Saved card: {this.props.autoBillCC}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            onClick={this.showStripeForm}
            style={{ display: this.state.displayNewCCForm ? 'none' : '' }}
          >
            Change Card
          </Button>
        </div>
        <form
          id="payment-form"
          data-secret={this.state.stripe_client_secret}
          style={{ width: 375, display: this.state.displayNewCCForm ? '' : 'none' }}
        >
          <div
            id="link-authentication-element"
            style={{ width: 270, display: 'inline-block', marginTop: 20, marginBottom: 5 }}
          ></div>
          <div
            id="payment-element"
            style={{ width: 270, display: 'inline-block', marginBottom: 20 }}
          ></div>
          <Button onClick={this.saveNewCard} style={{ marginTop: -10 }}>
            Save Card
          </Button>
        </form>

        <Modal centered isOpen={this.state.paymentAttempted}>
          <ModalBody>
            <h2>{this.state.paymentStatus}</h2>
            <p>{this.state.paymentDescription}</p>
            <Button
              disabled={this.state.paymentButtonDis}
              color={this.state.paymentStatusColor}
              onClick={this.state.paymentAction}
            >
              {this.state.paymentButtonText}
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    var commGroups = this.props.studentList.map((student) => {
      return { studentID: student.id, communicationGroup: null };
    });

    var limit = 0;

    this.state = {
      receiveBillingEmails: this.props.receiveBillingEmails,
      receiveOtherEmails: this.props.receiveOtherEmails,
      receiveTexts: this.props.mentorReceiveTexts,
      competitiveDivision: this.props.competitiveDivision,
      competitiveHandle: this.props.competitiveHandle,
      alertOpen: false,
      hasChanged: false,
      studentCommunicationGroups: commGroups,
      calendarEmails: [],
      calendarEmail: '',
      stripe_client_secret: '',
      timezone: this.props.timezone
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.getAllCommunicationGroups();
    this.getAllCalendarEmails();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      hasChanged: true
    });
  }

  handleCalendarChange = (event) => {
    const target = event.target;
    const value = target.value == '' ? null : target.value;

    this.setState({
      calendarEmail: value
    });
  };

  getAllCommunicationGroups() {
    this.props.studentList.forEach((student) => {
      this.props.apiaccess.getCommunicationGroup('student', student.id).then((data) => {
        if (data.communicationGroupID == null) {
          return;
        }
        var index = findIndexLodash(this.state.studentCommunicationGroups, {
          studentID: student.id
        });
        let groupCopy = JSON.parse(JSON.stringify(this.state.studentCommunicationGroups));
        groupCopy[index].communicationGroup = data;
        console.log('groupCopy');
        console.log(groupCopy);
        this.setState({
          studentCommunicationGroups: groupCopy
        });
      });
    });
  }

  createJoinCommunicationGroup = (studentID, name, phoneOrEmail, communicationGroupFound) => {
    if (!communicationGroupFound) {
      this.props.apiaccess.createCommunicationGroup(studentID).then((data) => {
        this.props.apiaccess
          .joinCommunicationGroup(studentID, name, phoneOrEmail, this.props.familyID)
          .then((data) => {
            this.getAllCommunicationGroups();
          });
      });
    } else {
      this.props.apiaccess
        .joinCommunicationGroup(studentID, name, phoneOrEmail, this.props.familyID)
        .then((data) => {
          this.getAllCommunicationGroups();
        });
    }
  };

  submitUserSettings = () => {
    if (this.props.userRole === 'user') {
      this.props.apiaccess
        .updateUserSettings(this.state.receiveBillingEmails, this.state.receiveOtherEmails, false)
        .then((response) => {
          this.props.refresh();
        });
    } else if (this.props.userRole === 'mentor') {
      this.props.apiaccess.updateMentorSettings(this.state.receiveTexts).then((response) => {
        this.props.refresh();
      });
    } else if (this.props.userRole === 'competitive_student') {
      this.props.apiaccess
        .updateCompetitiveStudentSettings(
          this.state.competitiveDivision,
          this.state.competitiveHandle
        )
        .then((response) => {
          this.props.refresh();
        });
    }

    this.props.apiaccess.updateTimeZone(this.state.timezone).then((response) => {
      this.props.refresh();
    });

    this.setState({
      alertOpen: true,
      hasChanged: false
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false
    });
  };

  getAllCalendarEmails = () => {
    this.props.apiaccess.getCalendarInvites(this.props.familyID).then((data) => {
      this.setState({
        calendarEmails: data.calendar_invites
      });
    });
  };

  addCalendarInvite = () => {
    var calEmail = this.state.calendarEmail;
    this.setState({
      calendarEmail: ''
    });

    this.props.apiaccess.addCalendarInvite(this.props.familyID, calEmail).then((response) => {
      this.getAllCalendarEmails();
    });
  };

  render() {
    if (this.state.calendarEmails && this.state.calendarEmails.length > 0) {
      var emails = this.state.calendarEmails.map((invite) => {
        return <span key={invite.email}>{invite.email}</span>;
      });
      if (emails.length != 0) {
        var emailComponent = emails.reduce((prev, curr) => [prev, ', ', curr]);
      }
    }

    return (
      <div>
        <h1>Settings</h1>

        <div className="settings">
          <p>Name: {this.props.name}</p>
          <p>Email: {this.props.email}</p>
          <div>
            <label style={{ marginRight: '10px' }}>Timezone:</label>
            <select name={'timezone'} onChange={this.handleInputChange}>
              {TIMEZONES.map((item) => (
                <option selected={this.state.timezone === item}>{item}</option>
              ))}
            </select>
          </div>

          {this.props.userRole === 'user' &&
            this.props.clientAgreement &&
            this.props.clientAgreement !== 'completed' &&
            !this.props.clientAgreement.startsWith('pending:') && (
              <p>
                View your{' '}
                <a target={'_blank'} rel={'noopener noreferrer'} href={this.props.clientAgreement}>
                  Breakout Mentors Client Agreement
                </a>
              </p>
            )}

          {this.props.userRole === 'user' && (
            <div>
              <p>
                <input
                  name="receiveBillingEmails"
                  type="checkbox"
                  checked={this.state.receiveBillingEmails}
                  onChange={this.handleInputChange}
                />
                &nbsp;Receive Billing Emails
              </p>

              <p>
                <input
                  name="receiveOtherEmails"
                  type="checkbox"
                  checked={this.state.receiveOtherEmails}
                  onChange={this.handleInputChange}
                />
                &nbsp;Receive Other Emails
              </p>

              {this.props.autoBillCC && <CreditCardComponent {...this.props} />}
              {this.state.hasChanged && (
                <Button color="success" onClick={this.submitUserSettings}>
                  Save
                </Button>
              )}

              <h2>Calendar Invites</h2>

              <p>
                Google Calendar is used to keep track of all the sessions. Would you like to be
                invited to the appointments? Simply enter your email below to receive calendar
                invites to upcoming sessions.
              </p>

              <p>
                <label style={{ marginRight: '10px' }}>Email:</label>
                <input
                  type="text"
                  value={this.state.calendarEmail}
                  onChange={this.handleCalendarChange}
                />
                &nbsp;
                {this.state.calendarEmail != '' && (
                  <Button color="success" onClick={this.addCalendarInvite}>
                    Save
                  </Button>
                )}
              </p>

              {this.state.calendarEmails && this.state.calendarEmails.length > 0 && (
                <p>Registered Emails: {emailComponent}</p>
              )}

              <h2>Mentor Communication</h2>

              {this.props.studentList.map((student) => {
                return (
                  <div key={student.id}>
                    <h3>{student.name}</h3>
                    <CommunicationGroup
                      studentID={student.id}
                      createJoinCommunicationGroup={this.createJoinCommunicationGroup}
                      communicationGroupFound={
                        findLodash(this.state.studentCommunicationGroups, { studentID: student.id })
                          .communicationGroup != null
                      }
                      communicationGroup={
                        findLodash(this.state.studentCommunicationGroups, { studentID: student.id })
                          .communicationGroup
                      }
                      apiaccess={this.props.apiaccess}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {this.props.userRole === 'mentor' && (
            <div>
              <p>
                <input
                  name="receiveTexts"
                  type="checkbox"
                  checked={this.state.receiveTexts}
                  onChange={this.handleInputChange}
                />
                &nbsp;Receive Session Confirmation Requests By Text
              </p>
            </div>
          )}

          {this.props.userRole === 'competitive_student' && (
            <Fragment>
              <div>
                <label style={{ marginRight: '10px' }}>Division:</label>
                <select name={'competitiveDivision'} onChange={this.handleInputChange}>
                  <option value={''} disabled selected={this.state.competitiveDivision === ''}>
                    Select&hellip;
                  </option>
                  <option value={'bronze'} selected={this.state.competitiveDivision === 'bronze'}>
                    Bronze
                  </option>
                  <option value={'silver'} selected={this.state.competitiveDivision === 'silver'}>
                    Silver
                  </option>
                  <option value={'gold'} selected={this.state.competitiveDivision === 'gold'}>
                    Gold
                  </option>
                  <option
                    value={'platinum'}
                    selected={this.state.competitiveDivision === 'platinum'}
                  >
                    Platinum
                  </option>
                </select>
              </div>
              <div>
                <p>Codeforces Handle: {this.state.competitiveHandle}</p>
              </div>
            </Fragment>
          )}

          {this.props.userRole !== 'user' && this.state.hasChanged && (
            <Button color="success" onClick={this.submitUserSettings}>
              Save
            </Button>
          )}
          <Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>
            Saved!
          </Alert>
        </div>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};
