import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { Container, Row, Col, Modal, ModalBody, Button } from 'reactstrap';

import AuthService from '../util/AuthService';
import MemoryStorage from '../util/MemoryStorage';
import actions from '../util/actions';

import Authenticated from './Authenticated';
import NavBar from './NavBar';
import SidebarNav from './SidebarNav';
import LandingPage from './LandingPage';

import Login from './Login';
import Logout from './Logout';
import StudentPage from './StudentPage';
import Loading from './Loading';
import GroupPage from './GroupPage';
import BillingPage from './BillingPage';
import SettingsPage from './SettingsPage';
import CreditAdjustPage from './CreditAdjustPage';
import MentorAssignmentsPage from './MentorAssignmentsPage';
import StudentRatePage from './StudentRatePage';
import GroupCreatePage from './GroupCreatePage';
import SendInvitesPage from './SendInvitesPage';
import NextUnconfirmedPage from './NextUnconfirmedPage';
import PaycheckPage from './PaycheckPage';
import PaycheckAdminPage from './PaycheckAdminPage';
import RecapsToSubmit from './RecapsToSubmit';
import MentorInstructions from './MentorInstructions';
import OtherWaysToHelp from './OtherWaysToHelp';
import AdminDashboard from './AdminDashboard';
import AdjustPaycheckPage from './AdjustPaycheckPage';
import ConfirmSessionPage from './ConfirmSessionPage';
import ImpersonateUserPage from './ImpersonateUserPage';
import MentorResources from './MentorResources';
import PackageAdjustmentPage from './PackageAdjustmentPage';
import EmailUnsubscribePage from './EmailUnsubscribePage';

import {
	CompetitiveEvaluationForm,
	ContestForm,
	SubmitCompetitiveFormPage
} from './SubmitCompetitiveFormPage';
import DisplayCompetitiveFormPage from './DisplayCompetitiveFormPage';
import CompetitiveGym from './CompetitiveGym';
import USACOSession from './USACOSession';

import TeamPage, { ALLOWED_TEAM_LEADS } from './TeamPage';
import Survey from './Survey';
import MentorSurvey from './MentorSurvey';

const authService = new AuthService(
	'MOBLjKMEHy6zomMee89c8cxm6THFn9P0',
	!import.meta.env && process.env.APP_SETTINGS === 'config.ProductionConfig'
		? 'login.breakoutmentors.com'
		: 'breakoutmentors.auth0.com'
);
const apiaccess = new actions();
const storage = new MemoryStorage();

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isEmptyState: true };

		authService.on('hide', () => {
			this.loginDone();
		});

		authService.on('logged_in', (accessToken, idToken, profile) => {
			this.setState({
				loggingIn: true
			});
			this.updateAccessToken(accessToken, idToken);
			const path = storage.getItem('post_login_path');
			this.updatePropsFromProfile(idToken, profile, path);
		});

		authService.on('logged_out', () => {
			this.updateAccessToken(null, null);
			this.profile = null;
			this.userRole = 'logged_out';
			this.updatePropsFromProfile(null, null);
		});

		authService.on('registered', (accessToken, idToken, profile, familyID, timezone) => {
			this.setState({
				loggingIn: true
			});
			this.updateAccessToken(accessToken, idToken);
			apiaccess.addNewUser(familyID, timezone).then((retVal) => {
				this.updatePropsFromProfile(idToken, profile);
			});
		});

		authService.on(
			'competitive_registered',
			(accessToken, idToken, profile, studentHash, timezone) => {
				this.setState({
					loggingIn: true
				});
				this.updateAccessToken(accessToken, idToken);
				console.log('competitive_registered');
				apiaccess
					.addNewCompetitiveUser(studentHash, timezone)
					.then((retVal) => {
						this.updatePropsFromProfile(idToken, profile);
						console.log(retVal);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		);

		apiaccess.on('api_error', () => {
			this.showError(
				'An internal server error has occurred and an admin has been notified. Please try again later.'
			);
		});

		apiaccess.on('request_error', (err) => {
			this.showError(err.response.data.message);
		});

		apiaccess.on('alerted_admin', (err) => {
			this.showError(
				'Unable to update the Google calendar records. The administrator has been notified and will process the change.'
			);
		});

		apiaccess.on('connection_error', () => {
			this.showError('Connection error. Please try again later.', () => {
				authService.getUserInfo(null, null);
			});
		});

		// HERE
		this.state = {
			name: '',
			email: '',
			studentList: [],
			userRole: '',
			familyID: 0,
			creditsRemaining: 0,
			clientAgreement: 'completed',
			shouldShowAgreementPrompt: false,
			userID: 0,
			auth0Id: '',
			loggingIn: false,
			sidebarHide: true,
			errorDetails: '',
			errorOccurred: false,
			receiveBillingEmails: true,
			receiveOtherEmails: true,
			autoBillCC: '',
			mentorReceiveTexts: false,
			monthlyBilling: false,
			competitiveDivision: '',
			admin_familyList: [],
			admin_mentorList: [],
			admin_competitiveList: [],
			admin_pairingData: [],
			competitiveStudents: [],
			timezone: 'US/Pacific'
		};
	}

	componentDidMount = () => {
		storage.setItem('post_login_path', this.props.location.pathname + this.props.location.search);
		authService.getUserInfo(storage.getItem('accessToken'), storage.getItem('idToken'));
	};

	updateAccessToken = (accessToken, idToken) => {
		if (accessToken == null) {
			storage.removeItem('accessToken');
			storage.removeItem('idToken');
			apiaccess.setToken(null);
		} else {
			storage.setItem('accessToken', accessToken);
			storage.setItem('idToken', idToken);
			apiaccess.setToken(idToken);
		}
	};

	updatePropsFromProfile = (token, profile, path) => {
		if (profile == null) {
			this.setState({
				name: '',
				studentList: [],
				userRole: '',
				familyID: 0,
				creditsRemaining: 0,
				userID: 0,
				auth0Id: '',
				clientAgreement: 'completed',
				shouldShowAgreementPrompt: false,
				loggingIn: false,
				monthlyBilling: false,
				competitiveDivision: '',
				competitiveHandle: '',
				competitiveStudents: [],
				timezone: 'US/Pacific'
			});
		} else {
			this.setState({
				name: profile.name,
				auth0Id: profile.user_id
			});
			apiaccess.getFamilyAndStudents().then((retVal) => {
				this.setState({
					email: retVal.email,
					userRole: retVal.userRole,
					receiveBillingEmails: retVal.userReceiveBillingEmails,
					receiveOtherEmails: retVal.userReceiveOtherEmails,
					studentList: retVal.students,
					familyID: retVal.familyID,
					creditsRemaining: retVal.creditsRemaining,
					clientAgreement: retVal.clientAgreement,
					shouldShowAgreementPrompt: retVal.clientAgreement === false,
					userID: retVal.userID,
					loggingIn: false,
					autoBillCC: retVal.autoBillCC,
					mentorReceiveTexts: retVal.mentorReceiveTexts,
					monthlyBilling: retVal.monthlyBilling,
					competitiveDivision: retVal.competitiveDivision,
					competitiveHandle: retVal.competitiveHandle,
					competitiveStudents: retVal.competitiveStudents,
					timezone: retVal.timezone
				});

				if (retVal.userRole === 'admin') {
					apiaccess.getFamilyList().then((data) => {
						this.setState({
							admin_familyList: data.families
						});
					});
					apiaccess.getMentorList().then((data) => {
						this.setState({
							admin_mentorList: data.mentors
						});
					});
					apiaccess.getCompetitiveStudentData().then((data) => {
						this.setState({
							admin_competitiveList: data.competitive_students
						});
					});
					apiaccess.getPairingData().then((data) => {
						this.setState({
							admin_pairingData: data.pairings
						});
					});
				}
				if (path && path !== '/') {
					this.redirect(path);
					storage.removeItem('post_login_path');
				} else this.gotoHomePage(retVal.userRole, retVal.email);
			});
		}
	};

	refreshUserData = () => {
		apiaccess.setToken(storage.getItem('idToken'));
		apiaccess.getFamilyAndStudents().then((retVal) => {
			this.setState({
				userRole: retVal.userRole,
				studentList: retVal.students,
				receiveBillingEmails: retVal.userReceiveBillingEmails,
				receiveOtherEmails: retVal.userReceiveOtherEmails,
				familyID: retVal.familyID,
				creditsRemaining: retVal.creditsRemaining,
				clientAgreement: retVal.clientAgreement,
				userID: retVal.userID,
				loggingIn: false,
				autoBillCC: retVal.autoBillCC,
				mentorReceiveTexts: retVal.mentorReceiveTexts,
				monthlyBilling: retVal.monthlyBilling,
				competitiveDivision: retVal.competitiveDivision,
				competitiveHandle: retVal.competitiveHandle,
				competitiveStudents: retVal.competitiveStudents,
				timezone: retVal.timezone
			});
		});
	};

	showLogin = () => {
		storage.setItem('post_login_path', this.props.location.pathname + this.props.location.search);
		authService.showLoginLock();
	};

	loginDone = () => {
		// this.props.history.push('/');
	};

	redirect = (to) => {
		this.props.history.push(to);
	};

	gotoHomePage = (userRole, userEmail) => {
		if (userRole === 'admin') {
			this.redirect('/dashboard');
		} else if (userRole === 'mentor') {
			if (ALLOWED_TEAM_LEADS.has(userEmail)) {
				this.redirect('/team');
			} else {
				this.redirect('/nextunconfirmed');
			}
		} else if (userRole === 'user') {
			this.redirect('/billing');
		} else if (userRole === 'competitive_student') {
			this.redirect('/competitivegym');
		}
	};

	showError = (message, onClose = () => { }) => {
		this.setState({
			errorOccurred: true,
			errorDetails: message,
			errorClose: onClose
		});
	};

	closeError = () => {
		this.setState({
			errorOccurred: false
		});
		if (this.state.errorDetails.includes('User does not exist in the database')) {
			console.log('CLEAR LOGIN INFO');
			this.updateAccessToken(null, null);
			this.profile = null;
			this.userRole = 'logged_out';
			this.updatePropsFromProfile(null, null);
		}
		this.state.errorClose();
	};

	openClientAgreement = () => {
		this.setState({ shouldShowAgreementPrompt: false });
		window.open(
			'https://signnow.com/s/j0QnFoMv?name_formula=Breakout%20Menors%20Client%20Agreement%20-%20%7CText_2&FamilyID=-~' +
			this.state.familyID +
			'~-',
			'_blank'
		);
	};

	render() {
		const authProps = {
			token: storage.getItem('idToken'),
			apiaccess: apiaccess,
			loggingIn: this.state.loggingIn,
			userRole: this.state.userRole,
			showLock: this.showLogin,
			loginDone: this.loginDone
		};
		return (
			<div id="app-container">
				<NavBar
					{...authProps}
					role={this.state.userRole}
					toggleSidebar={() => this.setState({ sidebarHide: !this.state.sidebarHide })}
				/>
				<Container fluid>
					<Row>
						<div className="sidebar">
							<SidebarNav
								{...authProps}
								role={this.state.userRole}
								studentList={this.state.studentList}
								competitiveStudentList={this.state.competitiveStudents}
								hidden={this.state.sidebarHide}
								email={this.state.email}
								clicked={() => this.setState({ sidebarHide: true })}
							/>
						</div>
						<Col md={12} sm={12} className="main-content">
							{authProps.loggingIn ? (
								<div>
									<h1>Welcome Back!</h1>
									<Loading />
								</div>
							) : (
								<Switch>
									<Route exact path="/" render={(props) => <LandingPage show={this.showLogin} />} />
									<Route
										path="/confirm=:hash"
										render={(props) => (
											<ConfirmSessionPage {...authProps} hash={props.match.params.hash} />
										)}
									/>
									<Route
										path="/unsubscribe=:hash"
										render={(props) => (
											<EmailUnsubscribePage {...authProps} hash={props.match.params.hash} />
										)}
									/>
									<Route
										path="/join=:id"
										render={(props) => (
											<Login show={authService.showRegisterLock(props.match.params.id)} />
										)}
									/>
									<Route
										path="/competitivejoin=:hash"
										render={(props) => (
											<Login
												show={authService.showCompetitiveRegisterLock(props.match.params.hash)}
											/>
										)}
									/>

									<Authenticated
										path="/student/:id"
										{...authProps}
										roles={['user']}
										component={StudentPage}
										componentProps={{
											userID: this.state.userID,
											studentList: this.state.studentList,
											timezone: this.state.timezone
										}}
									/>
									<Authenticated
										path="/settings"
										{...authProps}
										roles={['user', 'admin', 'mentor', 'competitive_student']}
										component={SettingsPage}
										componentProps={{
											familyID: this.state.familyID,
											studentList: this.state.studentList,
											mentorReceiveTexts: this.state.mentorReceiveTexts,
											name: this.state.name,
											email: this.state.email,
											receiveBillingEmails: this.state.receiveBillingEmails,
											receiveOtherEmails: this.state.receiveOtherEmails,
											refresh: this.refreshUserData,
											userRole: this.state.userRole,
											autoBillCC: this.state.autoBillCC,
											competitiveDivision: this.state.competitiveDivision,
											competitiveHandle: this.state.competitiveHandle,
											clientAgreement: this.state.clientAgreement,
											timezone: this.state.timezone
										}}
									/>
									<Authenticated
										path="/billing"
										{...authProps}
										roles={['user']}
										component={BillingPage}
										componentProps={{
											monthlyBilling: this.state.monthlyBilling,
											autoBillCC: this.state.autoBillCC,
											creditsRemaining: this.state.creditsRemaining,
											studentList: this.state.studentList,
											userID: this.state.userID,
											familyID: this.state.familyID,
											email: this.state.email,
											refresh: this.refreshUserData,
											shouldShowAgreementPrompt: this.state.shouldShowAgreementPrompt,
											timezone: this.state.timezone
										}}
									/>

									<Authenticated
										path="/otherwaystohelp"
										{...authProps}
										roles={['user', 'mentor']}
										component={OtherWaysToHelp}
										componentProps={{ userRole: this.state.userRole }}
									/>

									<Authenticated
										path="/submitcompetitiveform/submitstudentevaluationform"
										{...authProps}
										roles={['competitive_student']}
										component={CompetitiveEvaluationForm}
										componentProps={{ competitiveDivision: this.state.competitiveDivision }}
									/>
									<Authenticated
										path="/submitcompetitiveform/submitcontestform"
										{...authProps}
										roles={['competitive_student']}
										component={ContestForm}
										componentProps={{ competitiveDivision: this.state.competitiveDivision }}
									/>
									<Authenticated
										path="/submitcompetitiveform"
										{...authProps}
										roles={['competitive_student']}
										component={SubmitCompetitiveFormPage}
										componentProps={{
											competitiveDivision: this.state.competitiveDivision,
											userRole: this.state.userRole
										}}
									/>
									<Authenticated
										path="/displaycompetitiveform"
										{...authProps}
										roles={['competitive_student']}
										component={DisplayCompetitiveFormPage}
										componentProps={{ userRole: this.state.userRole }}
									/>
									<Authenticated
										path="/competitivegym"
										{...authProps}
										roles={['competitive_student']}
										component={CompetitiveGym}
										componentProps={{
											competitiveDivision: this.state.competitiveDivision,
											userRole: this.state.userRole
										}}
									/>
									<Authenticated
										path="/usacosession"
										{...authProps}
										roles={['mentor']}
										component={USACOSession}
										componentProps={{
											competitiveStudents: [
												//hard coded hack
												{ name: 'Bronze', id: 85, division: 'bronze' },
												{ name: 'Silver', id: 86, division: 'silver' },
												...this.state.competitiveStudents
											],
											userRole: this.state.userRole
										}}
									/>

									<Authenticated
										path="/dashboard"
										{...authProps}
										roles={['admin']}
										component={AdminDashboard}
									/>
									<Authenticated
										path="/creditadjust"
										{...authProps}
										roles={['admin']}
										component={CreditAdjustPage}
										componentProps={{
											familyList: this.state.admin_familyList,
											groupList: this.state.admin_pairingData
										}}
									/>
									<Authenticated
										path="/mentorassignments"
										{...authProps}
										roles={['admin']}
										component={MentorAssignmentsPage}
										componentProps={{
											mentorList: this.state.admin_mentorList,
											groupList: this.state.admin_pairingData
										}}
									/>
									<Authenticated
										path="/studentrate"
										{...authProps}
										roles={['admin']}
										component={StudentRatePage}
									/>
									<Authenticated
										path="/groupcreate"
										{...authProps}
										roles={['admin']}
										component={GroupCreatePage}
									/>
									<Authenticated
										path="/invites"
										{...authProps}
										roles={['admin']}
										component={SendInvitesPage}
										componentProps={{ familyList: this.state.admin_familyList }}
									/>
									<Authenticated
										path="/mentorpaychecks"
										{...authProps}
										roles={['admin']}
										component={PaycheckAdminPage}
										componentProps={{ mentorList: this.state.admin_mentorList }}
									/>
									<Authenticated
										path="/adjustpaychecks"
										{...authProps}
										roles={['admin']}
										component={AdjustPaycheckPage}
										componentProps={{ mentorList: this.state.admin_mentorList }}
									/>
									<Authenticated
										path="/impersonate"
										{...authProps}
										roles={['admin']}
										component={ImpersonateUserPage}
										componentProps={{
											familyList: this.state.admin_familyList,
											mentorList: this.state.admin_mentorList,
											competitiveStudentList: this.state.admin_competitiveList
										}}
									/>
									<Authenticated
										path="/packageadjustment"
										{...authProps}
										roles={['admin']}
										component={PackageAdjustmentPage}
									/>

									<Authenticated
										path="/team"
										{...authProps}
										roles={['mentor']}
										component={TeamPage}
										componentProps={{ email: this.state.email }}
									/>
									<Authenticated
										path="/group/:id"
										{...authProps}
										roles={['mentor']}
										component={GroupPage}
										componentProps={{
											userID: this.state.userID,
											timezone: this.state.timezone,
											studentList: this.state.studentList
										}}
									/>
									<Authenticated
										path="/nextunconfirmed"
										{...authProps}
										roles={['mentor']}
										component={NextUnconfirmedPage}
										componentProps={{
											userID: this.state.userID,
											timezone: this.state.timezone,
											studentList: this.state.studentList,
											competitiveStudents: this.state.competitiveStudents
										}}
									/>
									<Authenticated
										path="/paychecks"
										{...authProps}
										roles={['mentor']}
										component={PaycheckPage}
										componentProps={{
											userID: this.state.userID,
											studentList: this.state.studentList,
											timezone: this.state.timezone
										}}
									/>
									<Authenticated
										path="/recaps"
										{...authProps}
										roles={['mentor']}
										component={RecapsToSubmit}
										componentProps={{ userID: this.state.userID, timezone: this.state.timezone }}
									/>
									<Authenticated
										path="/instructions"
										{...authProps}
										roles={['mentor']}
										component={MentorInstructions}
									/>
									<Authenticated
										path="/resources"
										{...authProps}
										roles={['mentor']}
										component={MentorResources}
									/>

									<Route
										path="/logout"
										render={() => (
											<Logout
												logout={() => {
													authService.getUserInfo(null, null);
												}}
												redirect={this.redirect}
											/>
										)}
									/>

									<Route path="/survey" render={() => <Survey />} />

									<Route path="/mentorsurvey" render={() => <MentorSurvey />} />
								</Switch>
							)}
							<div className="center-contact-link contact-space">
								<p>
									<a href="mailto:info@breakoutmentors.com?Subject=Customer%20Portal%20question">
										Have a question? Contact Breakout Mentors
									</a>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
				<Modal
					centered
					isOpen={this.state.shouldShowAgreementPrompt && this.state.userRole === 'user'}
				>
					<ModalBody>
						<h2>Welcome</h2>
						<p>
							Thanks for joining the Portal. This website makes session tracking and payments easy.
						</p>
						<p>
							Before you go on, please sign the{' '}
							<a href="javascript:void(0)" onClick={this.openClientAgreement}>
								Breakout Mentors Client Agreement
							</a>
							.
						</p>
						<Button
							color="primary"
							onClick={this.openClientAgreement}
							style={{ borderColor: 'black' }}
						>
							Sign Agreement
						</Button>
					</ModalBody>
				</Modal>
				<Modal centered isOpen={this.state.errorOccurred}>
					<ModalBody>
						<h2>Error</h2>
						<p>{this.state.errorDetails}</p>
						<Button color="default" onClick={this.closeError} style={{ borderColor: 'black' }}>
							Close
						</Button>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true, errorInfo: info });
		// You can also log the error to an error reporting service
		//BRIAN TO DO
		//logErrorToMyService(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					<h1>Sorry, we've hit a snag!</h1>
					<p>There was an unknown error, the system administrator has been notified.</p>
					<p>
						<a href="/">Please click here to return to the homepage.</a>
					</p>
				</div>
			);
		}
		return this.props.children;
	}
}

export default withRouter(App);
