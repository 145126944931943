import React from 'react';
import { Button, Container, Navbar, NavbarBrand } from 'reactstrap';
import { NavLink } from 'react-router-dom';

export default function NavBar(props) {
  const logOutLink = {
    title: 'Logout',
    to: '/logout',
    authenticated: true,
    roles: ['user', 'admin', 'mentor', 'competitive_student']
  };

  return (
    <Navbar full inverse color="inverse" toggleable>
      <NavbarBrand href="/">
        <img src="https://breakoutmentors.com/wp-content/uploads/2023/06/cropped-breakout-mentors-brain-logo-4.jpg" />
      </NavbarBrand>

      {!import.meta.env ? null : <p>!Development Environment</p>}

      <img
        src="https://breakoutmentors.com/wp-content/uploads/2022/09/menu-img.png"
        id="menu-toggle-button"
        onClick={props.toggleSidebar}
      />

      {props.token != null &&
        (logOutLink.roles.length == 0 || logOutLink.roles.includes(props.role)) && (
          <NavLink activeClassName="active" to="/logout">
            <Button id="log-out-button" onClick={() => this.setState({ sidebarHide: true })}>
              Log Out
            </Button>
          </NavLink>
        )}
    </Navbar>
  );
}
