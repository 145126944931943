import React from 'react';
import PropTypes from 'prop-types';

import actions from '../util/actions';

import RequestPaycheck from './RequestPaycheck';
import PastPaychecks from './PastPaychecks';

export default function PaycheckPage(props) {
  return (
    <div>
      <RequestPaycheck userID={props.userID} apiaccess={props.apiaccess} />
      <h1>Past Paychecks</h1>
      <PastPaychecks userID={props.userID} apiaccess={props.apiaccess} />
    </div>
  );
}

RequestPaycheck.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired,
  userID: PropTypes.number.isRequired
};
