import React from 'react';
import PropTypes from 'prop-types';
import actions from '../util/actions';
import { Button, Alert } from 'reactstrap';

//Should be an administrator!

class GroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: '',
      student1: '0',
      student2: '0',
      student3: '0',
      student4: '0',
      alertOpen: false
    };
  }

  saveNewGroup = () => {
    if (this.state.groupName === '') {
      console.log('no group name');
      return;
    }
    if (this.state.student1 === '0') {
      console.log('no first student');
      return;
    }

    this.props.apiaccess
      .addNewGroup(
        this.state.groupName,
        this.state.student1,
        this.state.student2,
        this.state.student3,
        this.state.student4
      )
      .then((data) => {
        this.clearForm();
        this.openAlert();
      });
  };

  handleChange = (e) => {
    var key = e.target.id;
    var obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  };

  clearForm = () => {
    this.setState({
      groupName: '',
      student1: '0',
      student2: '0',
      student3: '0',
      student4: '0'
    });
  };

  openAlert = () => {
    this.setState({
      alertOpen: true
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false
    });
  };

  render() {
    return (
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="groupName">Group Name:&nbsp;</label>
            <input
              type="text"
              id="groupName"
              value={this.state.groupName}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Student 1:
              <select
                className="form-control"
                id="student1"
                value={this.state.student1}
                onChange={this.handleChange}
              >
                <option value="0" disabled>
                  Pick a student...
                </option>
                {this.props.studentList.map((student) => (
                  <option
                    value={student.id}
                    key={student.id}
                    id={student.id}
                    style={{ fontSize: '12px' }}
                  >
                    {student.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              Student 2:
              <select
                className="form-control"
                id="student2"
                value={this.state.student2}
                onChange={this.handleChange}
              >
                <option value="0" disabled>
                  Pick a student...
                </option>
                {this.props.studentList.map((student) => (
                  <option
                    value={student.id}
                    key={student.id}
                    id={student.id}
                    style={{ fontSize: '12px' }}
                  >
                    {student.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              Student 3:
              <select
                className="form-control"
                id="student3"
                value={this.state.student3}
                onChange={this.handleChange}
              >
                <option value="0" disabled>
                  Pick a student...
                </option>
                {this.props.studentList.map((student) => (
                  <option
                    value={student.id}
                    key={student.id}
                    id={student.id}
                    style={{ fontSize: '12px' }}
                  >
                    {student.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>
              Student 4:
              <select
                className="form-control"
                id="student4"
                value={this.state.student4}
                onChange={this.handleChange}
              >
                <option value="0" disabled>
                  Pick a student...
                </option>
                {this.props.studentList.map((student) => (
                  <option
                    value={student.id}
                    key={student.id}
                    id={student.id}
                    style={{ fontSize: '12px' }}
                  >
                    {student.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Button color="success" onClick={this.saveNewGroup}>
            Save
          </Button>
        </form>
        <Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>
          Successfully created group!
        </Alert>
      </div>
    );
  }
}

GroupForm.contextTypes = {
  router: PropTypes.object
};

GroupForm.propTypes = {
  studentList: PropTypes.array.isRequired,
  apiaccess: PropTypes.instanceOf(actions).isRequired
};

export default class GroupCreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentList: []
    };
  }

  componentDidMount = () => {
    this.props.apiaccess.getStudentList().then((data) => {
      this.setState({
        studentList: data.students
      });
    });
  };

  render() {
    return (
      <div>
        <h1>Create Group</h1>
        <p>
          This will remove the student from their previous group, so double check that it is correct
          before hitting save.
        </p>
        <GroupForm studentList={this.state.studentList} apiaccess={this.props.apiaccess} />
      </div>
    );
  }
}

GroupCreatePage.propTypes = {
  apiaccess: PropTypes.instanceOf(actions).isRequired
};
